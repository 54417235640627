<template>
  <ModalForm
    v-show="visible"
    @close="visible = false"
    title="Add software package"
  >
    <div class="form-outline mb-4" v-if="!manifest">
      <label class="form-label">Manifest file:</label>
      <input
        type="file"
        class="form-control form-control-lg"
        @change="onManifestSelected"
        accept=".json"
      />
    </div>

    <div class="form-outline mb-4" v-for="f in filesToUpload" :key="f.name">
      <label class="form-label">{{ f.name }}</label>
      <input
        type="file"
        class="form-control form-control-lg"
        @change="onFileUpload(f, $event)"
        :accept="extension(f.name)"
        v-if="f.progress == -1"
      />
      <div class="progress" v-if="f.progress != -1">
        <div class="progress-bar" :style="'width: ' + f.progress + '%'">
          {{ f.progress }}%
        </div>
      </div>
    </div>

    <div class="alert alert-danger" v-if="errorMessage">
      {{ errorMessage }}
    </div>

    <div class="text-center mt-5">
      <button
        class="btn btn-lg btn-primary"
        @click="save"
        :disabled="!uploadComplete()"
      >
        Add package {{ manifest?.version }}
      </button>
    </div>
  </ModalForm>
</template>

<script setup>
import { ref } from "vue";
import axios from "axios";
import ModalForm from "@/components/ModalForm.vue";
import backend from "../../../backend";

const emit = defineEmits(["dataChange"]);

const visible = ref(false);
const errorMessage = ref("");
const manifest = ref(null);
const filesToUpload = ref([]);

function extension(filename) {
  return filename.substring(filename.lastIndexOf("."));
}

function uploadComplete() {
  if (!manifest.value) return false;
  for (const f of filesToUpload.value) {
    if (f.progress != 100) return false;
  }
  return true;
}

async function onManifestSelected(event) {
  manifest.value = null;

  const files = event.target.files || event.dataTransfer.files;
  const file = files[0];
  if (!file.name.startsWith("manifest") || !file.name.endsWith(".json")) {
    errorMessage.value = "Not a manifest file";
    return;
  }

  const text = await file.text();
  try {
    manifest.value = JSON.parse(text);
  } catch (error) {
    errorMessage.value = "Invalid manifest contents: " + error;
    return;
  }

  filesToUpload.value = await backend.packages.getUploadUrls(manifest.value);
  for (const f of filesToUpload.value) f.progress = -1;
}

function startUpload(fileinfo, file) {
  let form = new FormData();
  for (const key in fileinfo.target.fields) {
    form.set(key, fileinfo.target.fields[key]);
  }
  form.append("file", file);
  fileinfo.progress = 0;

  axios
    .post(fileinfo.target.url, form, {
      onUploadProgress: (p) => {
        fileinfo.progress = Math.floor((100 * p.loaded) / p.total);
      },
    })
    .then(() => {
      fileinfo.progress = 100;
    })
    .catch((error) => {
      errorMessage.value = "Failed to upload " + file.name + ":" + error;
    });
}

async function onFileUpload(fileinfo, event) {
  errorMessage.value = "";
  const files = event.target.files || event.dataTransfer.files;
  const file = files[0];
  if (file.name != fileinfo.name) {
    errorMessage.value = "Wrong file selected. Expected: " + fileinfo.name;
    return;
  }
  if (file.size != fileinfo.size) {
    errorMessage.value =
      "Wrong file size. Expected " + fileinfo.size + " bytes, got " + file.size;
    return;
  }
  startUpload(fileinfo, file);
}

async function save() {
  await backend.packages.storePackage(manifest.value);
  visible.value = false;
  emit("dataChange");
}

function show() {
  errorMessage.value = "";
  manifest.value = null;
  filesToUpload.value = [];
  visible.value = true;
}

defineExpose({ show });
</script>
