<template>
  <BreadCrumbs
    v-if="!fullScreenView"
    :current="'Visit ' + visit.id"
    parent="Visits"
    parent-path="/visits"
  />
  <div class="body container-fluid">
    <div class="d-flex mb-3">
      <h2>Visit {{ visit.id }}</h2>
      <div class="ms-auto">
        <template v-if="!fullScreenView">
          <button
            class="btn btn-primary"
            @click="deleteClicked"
            v-if="visit.created && !visit.scanned && allowed('START_VISITS')"
          >
            Delete
          </button>
          <button
            class="btn btn-oivi-light"
            @click="beginClicked"
            v-if="
              visit.scanned &&
              !visit.analysis_started &&
              allowed('ANALYZE_VISITS')
            "
          >
            Begin analysis
          </button>
          <button
            class="btn btn-primary ms-3"
            @click="gotoAnalysis"
            v-if="
              visit.analysis_started &&
              !visit.analysis_finished &&
              allowed('ANALYZE_VISITS')
            "
          >
            Continue analysis
          </button>
          <button
            class="btn btn-oivi-light ms-3"
            v-if="
              visit.analysis_finished &&
              !visit.reviewed &&
              allowed('REVIEW_VISITS')
            "
            @click="confirmReviewVisible = true"
          >
            Mark as reviewed
          </button>
        </template>
        <button
          class="btn btn-secondary ms-3"
          v-if="fullScreenView"
          @click="router.push('/visits/lookup')"
        >
          Back
        </button>
        <button
          class="btn btn-oivi-light ms-3"
          v-if="visit.analysis_finished && allowed('VIEW_PATIENTS')"
          @click="viewReport"
        >
          View report
        </button>

        <template v-if="!fullScreenView">
          <button
            class="btn btn-oivi-light ms-3"
            v-if="visit.analysis_finished && allowed('VIEW_PATIENTS')"
            @click="saveReport"
          >
            Download
          </button>
          <button
            class="btn btn-oivi-light ms-3"
            v-if="visit.analysis_finished && allowed('VIEW_PATIENTS')"
            @click="resendReport"
          >
            (Re)Send report to patient
          </button>
          <i
            v-if="visit.analysis_finished && allowed('VIEW_PATIENTS')"
            class="bi bi-info-circle ms-2"
            id="info-icon"
            data-toggle="tooltip"
            title="To send automatically, enable this at the clinic location page"
          ></i>
        </template>
      </div>
    </div>

    <div class="row mt-3" v-if="visit.analysis_finished">
      <div class="col-md-12">
        <h4 style="border-bottom: 1px solid gray">Analysis result</h4>
        <dl class="row">
          <dt class="col-lg-2 col-6">Diabetic Retinopathy (Right eye):</dt>
          <dd class="col-lg-4 col-6">
            <span
              :class="
                !visit.dr_grading_od
                  ? ''
                  : visit.flagged
                    ? 'text-danger'
                    : 'text-success'
              "
            >
              {{ DrGrades[visit.dr_grading_od] ?? "N/A" }}
            </span>
          </dd>
          <dt class="col-lg-2 col-6">Diabetic Retinopathy (Left eye):</dt>
          <dd class="col-lg-4 col-6">
            <span
              :class="
                !visit.dr_grading_os
                  ? ''
                  : visit.flagged
                    ? 'text-danger'
                    : 'text-success'
              "
            >
              {{ DrGrades[visit.dr_grading_os] ?? "N/A" }}
            </span>
          </dd>
          <dt class="mt-2 col-lg-2 col-6">Recommendation:</dt>
          <dd class="mt-2 col-lg-4 col-6">
            <span :class="visit.flagged ? 'text-danger' : ''">
              {{ AppConfig.getVisitRecommendation(visit) }}
            </span>
          </dd>
        </dl>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-6">
        <h4 style="border-bottom: 1px solid gray">Visit details</h4>
        <dl class="row">
          <dt class="col-md-4 col-6">Status:</dt>
          <dd class="col-md-8 col-6">{{ formatStatus(visit) }}</dd>

          <template v-if="visit.location_id">
            <dt class="col-md-4 col-6">Clinic:</dt>
            <dd class="col-md-8 col-6">
              <router-link
                v-if="!fullScreenView"
                :to="'/locations/' + visit.location_id"
                >{{ visit.location_name }}</router-link
              >
              <span v-else>{{ visit.location_name }} </span>
            </dd>
          </template>

          <dt class="col-md-4 col-6">Started:</dt>
          <dd class="col-md-8 col-6">
            {{ formatDate(visit.created) }}
            <ByLine
              :userID="visit.creator_id"
              :userName="visit.creator_name"
              :disableLink="fullScreenView"
            />
          </dd>

          <dt class="col-md-4 col-6">Scanned:</dt>
          <dd class="col-md-8 col-6">
            {{ formatDate(visit.scanned) || "-" }}
            <ByLine
              :userID="visit.operator_id"
              :userName="visit.operator_name"
              :disableLink="fullScreenView"
            />
          </dd>

          <dt class="col-md-4 col-6">Analysis started:</dt>
          <dd class="col-md-8 col-6">
            {{ formatDate(visit.analysis_started) || "-" }}
            <ByLine
              v-if="!visit.analysis_finished"
              :userID="visit.analyst_id"
              :userName="visit.analyst_name"
              :disableLink="fullScreenView"
            />
          </dd>

          <dt class="col-md-4 col-6">Analysis finished:</dt>
          <dd class="col-md-8 col-6">
            {{ formatDate(visit.analysis_finished) || "-" }}
            <ByLine
              v-if="visit.analysis_finished"
              :userID="visit.analyst_id"
              :userName="visit.analyst_name"
              :disableLink="fullScreenView"
            />
          </dd>

          <dt class="col-md-4 col-6">Reviewed:</dt>
          <dd class="col-md-8 col-6">
            {{ formatDate(visit.reviewed) || "-" }}
            <ByLine
              v-if="visit.reviewed"
              :userID="visit.reviewer_id"
              :userName="visit.reviewer_name"
              :disableLink="fullScreenView"
            />
          </dd>
        </dl>
      </div>
      <div class="col-md-6">
        <h4 style="border-bottom: 1px solid gray">Patient details</h4>
        <dl class="row">
          <template v-if="visit.patient_id">
            <dt class="col-md-4 col-6">Name:</dt>
            <dd class="col-md-8 col-6">
              <router-link
                v-if="!fullScreenView"
                :to="'/patients/' + visit.patient_id"
              >
                {{ visit.name }}
              </router-link>
              <span v-else>
                {{ visit.name }}
              </span>
            </dd>

            <dt class="col-md-4 col-6">Contact information:</dt>
            <dd class="col-md-8 col-6">
              {{ visit.email }},
              {{ visit.phone }}
            </dd>
          </template>

          <dt class="col-md-4 col-6">Age, gender:</dt>
          <dd class="col-md-8 col-6">{{ visit.age }}, {{ visit.gender }}</dd>

          <dt class="col-md-4 col-6">Diabetes:</dt>
          <dd class="col-md-8 col-6">
            {{ formatYearsMonthsOnset(visit.diabetes_onset) }}
          </dd>

          <dt class="col-md-4 col-6">Hypertension:</dt>
          <dd class="col-md-8 col-6">
            {{ formatYearsMonthsOnset(visit.hypertension_onset) }}
          </dd>

          <dt class="col-md-4 col-6">Cataract (OD):</dt>
          <dd class="col-md-8 col-6">
            {{ formatCataract(visit.cataract_od) }}
          </dd>

          <dt class="col-md-4 col-6">Cataract (OS):</dt>
          <dd class="col-md-8 col-6">
            {{ formatCataract(visit.cataract_os) }}
          </dd>

          <dt class="col-md-4">Notes:</dt>
          <dd class="col-md-8 note">{{ visit.notes }}</dd>
        </dl>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-6">
        <h4 style="border-bottom: 1px solid gray">Visit notes</h4>
        <VisitNotes :visitID="visitID" />
      </div>
      <div class="col-md-6" v-if="visit.scanned">
        <h4 style="border-bottom: 1px solid gray">Images</h4>

        <div class="row">
          <div class="col-md-3" v-for="image in images" :key="image.id">
            <ThumbnailImage :image="image" @click="imageClicked" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <ModalForm v-show="errorMessage" @close="errorMessage = ''" title="Error">
    <div class="alert alert-danger">
      {{ errorMessage }}
    </div>
    <div class="mt-5">
      <button class="btn btn-secondary" @click="errorMessage = ''">
        Close
      </button>
    </div>
  </ModalForm>
  <ModalForm
    title="Confirm review"
    v-show="confirmReviewVisible"
    @close="confirmReviewVisible = false"
  >
    <p>
      Please confirm that review of this visit is complete. This action can't be
      undone.
    </p>
    <div class="text-center mt-5">
      <button
        class="btn btn-secondary me-3"
        @click="confirmReviewVisible = false"
      >
        Cancel
      </button>
      <button class="btn btn-primary" @click="confirmReview">Confirm</button>
    </div>
  </ModalForm>
  <ModalForm
    v-show="showImageCanvas"
    @close="showImageCanvas = false"
    :makeLarge="true"
  >
    <ImageCanvas :view_only="true" ref="ic" />
  </ModalForm>
</template>
<script setup>
import backend from "@/backend";
import ByLine from "@/components/ByLine.vue";
import ModalForm from "@/components/ModalForm.vue";
import ThumbnailImage from "@/components/ThumbnailImage.vue";
import { DrGrades } from "@/pages/Annotation/AnnotationGuide";
import ImageCanvas from "@/pages/Annotation/components/ImageCanvas.vue";
import VisitNotes from "@/pages/Annotation/components/VisitNotes.vue";
import router from "@/router";
import { formatDate, formatStatus } from "@/util/formatting";
import { AppConfig } from "@/util/AppConfig";
import usePolicy from "@/util/usePolicy";
import { Tooltip } from "bootstrap";
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
import { formatCataract, formatYearsMonthsOnset } from "../../util/formatting";

const props = defineProps(["visitID"]);

const { allowed } = usePolicy();
const store = useStore();

const visit = ref({});
const errorMessage = ref("");
const images = ref([]);
const confirmReviewVisible = ref(false);
const showImageCanvas = ref(false);
const ic = ref(null);

const fullScreenView =
  router.currentRoute.value.query["full-screen-view"] === "true";

async function loadVisit() {
  const visitResult = backend.visits.getVisit(props.visitID);
  const imagesResult = backend.visits.getVisitImages(props.visitID);

  visit.value = await visitResult;
  images.value = await imagesResult;
}

function gotoAnalysis() {
  router.push("/visits/" + props.visitID + "/analysis");
}

async function beginClicked() {
  await backend.visits.beginAnalysis(props.visitID);
  gotoAnalysis();
}

async function deleteClicked() {
  try {
    await backend.visits.deleteVisit(props.visitID);
  } catch (error) {
    if (error?.response?.status == 409) {
      errorMessage.value =
        "Visit is referenced by other data. Deletion not allowed.";
      return;
    }
    throw error;
  }
  router.push("/visits");
}

async function confirmReview() {
  await backend.visits.markReviewed(props.visitID);
  confirmReviewVisible.value = false;
  loadVisit();
}

async function viewReport() {
  const params = fullScreenView ? { "full-screen-view": true } : {};
  const result = await backend.visits.downloadReport(props.visitID, params);
  const actionType = backend.audit.userActionTypes.VIEW_REPORT;
  await logAction(actionType);
  const url = URL.createObjectURL(result);
  store.commit("SET_LAST_VIEWED_REPORT", url);
  window.open(url);
}

async function logAction(actionType) {
  const userId = store.state?.currentUser?.id;
  await backend.audit.logUserAction(userId, actionType, props.visitID);
}

async function resendReport() {
  await backend.visits.resendReport(props.visitID);
}

async function saveReport() {
  const result = await backend.visits.downloadReport(props.visitID);
  const actionType = backend.audit.userActionTypes.DOWNLOAD_REPORT;
  await logAction(actionType);

  const url = URL.createObjectURL(
    result.slice(0, result.size, "application/octet-stream")
  );
  store.commit("SET_LAST_VIEWED_REPORT", url);

  var a = document.createElement("a");
  a.href = url;
  a.download = "Oivi_Report_" + props.visitID + ".pdf";
  a.click();
}

function imageClicked(id) {
  for (const img of images.value) {
    if (img.id == id) {
      ic.value.loadImage(id);
      showImageCanvas.value = true;
    }
  }
}

new Tooltip(document.body, {
  selector: "[data-toggle='tooltip']",
});

onMounted(loadVisit);
</script>
<style scoped>
.cover-item {
  display: block;
  margin: 0 0 1rem 0;
  border-radius: 10px;
  background-clip: border-box;
  border: 1px solid rgb(248, 249, 250);
  width: 100%;
  height: 0;
  padding-bottom: 86%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
