import axios from "axios";

function usePatientsAPI() {
  const ep = import.meta.env.VITE_BIRDHOUSE_ENDPOINT;

  async function getPatients(
    limit = 20,
    offset = 0,
    sortBy = "",
    searchString = ""
  ) {
    return (
      await axios.get(import.meta.env.VITE_BIRDHOUSE_ENDPOINT + "/patients", {
        params: {
          limit: limit,
          offset: offset,
          sort: sortBy,
          q: searchString,
        },
      })
    ).data;
  }

  async function getPatientImportsData() {
    return (await axios.get(`${ep}/patients/import`)).data;
  }

  async function createPatient(
    name,
    mrn,
    email,
    phone,
    birthday,
    gender,
    diabetes_onset,
    hypertension_onset,
    cataract_od,
    cataract_os,
    notes
  ) {
    return (
      await axios.post(
        `${ep}/patients`,
        JSON.stringify({
          name: name,
          mrn: mrn,
          email: email,
          phone: phone,
          birthday: birthday,
          gender: gender,
          diabetes_onset: diabetes_onset,
          hypertension_onset: hypertension_onset,
          cataract_od: cataract_od,
          cataract_os: cataract_os,
          notes: notes,
        }),
        { headers: { "Content-Type": "application/json" } }
      )
    ).data.id;
  }

  async function createPatientV1(patient) {
    return (
      await axios.post(`${ep}/patients`, JSON.stringify(patient), {
        headers: { "Content-Type": "application/json" },
      })
    ).data.id;
  }

  function updatePatient(
    id,
    name,
    mrn,
    email,
    phone,
    birthday,
    gender,
    diabetes_onset,
    hypertension_onset,
    cataract_od,
    cataract_os,
    notes
  ) {
    return axios.patch(
      import.meta.env.VITE_BIRDHOUSE_ENDPOINT + "/patients/" + id,
      JSON.stringify({
        name: name,
        mrn: mrn,
        email: email,
        phone: phone,
        birthday: birthday,
        gender: gender,
        diabetes_onset: diabetes_onset,
        hypertension_onset: hypertension_onset,
        cataract_od: cataract_od,
        cataract_os: cataract_os,
        notes: notes,
      }),
      { headers: { "Content-Type": "application/json" } }
    );
  }

  function updatePatientV1(patientId, patient) {
    return axios.patch(
      import.meta.env.VITE_BIRDHOUSE_ENDPOINT + "/patients/" + patientId,
      JSON.stringify(patient),
      { headers: { "Content-Type": "application/json" } }
    );
  }

  async function getPatient(patientID) {
    return (await axios.get(ep + "/patients/" + patientID)).data;
  }

  async function createPatientVisit(patientID, locationID) {
    return (
      await axios.post(
        import.meta.env.VITE_BIRDHOUSE_ENDPOINT +
          "/patients/" +
          patientID +
          "/visits",
        JSON.stringify({ location_id: locationID }),
        { headers: { "Content-Type": "application/json" } }
      )
    ).data.id;
  }

  async function getPatientVisits(patientID, limit = 20, offset = 0) {
    return (
      await axios.get(
        import.meta.env.VITE_BIRDHOUSE_ENDPOINT +
          "/patients/" +
          patientID +
          "/visits",
        {
          params: {
            limit: limit,
            offset: offset,
          },
        }
      )
    ).data;
  }

  async function addPatientToOrg(patientID, orgID) {
    return axios.post(
      import.meta.env.VITE_BIRDHOUSE_ENDPOINT +
        "/patients/" +
        patientID +
        "/orgs",
      { id: orgID }
    );
  }

  async function removePatientFromOrg(patientID, orgID) {
    return axios.delete(
      import.meta.env.VITE_BIRDHOUSE_ENDPOINT +
        "/patients/" +
        patientID +
        "/orgs/" +
        orgID
    );
  }

  async function removePatient(patientID) {
    return axios.delete(
      import.meta.env.VITE_BIRDHOUSE_ENDPOINT + "/patients/" + patientID
    );
  }

  async function importPatients(file) {
    const formData = new FormData();
    formData.append("file", file);

    return (
      await axios.post(
        import.meta.env.VITE_BIRDHOUSE_ENDPOINT + "/patients/import",
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      )
    ).data;
  }

  return {
    getPatients,
    createPatient,
    createPatientV1,
    updatePatientV1,
    getPatient,
    updatePatient,
    createPatientVisit,
    getPatientVisits,
    addPatientToOrg,
    removePatientFromOrg,
    removePatient,
    importPatients,
    getPatientImportsData,
  };
}

export default usePatientsAPI;
