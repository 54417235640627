<template>
  <ModalForm
    :title="isEditing ? 'Edit User' : 'Add User'"
    ref="modalForm"
    footerButtonLabel="Save"
    :footerButtonAction="save"
    footerButtonClass="btn-outline-dark btn-lg"
  >
    <form>
      <div class="form-outline mb-2">
        <TextInput
          inputLabel="Email"
          inputName="Email"
          placeholder="Email"
          v-model="userDetails.email"
          :disabled="isEditing ? true : false"
        />
      </div>

      <div class="form-outline mb-2" v-if="isEditing">
        <TextInput
          inputLabel="Name"
          inputName="Name"
          placeholder="Name"
          v-model="userDetails.name"
        />
      </div>
      <div class="form-outline mb-2" v-if="isEditing">
        <PhoneInput
          inputLabel="Phone Number"
          inputName="PhoneNumber"
          placeholder="Phone number"
          v-model="userDetails.phone"
        />
      </div>
      <div class="form-outline m2-4" v-if="orgs">
        <SelectInput
          groupLabel="Organisation:"
          selectName="Organisation"
          v-model="userDetails.organization_id"
          :options="orgs.map((org) => ({ label: org.name, value: org.id }))"
          :placeholder="!isEditing && '-- select organisation --'"
        />
      </div>
      <div class="form-outline m2-4">
        <CheckBoxInput
          groupLabel="Roles"
          checkName="roles"
          v-model="userDetails.roles"
          :options="
            props.roles.map((role) => ({
              label: getRoleLabel(role),
              value: role,
            }))
          "
          flexRow="true"
        />
        <div v-if="userCanHaveQualifications(userDetails?.roles)">
          <TextInput
            inputLabel="Qualifications (optional)"
            inputName="Qualifications"
            placeholder="MBBS, DOMS, ..."
            v-model="userDetails.qualifications"
          />
        </div>
      </div>
      <div class="alert alert-danger" v-if="formError">
        {{ formError }}
      </div>
    </form>
  </ModalForm>
</template>
<script setup>
import { ref, onMounted, reactive } from "vue";
import { useStore } from "vuex";
import backend from "@/backend";
import ModalForm from "@/components/ModalForm.vue";
import TextInput from "@/components/Forms/TextInput.vue";
import SelectInput from "@/components/Forms/SelectInput.vue";
import CheckBoxInput from "@/components/Forms/CheckBoxInput.vue";
import PhoneInput from "@/components/Forms/PhoneInput.vue";
import { userCanHaveQualifications } from "@/util/formatting";

const store = useStore();

const formError = ref("");
const modalForm = ref(null);
const props = defineProps(["userID", "roles", "user"]);
const emit = defineEmits(["change"]);
const orgs = ref(null);

const isEditing = props.userID !== undefined;

const emptyForm = {
  email: "",
  orgId: "",
  roles: [],
  qualifications: null,
};

const userDetails = isEditing
  ? reactive({ ...props.user })
  : reactive(emptyForm);

onMounted(async function () {
  if (
    store.getters.currentUser?.actions?.includes("ACCESS_ALL_ORGANIZATIONS")
  ) {
    orgs.value = await backend.organizations.listOrganizations();
  } else {
    orgs.value = null;
  }
});

function getRoleLabel(roleValue) {
  return roleValue
    .replace("_", " ")
    .split(" ")
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(" ");
}

async function updateUser(data) {
  await backend.users.updateUser(props.userID, {
    ...data,
    phone: userDetails.phone,
  });
}

async function saveNewUser(data) {
  try {
    await backend.users.addUser({
      ...data,
      email: userDetails.email,
    });
  } catch (error) {
    if (error?.response?.status == 409) {
      formError.value = "Email address is in use";
      return;
    }
    throw error;
  }
}

async function save() {
  const data = {
    name: userDetails.name,
    roles: userDetails.roles,
    qualifications: userCanHaveQualifications(userDetails.roles)
      ? userDetails.qualifications
      : null,
  };
  if (orgs.value) data["organization_id"] = userDetails.organization_id;

  if (isEditing) await updateUser(data);
  else await saveNewUser(data);

  modalForm.value.close();
  emit("change");
}
</script>
