<template>
  <div class="d-inline-block">
    <label
      v-if="!props.hasLabelInline && props.inputLabel"
      :for="props.inputName"
      class="form-label fw-medium-bold"
      >{{ props.inputLabel }}</label
    >
    <div
      class="d-flex align-items-center"
      :class="{
        'input-group d-flex ': props.hasLabelInline,
        'disabled-number-input': props.disabled,
      }"
    >
      <Field
        :name="props.inputName"
        :value="props.modelValue"
        :rules="props.validator"
        v-slot="{ field, errorMessage }"
        @input="$emit('update:modelValue', $event.target.value)"
      >
        <input
          :id="props.inputName"
          v-bind="field"
          type="number"
          class="form-control number-input-field"
          :class="{ 'is-invalid': errorMessage }"
          :placeholder="props.placeholder"
          autocomplete="off"
          :min="props.min"
          :max="props.max"
          :style="
            errorMessage
              ? { maxWidth: getInputMaxWidth(true) }
              : { maxWidth: getInputMaxWidth(false) }
          "
          :disabled="props.disabled"
        />
        <span
          v-if="props.hasLabelInline"
          class="input-group-text rounded-end px-1 text-center"
          id="basic-addon2"
          :style="{
            display: 'block',
            width: `${props.inputTextWidthPixels}px`,
          }"
          >{{ props.inputLabel }}</span
        >
      </Field>
      <div class="ms-2">
        {{ props.suffix }}
      </div>
    </div>
    <div v-if="!hideErrorMessage" class="d-flex">
      <ErrorMessage
        class="error-message flex-grow-1 my-1"
        :style="{ width: 0 }"
        :name="props.inputName"
        :id="props.inputName + 'ErrorMessage'"
      />
    </div>
  </div>
</template>

<script setup>
import { configure, Field, ErrorMessage } from "vee-validate";
const props = defineProps([
  "modelValue",
  "inputLabel",
  "inputName",
  "validator",
  "placeholder",
  "min",
  "max",
  "hasLabelInline",
  "maxWidthPixels",
  "inputTextWidthPixels",
  "hideErrorMessage",
  "suffix",
  "disabled",
]);
function getInputMaxWidth(inputIsInvalid) {
  const baseWidth = parseInt(props.maxWidthPixels);
  if (inputIsInvalid) return `${baseWidth + 20}px`;
  return `${baseWidth}px`;
}
configure({
  validateOnInput: true,
});
</script>
<style lang="scss">
@import "@/style/style.scss";

.error-message {
  color: $danger;
}
.disabled-number-input {
  opacity: 50% !important;
}

.disabled-number-input input {
  background-color: white !important;
}

/* Hide the up- and down arrows in Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide the up- and down arrows in Firefox */
input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}
</style>
