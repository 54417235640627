<template>
  <SplashCard @onEnter="handleEnterKeyUp">
    <div class="mb-md-5">
      <h2 class="fw-bold mb-2">Login</h2>

      <p class="mb-5">Please enter your email and password</p>
      <div class="form-outline mb-4" :class="{ 'has-error': emailError }">
        <input
          type="email"
          id="typeEmailX"
          class="form-control form-control-lg"
          placeholder="Email"
          v-model="email"
        />
        <p class="errMessage" v-if="emailError">{{ emailError }}</p>
      </div>

      <div class="form-outline mb-4" :class="{ 'has-error': passwordError }">
        <input
          type="password"
          id="typePasswordX"
          class="form-control form-control-lg"
          placeholder="Password"
          v-model="password"
        />
        <p class="errMessage" v-if="passwordError">
          {{ passwordError }}
        </p>
      </div>

      <div
        v-if="showOTPInput"
        class="form-outline mb-4"
        :class="{ 'has-error': otpError }"
      >
        <input
          type="text"
          id="typeOTP"
          class="form-control form-control-lg"
          placeholder="Enter OTP"
          v-model="otp"
        />
        <p class="errMessage" v-if="otpError">
          {{ otpError }}
        </p>
      </div>

      <p class="small mb-5 pb-lg-2">
        <a class="link" @click="goToResetPasswordPage">Forgot password?</a>
      </p>
      <div>
        <p class="errMessage" v-if="errorMessage">{{ errorMessage }}</p>
      </div>

      <MfaSetupDialog
        v-if="showMfaSetupDialog"
        @close="handleCloseMfaSetupDialog"
      >
      </MfaSetupDialog>

      <button
        class="btn btn-oivi-light btn-lg px-5"
        type="submit"
        @click="showOTPInput ? handleConfirmSignIn() : handleSignIn()"
        :disabled="inProgress"
      >
        <div
          class="spinner-border spinner-border-sm"
          role="status"
          v-if="inProgress"
        >
          <span class="visually-hidden">Loading...</span>
        </div>
        <span>{{ showOTPInput ? "Verify OTP" : "Login" }}</span>
      </button>
    </div>

    <div>
      <p class="mb-0">
        Don't have an account?
        <a @click="goToSignUpPage" class="fw-bold link">Sign Up</a>
      </p>
    </div>
  </SplashCard>
</template>

<script setup>
import { ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import backend from "@/backend";
import SplashCard from "@/components/SplashCard.vue";
import MfaSetupDialog from "@/components/MfaSetupDialog.vue";
import { userMustEnableMFA } from "@/util/formatting";

const router = useRouter();
const store = useStore();
const showOTPInput = ref(false);
const email = ref("");
const otp = ref("");
const password = ref("");
const inProgress = ref(false);
const _cognitoUser = ref();
const errorMessage = ref("");
const emailError = ref("");
const passwordError = ref("");
const otpError = ref("");
const showMfaSetupDialog = ref(false);

async function handleSignIn() {
  if (!validateForm()) return;

  inProgress.value = true;
  try {
    store.commit("SET_CURRENT_USER", { email: email.value });
    _cognitoUser.value = await backend.auth.signIn(email.value, password.value);
    const challengeName = _cognitoUser.value?.nextStep?.signInStep;

    if (challengeName === "CONFIRM_SIGN_IN_WITH_TOTP_CODE") {
      showOTPInput.value = true;
    } else {
      const dbUser = await backend.users.getCurrentUser();
      const userRoles = dbUser.roles ?? [];
      if (userMustEnableMFA(userRoles)) {
        showMfaSetupDialog.value = true;
      } else {
        await signInUser();
      }
    }
  } finally {
    inProgress.value = false;
  }
}

async function handleConfirmSignIn() {
  if (!isValidOtp(otp.value)) {
    otpError.value = "Please enter a valid TOTP";
    return;
  }

  inProgress.value = true;
  try {
    await backend.auth.confirmSignIn(otp.value);
    await signInUser();
  } finally {
    inProgress.value = false;
  }
}

async function handleCloseMfaSetupDialog(result) {
  inProgress.value = true;
  try {
    showMfaSetupDialog.value = false;
    if (result) await signInUser();
  } finally {
    inProgress.value = false;
  }
}

async function signInUser() {
  const dbUser = await backend.users.getCurrentUser();
  const cognitoUser = await backend.auth.currentAuthenticatedUser();
  const user = {
    email: cognitoUser.signInDetails.loginId,
    signedIn: true,
    ...dbUser,
  };

  store.commit("SET_CURRENT_USER", user);
  const userId = cognitoUser.userId;
  try {
    const actionType = backend.audit.userActionTypes.PHI_ACCESS;
    await backend.audit.logUserAction(userId, actionType);
  } catch (error) {
    console.log("Failed to log user action : " + error);
  }
  router.push("/");
}

watch(email, () => {
  emailError.value = "";
  errorMessage.value = "";
});

watch(password, () => {
  passwordError.value = "";
  errorMessage.value = "";
});

watch(otp, () => {
  otpError.value = "";
  errorMessage.value = "";
});

function handleEnterKeyUp() {
  if (inProgress.value) return;
  handleSignIn();
}

function goToSignUpPage() {
  router.push({ name: "SignUp" });
}

function goToResetPasswordPage() {
  router.push("/forgot-password");
}

function isValidEmail(email) {
  return !!/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(email);
}

function isValidPassword(password) {
  return password.length > 0;
}

function isValidOtp(otp) {
  return otp.length >= 6;
}

function validateForm() {
  if (!isValidEmail(email.value) && !isValidPassword(password.value)) {
    passwordError.value = "Please enter a valid password";
    emailError.value = "Please enter a valid email";
    return false;
  }
  if (!isValidEmail(email.value)) {
    emailError.value = "Please enter a valid email";
    return false;
  }

  if (!isValidPassword(password.value)) {
    passwordError.value = "Please enter a valid password";
    return false;
  }

  return true;
}
</script>

<style scoped>
.link {
  cursor: pointer;
}
.errMessage {
  position: absolute;
  top: 45px;
  bottom: 0px;
  left: 3px;
  margin-top: 2px;
  font-size: 12px;
  color: red;
}
.has-error .form-control {
  border-color: red;
}
.form-outline {
  position: relative;
}
</style>
