<template>
  <section class="vh-100 gradient-custom">
    <SplashCard @onEnter="handleEnterKeyUp">
      <h6 class="fw-bold text-uppercase">Validate Verification Code</h6>
      <p class="mb-0">A code has been sent to:</p>
      <small>{{ userEmail }}</small>
      <div class="form-outline form-white mb-4 mt-4">
        <input
          type="tel"
          pattern="[0-9]*"
          @input="otp = otp.replace(/[^0-9]/g, '')"
          maxlength="6"
          class="form-control form-control-lg"
          placeholder="6-digit OTP/Verification Code"
          v-model="otp"
          :class="[otp.length === 6 ? 'border-success' : 'border-danger']"
        />
      </div>
      <div class="alert alert-danger" v-if="verificationCodeError">
        {{ verificationCodeError }}
      </div>
      <div class="mt-4 p-2">
        <button
          class="btn btn-primary btn-lg px-5"
          @click="cbValidate"
          :disabled="inProgress || otp.toString().length != 6"
        >
          <div
            class="spinner-border spinner-border-sm"
            role="status"
            v-if="inProgress"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
          <span v-if="!inProgress">Validate</span>
        </button>
      </div>
      <div class="text-center m-1">
        <br />
        <small
          >Didn't get the code?
          <a class="ms-2 link" @click="resendCode">Resend</a>
        </small>
      </div>
    </SplashCard>
  </section>
</template>

<script setup>
import { ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import backend from "@/backend";
import SplashCard from "@/components/SplashCard.vue";

const store = useStore();
const router = useRouter();
const userEmail = store.getters.currentUser.email;
const otp = ref("");
const inProgress = ref(false);
const verificationCodeError = ref("");

async function cbValidate() {
  inProgress.value = true;
  verificationCodeError.value = "";
  if (otp.value.toString().length == 6) {
    try {
      await backend.auth.confirmSignUp(userEmail, otp.value.toString());
      router.push({ name: "SignIn" });
    } catch (error) {
      console.error(error.message);
      verificationCodeError.value = error.message;
    }
  }
  inProgress.value = false;
}

async function resendCode() {
  await backend.auth.resendConfirmationCode(userEmail);
}

function handleEnterKeyUp() {
  if (inProgress.value) return;
  cbValidate();
}
</script>

<style scoped>
.height-100 {
  height: 100vh;
}

.inputs input {
  width: 40px;
  height: 40px;
  font-weight: bold;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.link {
  color: #6a9e7c;
  cursor: pointer;
}

.form-control:focus {
  box-shadow: none;
  border: 2px solid #6a9e7c;
}
</style>
