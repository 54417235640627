<template>
  <ModalForm v-show="visible" @close="visible = false" title="Add clinic">
    <div class="form-outline mb-4">
      <input
        type="text"
        class="form-control form-control-lg"
        placeholder="Clinic name"
        v-model="formData.name"
      />
    </div>

    <div class="form-outline mb-4">
      <input
        type="text"
        class="form-control form-control-lg"
        placeholder="Email"
        v-model="formData.email"
      />
    </div>

    <div class="form-outline mb-4">
      <input
        type="text"
        class="form-control form-control-lg"
        placeholder="Notification email"
        v-model="formData.notification_email"
      />
      <small class="text-muted">
        Email used to notify when reports are ready. Leave blank if not needed.
      </small>
    </div>

    <div class="form-outline mb-4">
      <input
        type="text"
        class="form-control form-control-lg"
        placeholder="Phone number"
        v-model="formData.phone"
      />
    </div>

    <div class="form-outline mb-4">
      <input
        type="text"
        class="form-control form-control-lg"
        placeholder="Address"
        v-model="formData.address"
      />
    </div>

    <div class="alert alert-danger" v-if="formError">
      {{ formError }}
    </div>

    <div class="text-center mt-5">
      <button class="btn btn-lg btn-oivi-light" @click="addClinic">
        Add clinic
      </button>
    </div>
  </ModalForm>
</template>
<script setup>
import { reactive, ref, watch } from "vue";
import backend from "@/backend";
import { validateEmail } from "@/util/validation";
import ModalForm from "@/components/ModalForm.vue";

const props = defineProps(["orgID"]);
const emit = defineEmits(["dataChange"]);

const visible = ref(false);
const formDataDefaults = {
  name: "",
  email: "",
  phone: "",
  address: "",
  notification_email: "",
};
const formData = reactive({ ...formDataDefaults });
const formError = ref("");

watch(visible, async (newValue) => {
  if (newValue == false) {
    for (var key in formData) formData[key] = formDataDefaults[key];
    formError.value = "";
  }
});

async function addClinic() {
  if (!formData.name.trim()) {
    formError.value = "Required: clinic name";
  } else if (formData.email && !validateEmail(formData.email)) {
    formError.value = "Invalid email format";
  } else {
    await backend.locations.addOrgLocation(props.orgID, formData);
    visible.value = false;
    emit("dataChange");
  }
}

function show() {
  visible.value = true;
}

defineExpose({ show });
</script>
