<template>
  <BreadCrumbs current="My Account" />
  <div class="body container-fluid">
    <div class="container">
      <dl class="row">
        <dt class="col-sm-3">Email:</dt>
        <dd class="col-sm-9">{{ user.email }}</dd>

        <dt class="col-sm-3">Organization:</dt>
        <dd class="col-sm-9">{{ user.organization }}</dd>

        <dt class="col-sm-3">Roles:</dt>
        <dd class="col-sm-9">
          <span v-for="role in user.roles" :key="role" class="me-1">{{
            role
          }}</span>
        </dd>

        <dt class="col-sm-3">Name:</dt>
        <dd class="col-sm-9">
          <input type="text" class="form-control" v-model="fullname" />
        </dd>

        <dt class="col-sm-3">Phone:</dt>
        <dd class="col-sm-9">
          <input type="text" class="form-control" v-model="phone" />
        </dd>
        <dt v-if="showQualifications" class="col-sm-3">Qualifications:</dt>
        <dd v-if="showQualifications" class="col-sm-9">
          <input type="text" class="form-control" v-model="qualifications" />
        </dd>

        <dd class="col-sm-9 offset-sm-3">
          <button class="btn btn-primary" @click="save">Save</button>
        </dd>
      </dl>
    </div>
  </div>
</template>
<script setup>
import { ref } from "vue";
import { useStore } from "vuex";
import backend from "@/backend";
import { userCanHaveQualifications } from "@/util/formatting";

const store = useStore();
const user = store.getters.currentUser;
const fullname = ref(user.name);
const phone = ref(user.phone);
const showQualifications = userCanHaveQualifications(user.roles);
const qualifications = ref(user.qualifications);
async function save() {
  await backend.users.updateCurrentUser(
    fullname.value,
    phone.value,
    qualifications.value
  );
  const dbUser = await backend.users.getCurrentUser();
  const updatedUser = {
    ...user,
    ...dbUser,
  };
  store.commit("SET_CURRENT_USER", updatedUser);
}
</script>
