import store from "@/store"; // Import your Vuex store

export const BUTTON_ACTION = "buttonClick";
export const ROUTE_CHANGE = "routeChange";

const dispatchQueue = [];
let isDispatching = false;

const processQueue = () => {
  if (isDispatching || dispatchQueue.length === 0) return;

  const [actionName, payload] = dispatchQueue.shift();
  isDispatching = true;

  store
    .dispatch(actionName, payload)
    .then(() => {
      isDispatching = false;
      processQueue(); // Continue processing the next item in the queue
    })
    .catch((error) => {
      console.error(`Error dispatching '${actionName}'`, error);
      isDispatching = false;
      processQueue(); // Continue processing the next item in the queue regardless of the error
    });
};

export const logRoutingInQue = (activityData) => {
  dispatchQueue.push(["addActivityLog", activityData]);
  processQueue();
};

export function getFormattedDateUTC() {
  const date = new Date();

  const fullMilliseconds = date.getTime();
  const seconds = Math.floor(fullMilliseconds / 1000);
  const microseconds = (fullMilliseconds % 1000) * 1000;

  // Construct a new Date object using the seconds
  const utcDate = new Date(seconds * 1000);

  const formattedDateUTC = `${utcDate.getUTCFullYear()}-${String(
    utcDate.getUTCMonth() + 1
  ).padStart(2, "0")}-${String(utcDate.getUTCDate()).padStart(2, "0")} ${String(
    utcDate.getUTCHours()
  ).padStart(2, "0")}:${String(utcDate.getUTCMinutes()).padStart(
    2,
    "0"
  )}:${String(utcDate.getUTCSeconds()).padStart(2, "0")}.${String(
    microseconds
  ).padStart(6, "0")}`;

  return formattedDateUTC;
}
