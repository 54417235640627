<template>
  <ModalForm
    v-show="visible"
    @close="visible = false"
    title="Add organization"
    footerButtonLabel="Add organization"
    :footerButtonAction="addOrg"
    footerButtonClass="btn-primary btn-lg"
  >
    <div class="form-outline mb-4">
      <label class="form-label">Name:</label>
      <input
        type="text"
        class="form-control form-control-lg"
        placeholder="Organization name"
        v-model="formData.name"
      />
    </div>

    <div class="form-outline mb-4">
      <label class="form-label">Email:</label>
      <input
        type="text"
        class="form-control form-control-lg"
        placeholder="Email"
        v-model="formData.email"
      />
    </div>

    <div class="form-outline mb-4">
      <label class="form-label">Phone number:</label>
      <input
        type="text"
        class="form-control form-control-lg"
        placeholder="Phone number"
        v-model="formData.phone"
      />
    </div>

    <div class="form-outline mb-4">
      <label class="form-label">Address:</label>
      <input
        type="text"
        class="form-control form-control-lg"
        placeholder="Address"
        v-model="formData.address"
      />
    </div>

    <div class="form-outline mb-4" v-if="requiresSiteID">
      <label class="form-label"
        >Site Id:
        <i
          class="bi bi-info-circle ms-2"
          id="info-icon"
          data-toggle="tooltip"
          title="Site id must be added before sending images for grading"
        ></i
      ></label>
      <input
        type="text"
        class="form-control form-control-lg"
        placeholder="Site Id"
        v-model="formData.site_id"
      />
    </div>

    <div class="form-outline mb-4">
      <label class="form-label">Analyst organization:</label>
      <select class="form-select" v-model="formData.analyst_org_id">
        <option value="" disabled>- select analyst organization -</option>
        <option value="self">(self)</option>
        <option v-for="org in orgs" :key="org.id" :value="org.id">
          {{ org.name }}
        </option>
      </select>
    </div>

    <div class="alert alert-danger" v-if="formError">
      {{ formError }}
    </div>
  </ModalForm>
</template>
<script setup>
import backend from "@/backend";
import ModalForm from "@/components/ModalForm.vue";
import { AppConfig } from "@/util/AppConfig";
import { validateEmail } from "@/util/validation";
import { Tooltip } from "bootstrap";
import { computed, reactive, ref, watch } from "vue";

const emit = defineEmits(["dataChange"]);

const visible = ref(false);
const formDataDefaults = {
  name: "",
  email: "",
  phone: "",
  address: "",
  analyst_org_id: "",
  site_id: "",
};
const formData = reactive({ ...formDataDefaults });
const formError = ref("");
const orgs = ref(null);
const requiresSiteID = computed(() => AppConfig.organizationNeedSiteID);

watch(visible, async (newValue) => {
  if (newValue == false) {
    for (var key in formData) formData[key] = formDataDefaults[key];
    formError.value = "";
  }
});

new Tooltip(document.body, { selector: "[data-toggle='tooltip']" });

async function addOrg() {
  if (!formData.name.trim()) {
    formError.value = "Required: organization name";
  } else if (formData.email && !validateEmail(formData.email)) {
    formError.value = "Invalid email format";
  } else if (!formData.analyst_org_id) {
    formError.value = "Required: analyst organization";
  } else if (AppConfig.organizationNeedSiteID && !formData.site_id) {
    formError.value = "Required: Site Id";
  } else {
    await backend.organizations.addOrganization(formData);
    visible.value = false;
    emit("dataChange");
  }
}

async function show() {
  orgs.value = await backend.organizations.listOrganizations();
  visible.value = true;
}

defineExpose({ show });
</script>
