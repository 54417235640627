<template>
  <div class="body container-fluid">
    <div class="row">
      <h2 class="col-9">Statistics</h2>

      <div class="col-3 mt-2" v-if="allowed('VIEW_ORGANIZATION')">
        <select class="form-select w-100" v-model="selectedLocationId">
          <option value="">- all clinics -</option>
          <option v-for="loc in locations" :key="loc.id" :value="loc.id">
            {{ loc.name }}
          </option>
        </select>
      </div>

      <div class="row mt-3">
        <div class="col-md-3">
          <label for="startDate" class="fw-bolder mb-2">Start date</label>
          <input
            type="date"
            id="startDate"
            v-model="startDate"
            class="form-control"
          />
        </div>
        <div class="col-md-3">
          <label for="endDate" class="fw-bolder mb-2">End date</label>
          <input
            type="date"
            id="endDate"
            v-model="endDate"
            class="form-control"
          />
        </div>
      </div>

      <div class="row mt-3 justify-content-center py-2 mb-2">
        <div class="col-md-6 text-center">
          <button
            @click="loadStatistics"
            class="btn btn-oivi-light btn-sm"
            style="margin-right: 15px"
          >
            Filter
          </button>
          <button @click="clearDateFilter" class="btn btn-secondary btn-sm">
            Reset
          </button>

          <button
            @click="downloadData"
            class="btn btn-oivi-light btn-sm float-end"
          >
            Download Data
          </button>
        </div>
      </div>
      <table
        class="table table-bordered border-primary table-striped mt-3"
        style="table-layout: fixed; white-space: normal"
      >
        <thead>
          <tr style="text-indent: 0rem">
            <th role="button" @click="setSortBy('visit_month')">
              {{ PeriodheaderText }}
              <SortIcon col="visit_month" :sorting="sortBy" />
            </th>
            <th
              role="button"
              data-toggle="tooltip"
              title="The total number of completed visits"
              @click="setSortBy('total_visits')"
            >
              Completed visits
              <SortIcon col="total_visits" :sorting="sortBy" />
            </th>
            <th
              role="button"
              data-toggle="tooltip"
              title="The number of visits per day (up to current day and including weekends)"
              @click="setSortBy('total_days')"
            >
              Visits per day
              <SortIcon col="total_days" :sorting="sortBy" />
            </th>
            <th
              role="button"
              data-toggle="tooltip"
              title="The number of visits that have analysis completed"
              @click="setSortBy('analysed_visits')"
            >
              Analysed visits
              <SortIcon col="analysed_visits" :sorting="sortBy" />
            </th>
            <th
              role="button"
              data-toggle="tooltip"
              title="The number of visits where neither of the eyes scanned were marked as 'inconclusive'"
              @click="setSortBy('conclusive_analysed_visits')"
              style="text-transform: none"
            >
              Successful analyses
              <SortIcon col="conclusive_analysed_visits" :sorting="sortBy" />
            </th>
            <th
              role="button"
              data-toggle="tooltip"
              title="successful analysis done within 30 minutes"
              @click="setSortBy('analysed_visits_within_target_time')"
            >
              Analysed in 30 min
              <SortIcon
                col="analysed_visits_within_target_time"
                :sorting="sortBy"
              />
            </th>
            <th
              role="button"
              data-toggle="tooltip"
              title="The median time taken from scan complete until analysis complete (hrs:mins:seconds)"
              @click="setSortBy('median_scan_to_analysis_time')"
            >
              Analysis response time
              <SortIcon col="median_scan_to_analysis_time" :sorting="sortBy" />
            </th>
            <th
              role="button"
              data-toggle="tooltip"
              title="The median time taken for analysis the analysis to be completed (hrs:mins:seconds)"
              @click="setSortBy('median_analysis_time')"
            >
              Analysis time
              <SortIcon col="median_analysis_time" :sorting="sortBy" />
            </th>
            <th
              role="button"
              data-toggle="tooltip"
              title="The number of visits where DR was detected in at least one eye"
              @click="setSortBy('dr_detected')"
            >
              Visits with DR
              <SortIcon col="dr_detected" :sorting="sortBy" />
            </th>
            <th
              role="button"
              data-toggle="tooltip"
              title="Average Age at the time of the scan"
              @click="setSortBy('avg_age_at_scan')"
            >
              Average Age at Scan
              <SortIcon col="avg_age_at_scan" :sorting="sortBy" />
            </th>
            <th
              role="button"
              data-toggle="tooltip"
              title="Average Age Now"
              @click="setSortBy('avg_age_now')"
            >
              Average Age Now
              <SortIcon col="avg_age_now" :sorting="sortBy" />
            </th>
            <th
              role="button"
              data-toggle="tooltip"
              title="The average number of images taken per visit"
              @click="setSortBy('avg_images_per_visit')"
            >
              Uploaded Images Per Visit
              <SortIcon col="avg_images_per_visit" :sorting="sortBy" />
            </th>
            <th
              role="button"
              data-toggle="tooltip"
              title="Total number of images taken"
              @click="setSortBy('total_images')"
            >
              Total Uploaded Images
              <SortIcon col="total_images" :sorting="sortBy" />
            </th>
            <th
              role="button"
              data-toggle="tooltip"
              title="Image Captures"
              @click="setSortBy('avg_image_captures')"
            >
              Image Captures Per Visit
              <SortIcon col="avg_image_captures" :sorting="sortBy" />
            </th>
            <th
              role="button"
              data-toggle="tooltip"
              title="Image Deletions"
              @click="setSortBy('avg_image_deletions')"
            >
              Image Deletions Per Visit
              <SortIcon col="avg_image_deletions" :sorting="sortBy" />
            </th>
            <th
              role="button"
              data-toggle="tooltip"
              title="Image Cancellations"
              @click="setSortBy('avg_image_cancellations')"
            >
              Image Cancellations Per Visit
              <SortIcon col="avg_image_cancellations" :sorting="sortBy" />
            </th>
            <th
              role="button"
              data-toggle="tooltip"
              title="Image Failures"
              @click="setSortBy('avg_image_failures')"
            >
              Image Failures Per Visit
              <SortIcon col="avg_image_failures" :sorting="sortBy" />
            </th>
            <th
              role="button"
              data-toggle="tooltip"
              title="Capture time"
              @click="setSortBy('avg_time_to_capture_seconds')"
            >
              Capture time per visit(s)
              <SortIcon col="avg_time_to_capture_seconds" :sorting="sortBy" />
            </th>
            <th
              role="button"
              data-toggle="tooltip"
              title="Upload time"
              @click="setSortBy('avg_time_to_upload_seconds')"
            >
              Upload time per visit(s)
              <SortIcon col="avg_time_to_upload_seconds" :sorting="sortBy" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="row in visitsStatistics"
            :key="row.visit_month"
            style="text-indent: 0rem"
          >
            <td v-if="row.visit_month != null">
              {{ formatVisitMonth(row.visit_month) }}
            </td>
            <td v-else>Total</td>

            <td>{{ row.total_visits }}</td>

            <td>
              {{ round(row.total_visits / row.total_days, 1) }}
            </td>

            <td>
              <span>{{ row.analysed_visits }}</span>
              <span v-if="row.total_visits !== 0">
                ({{ round((row.analysed_visits * 100) / row.total_visits) }}%)
              </span>
            </td>

            <td>
              <span>{{ row.conclusive_analysed_visits }}</span>
              <span v-if="row.analysed_visits !== 0">
                ({{
                  round(
                    (row.conclusive_analysed_visits * 100) / row.analysed_visits
                  )
                }}%)
              </span>
            </td>

            <td>
              <span>
                {{ row.analysed_visits_within_target_time }}
              </span>
              <span v-if="row.analysed_visits !== 0">
                ({{
                  round(
                    (row.analysed_visits_within_target_time * 100) /
                      row.analysed_visits
                  )
                }}%)
              </span>
            </td>

            <td>{{ formatTimeTaken(row.median_scan_to_analysis_time) }}</td>

            <td>{{ formatTimeTaken(row.median_analysis_time) }}</td>

            <td>
              {{ row.dr_detected }} ({{
                round((row.dr_detected * 100) / row.total_visits, 1)
              }}%)
            </td>

            <td v-if="row.visit_month != null || visitsStatistics.length == 1">
              {{ round(row.avg_age_at_scan, 1) }}
            </td>
            <td v-else>
              {{
                round(row.avg_age_at_scan / (visitsStatistics.length - 1), 1)
              }}
            </td>

            <td v-if="row.visit_month != null || visitsStatistics.length == 1">
              {{ round(row.avg_age_now, 1) }}
            </td>
            <td v-else>
              {{ round(row.avg_age_now / (visitsStatistics.length - 1), 1) }}
            </td>

            <td v-if="row.visit_month != null || visitsStatistics.length == 1">
              {{ round(row.avg_images_per_visit, 1) }}
            </td>
            <td v-else>
              {{
                round(
                  row.avg_images_per_visit / (visitsStatistics.length - 1),
                  1
                )
              }}
            </td>

            <td>
              {{ row.total_images }}
            </td>

            <td v-if="row.visit_month != null || visitsStatistics.length == 1">
              {{ round(row.avg_image_captures, 1) }}
            </td>
            <td v-else>
              {{
                round(row.avg_image_captures / (visitsStatistics.length - 1), 1)
              }}
            </td>

            <td v-if="row.visit_month != null || visitsStatistics.length == 1">
              {{ round(row.avg_image_deletions, 1) }}
            </td>
            <td v-else>
              {{
                round(
                  row.avg_image_deletions / (visitsStatistics.length - 1),
                  1
                )
              }}
            </td>

            <td v-if="row.visit_month != null || visitsStatistics.length == 1">
              {{ round(row.avg_image_cancellations, 1) }}
            </td>
            <td v-else>
              {{
                round(
                  row.avg_image_cancellations / (visitsStatistics.length - 1),
                  1
                )
              }}
            </td>

            <td v-if="row.visit_month != null || visitsStatistics.length == 1">
              {{ round(row.avg_image_failures, 1) }}
            </td>
            <td v-else>
              {{
                round(row.avg_image_failures / (visitsStatistics.length - 1), 1)
              }}
            </td>
            <td v-if="row.visit_month != null || visitsStatistics.length == 1">
              {{ round(row.avg_time_to_capture_seconds, 1) }}
            </td>
            <td v-else>
              {{
                round(
                  row.avg_time_to_capture_seconds /
                    (visitsStatistics.length - 1),
                  1
                )
              }}
            </td>
            <td v-if="row.visit_month != null || visitsStatistics.length == 1">
              {{ round(row.avg_time_to_upload_seconds, 1) }}
            </td>
            <td v-else>
              {{
                round(
                  row.avg_time_to_upload_seconds /
                    (visitsStatistics.length - 1),
                  1
                )
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onBeforeMount } from "vue";
import backend from "@/backend";
import { useRouter } from "vue-router";
import usePolicy from "@/util/usePolicy";
import SortIcon from "@/components/SortIcon.vue";

const { allowed } = usePolicy();
const router = useRouter();

const visitsStatistics = ref([]);
const locations = ref([]);
const selectedLocationId = ref("");
const sortBy = ref("-visit_month");
const startDate = ref("");
const endDate = ref("");
const PeriodheaderText = ref("Visit Month");

function clearDateFilter() {
  startDate.value = "";
  endDate.value = "";
  loadStatistics();
}

async function downloadData() {
  const host =
    window.location.protocol +
    "//" +
    window.location.hostname +
    (window.location.port ? ":" + window.location.port : "");

  const response = await backend.visits.downloadVisitStatistics(
    selectedLocationId.value,
    sortBy.value,
    startDate.value,
    endDate.value,
    host
  );
  const blob = new Blob([response.data], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");

  link.href = url;
  link.download = "visits_statistics.xlsx";

  link.dispatchEvent(
    new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window,
    })
  );

  URL.revokeObjectURL(url);
}

function setSortBy(col) {
  if (sortBy.value == "+" + col) {
    sortBy.value = "-" + col;
  } else {
    sortBy.value = "+" + col;
  }
}
function formatVisitMonth(visitMonth) {
  const date = new Date(visitMonth + "-15"); // choose day in the middle of the month to cater to all time zones

  return date.toLocaleString("default", {
    year: "numeric",
    month: "long",
  });
}

function formatTimeTaken(totalSeconds) {
  if (totalSeconds == null) return "-";
  const hrs = Math.floor(totalSeconds / (60 * 60));
  const mins = Math.floor((totalSeconds % 3600) / 60);
  const seconds = Math.floor((totalSeconds % 3600) % 60);

  return hrs + ":" + ("0" + mins).slice(-2) + ":" + ("0" + seconds).slice(-2);
}

function round(value, precision) {
  const multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}

function loadQueryParameters() {
  const url = new URL(window.location);

  const locId = url.searchParams.get("locId");
  const sort = url.searchParams.get("sort");

  selectedLocationId.value = locId ? locId : "";
  sortBy.value = sort ? sort : "-visit_month";
}

async function loadStatistics() {
  if (startDate.value || endDate.value)
    PeriodheaderText.value = "Selected Dates";
  else PeriodheaderText.value = "Visit Month";

  visitsStatistics.value = await backend.visits.getVisitsStatistics(
    selectedLocationId.value,
    sortBy.value,
    startDate.value,
    endDate.value
  );
}

async function loadLocations() {
  if (allowed("VIEW_ORGANIZATION")) {
    locations.value = await backend.locations.listLocations();
  }
}

onBeforeMount(() => {
  loadLocations();

  loadQueryParameters();

  loadStatistics();
});

watch([selectedLocationId, sortBy], () => {
  loadStatistics();
  router.push({
    name: "Detailed",
    query: { locId: selectedLocationId.value, sort: sortBy.value },
  });
});
</script>

<style scoped>
tr:last-child {
  font-weight: bold;
}
</style>
