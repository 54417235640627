<template>
  <div class="body">
    <h2 class="mb-4">Patients</h2>

    <div class="d-flex mb-4">
      <div>
        <div class="input-group rounded" id="id-search-patients">
          <button class="input-group-text border-0" @click="handleChanges">
            <img src="@/assets/icons/search/search.png" />
          </button>
          <input
            type="search"
            class="form-control"
            placeholder="Search"
            @keyup="searchUpdated"
            @input="(event) => fireSearchIfInputEmpty(event)"
            @search="(event) => fireSearchIfInputEmpty(event)"
            v-model="searchText"
          />
        </div>
        <div v-if="tableAttributes.searchInput !== ''" style="margin-top: 10px">
          <h6 class="mb-0">
            Showing results for "<span class="text-primary">{{
              tableAttributes.searchInput
            }}</span
            >"
          </h6>
        </div>
      </div>
      <div class="ms-auto d-flex">
        <div class="me-2" v-if="showFileUploadButton">
          <label class="btn btn-oivi-light" id="id-button-file-upload">
            File Upload
            <input
              ref="fileInput"
              type="file"
              accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
              hidden
              @change="handleFileUpload"
            />
          </label>
        </div>
        <button
          class="btn btn-oivi-light"
          @click="navigateToAddPatientPage"
          id="id-button-add-patient"
        >
          Add patient
        </button>
      </div>
    </div>

    <table class="table table-striped table-hover" id="id-table-patients">
      <thead>
        <tr>
          <th role="button" @click="sortBy('name')">
            Name <SortIcon col="name" :sorting="tableAttributes.sortBy" />
          </th>
          <th v-if="showIDForPatientList" role="button" @click="sortBy('id')">
            ID <SortIcon col="id" :sorting="tableAttributes.sortBy" />
          </th>
          <th v-if="showMRNForPatientList" role="button" @click="sortBy('mrn')">
            MRN <SortIcon col="id" :sorting="tableAttributes.sortBy" />
          </th>
          <th>Phone</th>
          <th>Email</th>
          <th>Last visit</th>
          <th>Status</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="p in patients"
          :key="p.id"
          @click="router.push('/patients/' + p.id)"
          role="button"
        >
          <td>{{ p.name }}</td>
          <td v-if="showIDForPatientList">{{ p.id }}</td>
          <td v-if="showMRNForPatientList">{{ p.mrn }}</td>
          <td>{{ p.phone }}</td>
          <td>{{ p.email }}</td>
          <td>{{ formatDate(p.last_visit) }}</td>
          <td>{{ p.visit_status }}</td>
          <td class="menu-container">
            <div class="dropdown menu patient-actions-trigger" @click.stop>
              <i
                class="bi bi-three-dots menu-icon"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                @click.stop="toggleMenu"
              ></i>
              <ul class="dropdown-menu patient-actions">
                <li>
                  <button
                    class="dropdown-item"
                    type="button"
                    @click="router.push('/patients/' + p.id)"
                  >
                    View
                  </button>
                </li>
                <li>
                  <button
                    class="dropdown-item"
                    type="button"
                    @click="startPatientVisit(p.id)"
                  >
                    Start Visit
                  </button>
                </li>
              </ul>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <PageSelect
      :totalEntries="totalEntries"
      :limitPerPage="tableAttributes.limitPerPage"
      :offsetValue="tableAttributes.offsetValue"
      @newOffsetValue="newOffsetValue"
      @newLimitPerPage="newLimitPerPage"
    />
  </div>
  <ModalForm
    title="Confirm upload"
    v-show="confirmUploadVisible"
    @close="closeModal"
  >
    <p>Please confirm to upload the file. This action can't be undone.</p>
    <div class="text-center mt-5">
      <button class="btn btn-secondary me-3" @click="closeModal">Cancel</button>
      <button class="btn btn-primary" @click="importPatients">Confirm</button>
    </div>
  </ModalForm>
  <ModalForm
    title="Data uploaded"
    v-show="uploadResponseVisible"
    @close="closeModal"
  >
    <p>
      {{ patientImportResponse }}
    </p>
  </ModalForm>
  <CheckInModal
    v-if="checkinVisible"
    :patient="selectedPatient"
    :clinics="selectedPatientclinics"
    @dataChange="getPatientsData()"
  ></CheckInModal>
</template>

<script setup>
import backend from "@/backend";
import ModalForm from "@/components/ModalForm.vue";
import PageSelect from "@/components/PageSelect.vue";
import CheckInModal from "@/pages/Patient/components/CheckInModal.vue";
import SortIcon from "@/components/SortIcon.vue";
import { AppConfig } from "@/util/AppConfig";
import { formatDate } from "@/util/formatting";
import { computed, onBeforeMount, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

const confirmUploadVisible = ref(false);
const uploadResponseVisible = ref(false);
const patientImportResponse = ref(null);

const checkinVisible = ref(false);
const selectedPatient = ref(null);
const selectedPatientclinics = ref([]);

let file = null;
const router = useRouter();
const patients = ref([]);
const searchText = ref("");
const store = useStore();
const user = store.getters.currentUser;

const totalEntries = ref(0);
const tableAttributes = reactive({
  limitPerPage: null,
  offsetValue: null,
  searchInput: null,
  sortBy: null,
});

const showFileUploadButton = computed(() => AppConfig.allowPatientFileImport);
const showMRNForPatientList = computed(() => AppConfig.showMRNForPatientList);
const showIDForPatientList = computed(() => AppConfig.showIDForPatientList);

function sortBy(col) {
  if (tableAttributes.sortBy == "+" + col) {
    tableAttributes.sortBy = "-" + col;
  } else {
    tableAttributes.sortBy = "+" + col;
  }
  store.commit("SET_PATIENTS_LIST_SORT_BY", tableAttributes.sortBy);
}

async function getPatientsData() {
  const result = await backend.patients.getPatients(
    tableAttributes.limitPerPage,
    tableAttributes.offsetValue,
    tableAttributes.sortBy,
    tableAttributes.searchInput
  );
  store.commit(
    "SET_PATIENTS_LIST_LIMIT",
    parseInt(tableAttributes.limitPerPage)
  );
  store.commit(
    "SET_PATIENTS_LIST_OFFSET",
    parseInt(tableAttributes.offsetValue)
  );
  store.commit("SET_PATIENTS_LIST_SEARCH", tableAttributes.searchInput);
  store.commit("SET_PATIENTS_LIST_SORT_BY", tableAttributes.sortBy);

  patients.value = result.patients;
  totalEntries.value = result.total_count;
}

function searchUpdated(e) {
  if (e) {
    var key = e.keyCode || e.which;
    if (key == 13) {
      // Enter
      handleChanges();
    }
    if (key == 27) {
      // Escape
      searchText.value = "";
      handleChanges();
    }
  }
}

function fireSearchIfInputEmpty(event) {
  if (event.target.value == "") {
    handleChanges();
  }
}

function setTableFilters(imageFilters) {
  for (const [key, value] of Object.entries(imageFilters)) {
    if (key in tableAttributes) {
      tableAttributes[key] = ["limitPerPage", "offsetValue"].includes(key)
        ? parseInt(value)
        : value;
    }
  }
}

watch(tableAttributes, () => {
  const query = {};
  for (const [key, value] of Object.entries(tableAttributes)) {
    if (value) query[key] = value;
  }
  router.push({ name: "Patients", query: query });
  getPatientsData();
});

onBeforeMount(() => {
  const url = new URL(window.location);
  setTableFilters(Object.fromEntries(url.searchParams));
  tableAttributes.limitPerPage = tableAttributes?.limitPerPage ?? getLimit();
  tableAttributes.offsetValue = tableAttributes?.offsetValue ?? getOffset();
  tableAttributes.searchInput = tableAttributes?.searchInput ?? getInput();
  tableAttributes.sortBy = tableAttributes?.sortBy ?? getSort();
  searchText.value = tableAttributes.searchInput;
  getPatientsData();
});

function getLimit() {
  const limit = store.state?.patientsListLimit ?? 20;
  return parseInt(limit);
}

function getOffset() {
  const offset = store.state?.patientsListOffset ?? 0;
  return parseInt(offset);
}

function getInput() {
  const input = store.state?.patientsListSearch ?? "";
  return input;
}

function getSort() {
  let sort = "";
  if (AppConfig.showIDForPatientList) {
    sort = "-id";
  } else if (AppConfig.showMRNForPatientList) {
    sort = "mrn";
  }
  return sort;
}

function newLimitPerPage(limit) {
  store.commit("SET_PATIENTS_LIST_LIMIT", parseInt(limit));
  tableAttributes.limitPerPage = limit;
}
function newOffsetValue(newOffsetVal) {
  store.commit("SET_PATIENTS_LIST_OFFSET", parseInt(newOffsetVal));
  tableAttributes.offsetValue = newOffsetVal;
}
function handleChanges() {
  newOffsetValue(0); // always jump to first page of table when search is executed
  store.commit("SET_PATIENTS_LIST_SEARCH", searchText.value);
  tableAttributes.searchInput = searchText.value;
}

function navigateToAddPatientPage() {
  router.push("patients/add");
}

function handleFileUpload(event) {
  file = event.target.files[0];
  if (file && (file.type === "text/csv" || file.name.endsWith(".csv"))) {
    confirmUploadVisible.value = true;
  } else {
    alert("Please upload a valid CSV file.");
    event.target.value = "";
  }
}

function closeModal() {
  confirmUploadVisible.value = false;
  uploadResponseVisible.value = false;
  file = null;
}

async function importPatients() {
  confirmUploadVisible.value = false;
  try {
    await backend.patients.importPatients(file);
    patientImportResponse.value =
      "Data has been uploaded successfully. It will be available soon";
    uploadResponseVisible.value = true;
  } finally {
    file = null;
  }
}

async function toggleMenu(ev) {
  const menuItems = document.querySelectorAll(".patient-actions.show");
  if (menuItems?.length > 0) {
    const currentActionTarget =
      ev.target.parentNode.querySelector(".patient-actions");
    for (let i = 0; i < menuItems.length; i++) {
      const el = menuItems[i];
      if (el !== currentActionTarget) {
        el.classList.remove("show");
      }
    }
  }
}

async function startPatientVisit(patientId) {
  selectedPatientclinics.value = await backend.locations.listOrgLocations(
    user.organization_id
  );
  const patientData = patients.value.find((x) => x.id === patientId);
  selectedPatient.value = patientData;
  checkinVisible.value = true;
  if (!user.clinic_id && selectedPatientclinics.value.length == 1)
    user.clinic_id = selectedPatientclinics.value[0].id;
}
</script>

<style scoped>
.menu-container {
  padding: 0;
}

.menu {
  height: 56px;
  min-width: 56px;
  width: 100%;
  display: block;
  box-sizing: border-box;
}

.menu-icon {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
