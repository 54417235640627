import axios from "axios";

function useOrganizationsAPI() {
  const endpoint = import.meta.env.VITE_BIRDHOUSE_ENDPOINT;

  async function listOrganizations() {
    return (await axios.get(endpoint + "/organizations")).data.organizations;
  }

  function addOrganization(data) {
    return axios.post(endpoint + "/organizations", JSON.stringify(data), {
      headers: { "Content-Type": "application/json" },
    });
  }

  async function getOrganization(id) {
    return (await axios.get(endpoint + "/organizations/" + id)).data;
  }

  function updateOrganization(id, data) {
    return axios.patch(
      endpoint + "/organizations/" + id,
      JSON.stringify(data),
      {
        headers: { "Content-Type": "application/json" },
      }
    );
  }

  function deleteOrganization(id) {
    return axios.delete(endpoint + "/organizations/" + id);
  }

  return {
    listOrganizations,
    addOrganization,
    getOrganization,
    updateOrganization,
    deleteOrganization,
  };
}

export default useOrganizationsAPI;
