<template>
  <div class="body container-fluid">
    <p>You do not have the right permissions to access this page.</p>
    <p>
      Please contact your administrator if you believe you should have access.
    </p>
    <p>
      You can use the following link to navigate back
      <router-link to="/"> Home</router-link>.
    </p>
  </div>
</template>
