import axios from "axios";

function usePackagesAPI() {
  const endpoint = import.meta.env.VITE_BIRDHOUSE_ENDPOINT;

  async function listPackages() {
    return (await axios.get(endpoint + "/software/packages")).data
      .software_packages;
  }

  async function getPackage(id) {
    return (await axios.get(endpoint + "/software/packages/" + id)).data;
  }

  function deletePackage(id) {
    return axios.delete(endpoint + "/software/packages/" + id);
  }

  async function getUploadUrls(manifest) {
    return (await axios.put(endpoint + "/software/packages", manifest)).data
      .files;
  }

  function storePackage(manifest) {
    return axios.post(endpoint + "/software/packages", manifest);
  }

  return {
    listPackages,
    getPackage,
    deletePackage,
    getUploadUrls,
    storePackage,
  };
}

export default usePackagesAPI;
