<template>
  <svg
    class="search-loop-svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2213_8478)">
      <path
        d="M7.53 1.5C10.86 1.5 13.56 4.21 13.56 7.53C13.56 10.85 10.85 13.56
      7.53 13.56C4.21 13.56 1.5 10.86 1.5 7.53C1.5 4.2 4.21 1.5 7.53 1.5ZM7.53
      0C3.37 0 0 3.37 0 7.53C0 11.69 3.37 15.06 7.53 15.06C11.69 15.06 15.06
      11.69 15.06 7.53C15.06 3.37 11.69 0 7.53 0Z"
      />
      <path
        d="M12.25 13.2905L18.45 19.5405C18.74 19.8305 19.22 19.8405 19.51 19.5405C19.8 19.2505 19.81 18.7705 19.51 18.4805L13.31 12.2305"
      />
    </g>
    <defs>
      <clipPath id="clip0_2213_8478">
        <rect width="19.74" height="19.77" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script setup></script>

<style lang="scss" scoped>
@import "@/style/style.scss";
.search-loop-svg {
  fill: $primary;
}
</style>
