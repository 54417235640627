<template>
  <BreadCrumbs
    v-if="patient.id"
    :current="'Patient ' + patient.id"
    parent="Patients"
    parent-path="/patients"
  />
  <div class="body">
    <PatientForm
      v-if="isEditingPatient && !patientDataIsLoading"
      :patientInfo="patient"
      @finishEditing="finishEditing"
    />
    <div v-if="!isEditingPatient" class="col-md-6 mx-auto">
      <div
        v-if="patientDataIsLoading"
        class="d-flex justify-content-center mt-5"
      >
        <div
          class="oivi-green-spinner spinner-border spinner-border-sm"
          role="status"
        >
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div v-if="!patientDataIsLoading">
        <div class="row">
          <PatientDetails
            :patientInfo="patient"
            @editPatient="editPatient"
            @removeFromOrg="removeFromOrg"
            @removePatient="confirmDeletePatientModal.show()"
            @addToOrg="selectOrgModal.show()"
          />
        </div>
        <div class="row">
          <VisitTable :patientID="patientID" ref="visitTable" />
        </div>
        <div class="row">
          <CheckIn :patient="patient" @dataChange="visitTable.reload()" />
        </div>
      </div>
    </div>
  </div>
  <SelectOrgModal ref="selectOrgModal" @orgSelected="addToOrg">
    <p>Please select organization to add patient to.</p>
    <p>
      Users in this organization will get access to view and edit patient data.
    </p>
  </SelectOrgModal>
  <YesNoModal
    ref="confirmDeletePatientModal"
    title="Delete patient"
    yesLabel="Delete patient"
    yesCustomStyle="btn-danger"
    :onYes="() => removePatient(patient.id)"
    :yesSafetyInput="`delete patient ${patient.id}`"
  >
    <p>Are you sure you want to delete this patient?</p>
    <p>
      All data relating to this patient will be lost. This includes all visit
      data and images.
    </p>
    <p>This action cannot be undone.</p>
  </YesNoModal>
</template>

<script setup>
import { ref, onMounted } from "vue";
import backend from "@/backend";
import PatientDetails from "./components/PatientDetails.vue";
import VisitTable from "./components/VisitTable.vue";
import CheckIn from "./components/CheckIn.vue";
import PatientForm from "../AddPatient/components/PatientForm.vue";
import SelectOrgModal from "@/components/SelectOrgModal.vue";
import { useRouter } from "vue-router";
import YesNoModal from "@/components/YesNoModal.vue";

const props = defineProps(["patientID"]);
const router = useRouter();

const patient = ref({});
const visitTable = ref(null);
const isEditingPatient = ref(false);
const selectOrgModal = ref(null);
const confirmDeletePatientModal = ref(null);
const patientDataIsLoading = ref(false);

function editPatient() {
  isEditingPatient.value = true;
}

async function finishEditing() {
  isEditingPatient.value = false;
  await refreshPatient();
}

async function refreshPatient() {
  patientDataIsLoading.value = true;
  patient.value = await backend.patients.getPatient(props.patientID);
  patientDataIsLoading.value = false;
}

async function removeFromOrg(orgID) {
  if (
    !window.confirm(
      "Are you sure that you want to remove the patient from this organization?"
    )
  )
    return;
  await backend.patients.removePatientFromOrg(props.patientID, orgID);
  await refreshPatient();
}

async function removePatient(id) {
  await backend.patients.removePatient(id);
  router.push("/patients");
}

async function addToOrg(orgID) {
  await backend.patients.addPatientToOrg(props.patientID, orgID);
  await refreshPatient();
}

onMounted(async () => {
  await refreshPatient();
});
</script>
