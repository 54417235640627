import { Modal } from "bootstrap";

function showPopupMessage(message, title = "Error") {
  document.getElementById("popupModalTitle").innerText = title;
  document.getElementById("popupModalBody").innerText = message;
  const modal = new Modal(document.getElementById("popupModal"));
  modal.show();
}

export { showPopupMessage };
