<template>
  <NavBar v-if="currentUser?.signedIn && !hideNavbar" />
  <main :style="`width: ${fullWidth ? '100%' : '80%'}`">
    <RouterView />
  </main>
  <AxiosLoader />
  <PopUpModal />
</template>
<script setup>
import { useStore } from "vuex";
import { computed, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useFavicon } from "@vueuse/core";
import "bootstrap-icons/font/bootstrap-icons.css";
import AxiosLoader from "@/components/AxiosLoader.vue";
import NavBar from "./components/NavBar.vue";
import PopUpModal from "./components/PopUpModal.vue";
import backend from "@/backend";

const SITE_TITLE = "Oivi Cloud";
const hideNavbar = ref(false);
const fullWidth = ref(false);
const store = useStore();
const router = useRouter();
const currentUser = computed(() => store.state.currentUser);

const icon = useFavicon();

icon.value =
  import.meta.env.VITE_DEPLOYMENT !== "production"
    ? "oivi-favicon-dev.png"
    : "oivi-favicon.png";

function canUserAccessRoute(route) {
  if (route.meta?.public) return true;
  const userActions = currentUser.value?.actions;
  if (userActions.includes("ANY_ACTION")) return true;
  if (!route.meta?.privilege) return true;
  const privileges = [].concat(route.meta?.privilege);
  return privileges.every((privilege) => userActions?.includes(privilege));
}

router.beforeEach((to) => {
  if (!to.meta.public && !currentUser.value?.signedIn) {
    store.commit("SET_TARGET_URL", { history: true, path: to.fullPath });
    return { name: "SignIn" };
  }
  if (!canUserAccessRoute(to)) {
    return { name: "NotPermitted", params: { notPermitted: to.name } };
  }
  if (
    currentUser.value?.signedIn &&
    ["SignIn", "SignUp", "SignUpConfirm", "ForgotPassword"].includes(to.name)
  )
    return { name: "Home" };
});

router.afterEach((to) => {
  if (to.meta.title) document.title = to.meta.title + " - " + SITE_TITLE;
  else document.title = SITE_TITLE;
  if (to.meta.hideNavbar) {
    hideNavbar.value = true;
    fullWidth.value = true;
  } else if (to.query["full-screen-view"] === "true") {
    hideNavbar.value = true;
    fullWidth.value = false;
  } else {
    hideNavbar.value = false;
    fullWidth.value = false;
  }
});

onMounted(() => {
  if (currentUser.value?.signedIn) {
    const userId = currentUser.value.userId;
    (async function logUserAction() {
      try {
        const actionType = backend.audit.userActionTypes.PHI_ACCESS;
        await backend.audit.logUserAction(userId, actionType);
      } catch (error) {
        console.error("Error logging user action:", error);
      }
    })();
  }
});
</script>
