<template>
  <Form @submit="submitVisitId" v-slot="{ errors }">
    <div class="visits-lookup-container">
      <div class="visit-id-form-container">
        <NumberInput
          inputName="visitIdInput"
          v-model="visitId"
          :min="0"
          placeholder="Enter Visit ID"
          :validator="validateVisitId"
        />
        <button type="submit" class="btn btn-secondary" id="submit-visit-id">
          <div
            class="spinner-border spinner-border-sm"
            role="status"
            v-if="searchIsLoading"
            :disable="errors.length"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
          <SearchLoop v-else />
        </button>
      </div>
    </div>
  </Form>
</template>

<script setup>
import SearchLoop from "@/assets/icons/search/SearchLoop.vue";
import backend from "@/backend";
import NumberInput from "@/components/Forms/NumberInput.vue";
import router from "@/router";
import { showPopupMessage } from "@/util/popup";
import { Form } from "vee-validate";
import { onMounted, onUnmounted, ref } from "vue";

const visitId = ref("");
const searchIsLoading = ref(false);

async function submitVisitId() {
  searchIsLoading.value = true;
  try {
    const visit = await backend.visits.getVisit(visitId.value, {
      "full-screen-view": true,
    });
    router.push({
      path: `/visits/${visit.id}/`,
      query: { "full-screen-view": "true" },
    });
    searchIsLoading.value = false;
  } catch (_err) {
    searchIsLoading.value = false;
    showPopupMessage(
      "Hmmm, we could not find this visit. Make sure you entered the correct id of your visit and try again!",
      "We could not find this visit"
    );
  }
}

function validateVisitId(visitId) {
  if (!visitId || visitId < 1) return "Enter a valid Visit ID";
  return true;
}

const keyPressListener = (e) => {
  if (e.key === "Enter") {
    submitVisitId();
  }
};
onMounted(() => window.addEventListener("keypress", keyPressListener));
onUnmounted(() => window.removeEventListener("keypress", keyPressListener));
</script>

<style lang="scss" scoped>
@import "@/style/style.scss";

.visits-lookup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  background-color: $primary;

  .visit-id-form-container {
    display: flex;
    justify-content: center;
    align-items: start;
    height: 100px;
    width: 300px;
  }

  button {
    height: 48px;
    min-width: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $oivi-light-green;
    border-color: $oivi-light-green;
  }

  button:hover,
  button:active {
    border: 2px solid;
    border-color: white;
  }
}
</style>
