<template>
  <BreadCrumbs
    :current="'Patient ' + 'add'"
    parent="Patients"
    parent-path="/patients"
  />
  <div class="body">
    <PatientForm @showExistingPatientsModal="showExistingPatientsModal" />
    <ExistingPatientsModal
      id="id-modal-existing-patients"
      v-show="isExistingPatientsModalVisible"
      :patients="existingPatients"
      @close="closeExistingPatientsModal"
      @onPatientSelected="onExistingPatientSelected"
    />
  </div>
</template>

<script setup lan="ts">
import { ref } from "vue";
import ExistingPatientsModal from "../Patients/components/ExistingPatientsModal.vue";
import PatientForm from "./components/PatientForm.vue";
import { useRouter } from "vue-router";

const router = useRouter();

const existingPatients = ref([]);
const isExistingPatientsModalVisible = ref(false);

function showExistingPatientsModal(patients) {
  existingPatients.value = patients;
  isExistingPatientsModalVisible.value = true;
}
function closeExistingPatientsModal() {
  isExistingPatientsModalVisible.value = false;
}
function onExistingPatientSelected(id) {
  isExistingPatientsModalVisible.value = false;
  router.push({ name: "Patient", params: { patientID: id } });
}
</script>
<style></style>
