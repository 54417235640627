<template>
  <div class="body container-fluid">
    <p>You are being signed out.</p>
  </div>
</template>
<script setup>
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import backend from "@/backend";

const router = useRouter();
const store = useStore();

async function signout() {
  await backend.auth.signOut();
  store.commit("SET_CURRENT_USER", undefined);
  router.push({ name: "SignIn" });
}

signout();
</script>
