import axios from "axios";

function useSettingsAPI() {
  const endpoint = import.meta.env.VITE_BIRDHOUSE_ENDPOINT;

  async function listSettings() {
    return (await axios.get(endpoint + "/settings")).data;
  }

  function setSetting(name, value) {
    return axios.put(endpoint + "/settings/" + name, JSON.stringify(value), {
      headers: { "Content-Type": "application/json" },
    });
  }

  return {
    listSettings,
    setSetting,
  };
}

export default useSettingsAPI;
