<template>
  <div class="card border-dark mb-3 rounded-0">
    <div class="row">
      <div class="col-7">
        <img
          class="card-img-left"
          :src="img.thumbnail_url ? img.thumbnail_url : img.image_url"
          @click="$emit('imageClicked', img.id)"
        />
      </div>

      <div class="card-body col-5">
        <p>
          Cataract:
          {{
            formatCataract(img.eye == "r" ? img.cataract_od : img.cataract_os)
          }}
        </p>
        <p>Age: {{ formatAgeShort(img.birthday) }}</p>
        <p v-if="img.diabetes_onset == null">Not diabetic</p>
        <p v-else>
          {{ formatAgeShort(img.diabetes_onset) }} years with diabetes
        </p>
        <p>clinic : {{ img.location_name }}</p>
        <p>{{ img.captured.split(" ")[0] }}</p>
        <br />
        <p>
          <router-link :to="'/visits/' + img.visit_id">
            View visit {{ img.visit_id }}
          </router-link>
        </p>
        <p>
          <a :href="img.data_url" :download="'img_' + img.id + '.zip'">
            Download Image
          </a>
        </p>
        <p v-for="imgVariant in img.variants" :key="imgVariant.variant">
          <a :href="imgVariant.url">
            {{ imgVariant.variant }}
          </a>
        </p>
        <p>
          <a
            v-if="doesImagePupilTimeSeriesExists(img)"
            @click="$emit('showPupilTimeSeries', img.id)"
            href="javascript:void(0);"
          >
            Show Pupil Time Series
          </a>
        </p>
      </div>
    </div>
  </div>
</template>
<script setup>
import { formatAgeShort, formatCataract } from "@/util/formatting";
defineProps(["img"]);

function doesImagePupilTimeSeriesExists(img) {
  return (
    img?.metadata?.alignment?.states?.pre_analysis_alignment
      ?.pupil_time_series != undefined
  );
}
</script>
<style scoped>
.card {
  flex-direction: row;
}
p {
  margin: 0;
  font-size: 70%;
}
img {
  width: 100%;
  height: auto;
}
</style>
