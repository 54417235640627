<template>
  <ModalForm
    title="Existing patients found"
    footerButtonLabel="Cancel"
    footerButtonClass="btn-primary"
    :footerButtonAction="close"
    @close="close"
  >
    <table class="table table-striped">
      <thead>
        <tr class="table-headers">
          <th>Name</th>
          <th>Email</th>
          <th>Phone</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="patient in patients" :key="patient.id">
          <tr class="table-row" @click="onPatientClicked(patient.id)">
            <td>{{ patient.name }}</td>
            <td>{{ patient.email }}</td>
            <td>{{ patient.phone }}</td>
          </tr>
        </template>
      </tbody>
    </table>
  </ModalForm>
</template>

<script setup>
import ModalForm from "@/components/ModalForm.vue";

defineProps(["patients"]);
const emit = defineEmits(["close", "onPatientSelected"]);

function close() {
  emit("close");
}

function onPatientClicked(id) {
  emit("onPatientSelected", id);
}
</script>

<style scoped>
.table-row:hover {
  background: rgba(105, 159, 123, 0.42);
  border: 2px solid #699f7b;
  box-sizing: border-box;
  cursor: pointer;
}
</style>
