<template>
  <div
    v-if="showLoader"
    class="loading-box"
    style="background-color: rgba(96, 96, 96, 0.85)"
  >
    <div class="lds-roller">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>
<script setup>
import axios from "axios";
import { ref } from "vue";

const showLoader = ref(false);
const endpoint = import.meta.env.VITE_BIRDHOUSE_ENDPOINT;

let timerId = 0;
let requestCount = 0;

function shouldIgnore(url) {
  if (url && url.startsWith(endpoint)) return false;
  return true;
}

function requestStarted(url) {
  if (shouldIgnore(url)) return;
  requestCount++;
  if (timerId) return;
  timerId = setTimeout(() => {
    showLoader.value = true;
    timerId = 0;
  }, 500);
}

function requestEnded(url) {
  if (shouldIgnore(url)) return;
  requestCount--;
  if (requestCount > 0) return;
  if (timerId) clearTimeout(timerId);
  timerId = 0;
  showLoader.value = false;
}

axios.interceptors.request.use(
  (config) => {
    requestStarted(config?.url);
    return config;
  },
  (error) => {
    requestEnded(error?.config?.url);
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    requestEnded(response?.config?.url);
    return response;
  },
  (error) => {
    requestEnded(error?.config?.url);
    return Promise.reject(error);
  }
);
</script>
<style scoped>
.loading-box {
  position: fixed;
  top: 0%;
  bottom: 0%;
  width: 100%;
  z-index: 100000;
  text-align: center;
}
.lds-text {
  top: 40%;
  position: relative;
  color: black;
}
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  top: 40%;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: black;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
