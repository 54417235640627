<template>
  <div class="body container-fluid">
    <p>This is the start page. Please use the top menu to navigate.</p>
  </div>
</template>

<script setup>
import { onBeforeMount } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

const router = useRouter();
const store = useStore();

function routeUsers() {
  if (store.state.targetUrl.history) {
    const destPath = store.state.targetUrl.path;
    store.commit("SET_TARGET_URL", {
      history: false,
      path: destPath,
    });
    return router.push(destPath);
  }
  const roles = store.getters.currentUser.roles;
  if (roles.indexOf("sys_admin") !== -1) {
    return router.push("/users");
  } else if (
    roles.indexOf("medical_practitioner") !== -1 ||
    roles.indexOf("operator") !== -1
  ) {
    return router.push("/patients");
  } else if (roles.indexOf("medical_analyst") !== -1) {
    return router.push("/visits");
  } else if (roles.indexOf("sales") !== -1) {
    return router.push("/organizations");
  } else if (roles.indexOf("org_admin") !== -1) {
    return router.push("/users");
  }
}

onBeforeMount(() => {
  routeUsers();
});
</script>
