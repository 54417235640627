function useCanvasHelpers() {
  var icVars;

  function initUseCanvasHelpers(icVarsR) {
    icVars = icVarsR;
  }

  function clearCanvas() {
    icVars.ic.save();
    icVars.ic.setTransform(1, 0, 0, 1, 0, 0);
    icVars.ic.clearRect(0, 0, icVars.ic.canvas.width, icVars.ic.canvas.height);
    icVars.ic.restore();
  }

  function drawTextBG(ctx, txt, fontSize, bg, x, y) {
    ctx.font = `${fontSize}px monospace`;
    ctx.textBaseline = "top";
    ctx.fillStyle = bg;
    var width = ctx.measureText(txt).width;
    ctx.fillRect(x, y, width, fontSize);
    ctx.fillStyle = "#000";
    if (!isColorDark(bg)) ctx.fillStyle = "#fff";
    ctx.fillText(txt, x, y);
    ctx.restore();
  }

  function drawAiAnnotation(ctx, fontSize, bg, box, label) {
    ctx.font = `${fontSize}px monospace`;
    ctx.textBaseline = "top";
    ctx.lineWidth = "15";
    ctx.strokeStyle = bg;
    ctx.fillStyle = bg;
    let imgWidth = icVars.background.width;
    let imgHeight = icVars.background.height;

    let y = 0;
    let startY = Math.floor(box.startY * imgHeight);
    if (startY - 50 < 50) {
      y = startY;
    } else {
      y = startY - 50;
    }
    ctx.beginPath();
    ctx.rect(
      Math.floor(box.startX * imgWidth),
      Math.floor(box.startY * imgHeight),
      Math.floor((box.endX - box.startX) * imgWidth),
      Math.floor((box.endY - box.startY) * imgHeight)
    );
    ctx.stroke();
    var width = ctx.measureText(label).width;
    ctx.fillRect(Math.floor(box.startX * imgWidth), y, width, fontSize);
    ctx.fillStyle = "#000";
    if (!isColorDark(bg)) ctx.fillStyle = "#fff";
    ctx.fillText(label, Math.floor(box.startX * imgWidth), y);
    ctx.restore();
  }

  function isColorDark(color) {
    const hex = color.replace("#", "");
    const c_r = parseInt(hex.substr(0, 2), 16);
    const c_g = parseInt(hex.substr(2, 2), 16);
    const c_b = parseInt(hex.substr(4, 2), 16);
    const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000;
    return brightness > 128;
  }

  function drawAnnotations() {
    const cWidthR =
      (icVars.ic.canvas.clientWidth / 700) * icVars.designedSizeRatio;
    icVars.strokes.forEach((stroke) => {
      icVars.ic.beginPath();
      if (stroke.hovered !== undefined || stroke.selected !== undefined) {
        icVars.ic.lineWidth = 10 * cWidthR;
      } else icVars.ic.lineWidth = 1;
      icVars.ic.strokeStyle = stroke.color;
      var minY = icVars.background.height;
      var centroidX = 0;
      stroke.points.forEach((p, i) => {
        centroidX += p.x;
        if (p.y < minY) minY = p.y;
        if (i == 0) {
          icVars.ic.moveTo(p.x, p.y);
        } else {
          icVars.ic.lineTo(p.x, p.y);
          icVars.ic.stroke();
        }
      });
      centroidX /= stroke.points.length;
      if (stroke.hovered !== undefined) {
        icVars.ic.fillStyle = stroke.color;
        let fontSize = 65 * cWidthR;
        drawTextBG(
          icVars.ic,
          stroke.label,
          fontSize,
          stroke.color,
          centroidX - 18 * cWidthR * stroke.label.length,
          minY - 80 * cWidthR
        );
      }
    });
    icVars.ic.strokeStyle = icVars.currentColor;
  }

  function drawAiAnnotations() {
    const cWidthR =
      (icVars.ic.canvas.clientWidth / 700) * icVars.designedSizeRatio;
    icVars.diagStrokes.forEach((diagStroke) => {
      let fontSize = 35 * cWidthR;
      drawAiAnnotation(
        icVars.ic,
        fontSize,
        diagStroke.color,
        diagStroke.box,
        diagStroke.label
      );
    });
    icVars.ic.strokeStyle = icVars.currentColor;
  }

  function redrawCanvas() {
    clearCanvas();
    icVars.ic.drawImage(icVars.background, 0, 0);
    if (icVars.isShowingAnnotations) drawAnnotations();
    if (icVars.isShowingAIGradingAssistant) drawAiAnnotations();
  }

  return { initUseCanvasHelpers, clearCanvas, redrawCanvas };
}
export default useCanvasHelpers;
