<template>
  <BreadCrumbs
    :current="device.serial"
    parent="Devices"
    parent-path="/devices"
  />
  <div class="body">
    <div class="row">
      <div class="col-sm-3">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{{ device.serial }}</h5>
            <dl>
              <dt>Model</dt>
              <dd>{{ device.hardware_model }}</dd>

              <dt>Revision</dt>
              <dd>{{ device.hardware_revision }}</dd>

              <dt>Software version</dt>
              <dd>{{ device.software_version }}</dd>

              <dt>Location</dt>
              <dd>
                <router-link :to="'/locations/' + device.location_id">
                  {{ device.location_name }}
                </router-link>
              </dd>

              <dt>Organization</dt>
              <dd>
                <router-link :to="'/organizations/' + device.organization_id">
                  {{ device.organization_name }}
                </router-link>
              </dd>

              <dt>Software channel</dt>
              <dd>
                <router-link :to="'/channels/' + device.sw_channel_id">
                  {{ device.sw_channel_name }}
                </router-link>
              </dd>

              <dt>Last ping</dt>
              <dd>{{ formatDate(device.pinged) }}</dd>
            </dl>
            <button
              class="btn btn-oivi-light me-2"
              @click="editDeviceModal.show()"
            >
              Edit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <EditDeviceModal
    ref="editDeviceModal"
    :device="device"
    @dataChange="loadData"
  />
</template>

<script setup>
import { ref, onMounted } from "vue";
import backend from "@/backend";
import { formatDate } from "@/util/formatting";
import EditDeviceModal from "./components/EditDeviceModal.vue";

const props = defineProps(["deviceID"]);
const editDeviceModal = ref(null);
const device = ref({});

async function loadData() {
  device.value = await backend.devices.getDevice(props.deviceID);
}

onMounted(async function () {
  loadData();
});
</script>
d
