<template>
  <div
    id="patient-form-container"
    class="add-patient-page-container mx-auto mb-2"
  >
    <Form v-slot="{ errors }" @submit="handleFormSubmit">
      <div class="fw-bold form-title">
        {{ isNewPatient ? "New Patient" : "Edit Patient" }}
      </div>
      <div class="row mb-sm-3">
        <div class="col-sm-6 mb-3 mb-sm-0">
          <TextInput
            v-model="patientDetails.name"
            input-label="Patient Name *"
            input-name="patientNameInput"
            :validator="validateFullName"
            placeholder="Patient Full Name"
          />
        </div>
        <div class="col-sm-6 mb-3 mb-sm-0">
          <TextInput
            v-model="patientDetails.medicalRecordNumber"
            input-label="Medical Record Number"
            input-name="patienMedicalRecordInput"
            :validator="() => true"
            placeholder="Medical record (optional)"
            tooltip-content="Cross reference to other patient journal systems"
          />
        </div>
      </div>
      <div class="row mb-sm-3">
        <div class="col-sm-6 mb-3 mb-sm-0">
          <TextInput
            v-model="patientDetails.email"
            input-label="Email"
            input-name="patientEmailInput"
            :validator="validateEmailInput"
            placeholder="Patient Email"
            @handle-on-blur-action="checkIfPatientEmailExists"
          />
        </div>
        <div class="col-sm-6 mb-3 mb-sm-0">
          <PhoneInput
            v-model="patientDetails.phoneNumber"
            input-label="Phone number"
            input-name="patientPhoneInput"
            @handle-on-blur-action="checkIfPatientPhoneExists"
          />
        </div>
      </div>
      <div class="row justify-content-start mb-4">
        <div class="col-md-3 col-sm-6 pe-0 px-3 mb-3 mb-sm-0">
          <span>
            <NumberInput
              v-model="patientDetails.age"
              input-label="Age *"
              input-name="patientAgeInput"
              suffix="Years"
              :validator="validateAgeInput"
              placeholder="0"
              :min="0"
              max-width-pixels="60"
            />
          </span>
        </div>
        <div class="col-md-9 col-6 d-sm-flex justify-content-md-end">
          <RadioInput
            v-model="patientDetails.gender"
            input-label="Gender *"
            input-name="patientGenderInput"
            :validator="validateGenderInput"
            :options="[
              { label: 'Female', value: 'F' },
              { label: 'Male', value: 'M' },
            ]"
            :has-other-option="true"
            other-option-placeholder="Please specify"
          />
        </div>
      </div>
      <div class="fw-bold form-sub-title">Medical History</div>
      <div class="row">
        <div class="col-sm-8">
          <div
            v-for="disease in medicalConditions"
            :key="disease.value"
            class="d-flex flex-column disease-container"
          >
            <div class="fw-medium-bold mb-2">{{ disease.label + " *" }}</div>
            <div class="d-flex flex-row">
              <div class="me-3">
                <label
                  :for="disease.value + 'CheckBox'"
                  class="form-check-label btn btn-light d-flex align-items-center check-box-label flex-nowrap px-2"
                  :class="{
                    'is-invalid': `${disease.value}NoHistoryCheck` in errors,
                  }"
                >
                  <Field
                    v-slot="{ field }"
                    :name="`${disease.value}NoHistoryCheck`"
                    :rules="`validateDiseaseHistory:@${disease.value}Months,@${disease.value}Years`"
                  >
                    <input
                      :id="disease.value + 'CheckBox'"
                      v-model="patientDetails[`${disease.value}`].noHistory"
                      :name="`${disease.value}NoHistoryCheck`"
                      v-bind="field"
                      type="checkbox"
                      class="form-check-input check-box-square me-2 mt-0"
                      :class="{
                        'is-invalid':
                          `${disease.value}NoHistoryCheck` in errors,
                      }"
                    />
                  </Field>
                  N/A</label
                >
              </div>
              <div class="d-flex flex-wrap flex-md-row">
                <div class="mx-2 mb-md-0 mb-2">
                  <NumberInput
                    v-model="patientDetails[`${disease.value}`].period.years"
                    :input-name="disease.value + 'Years'"
                    :validator="`validateYearsWithCrossCheck:@${disease.value}Months,@${disease.value}NoHistoryCheck`"
                    placeholder=" - "
                    :min="0"
                    suffix="Years"
                    max-width-pixels="60"
                    :disabled="patientDetails[`${disease.value}`].noHistory"
                  />
                </div>
                <div class="mx-2">
                  <NumberInput
                    v-model="patientDetails[`${disease.value}`].period.months"
                    :input-name="disease.value + 'Months'"
                    :validator="`validateMonthsWithCrossCheck:@${disease.value}Years,@${disease.value}NoHistoryCheck`"
                    placeholder=" - "
                    :min="0"
                    suffix="Months"
                    max-width-pixels="60"
                    :disabled="patientDetails[`${disease.value}`].noHistory"
                  />
                </div>
              </div>
            </div>
            <ErrorMessage
              :id="`${disease.value}NoHistoryCheck` + 'ErrorMessage'"
              class="error-message"
              :name="`${disease.value}NoHistoryCheck`"
            />
          </div>
        </div>
        <div class="col-sm-4 ps-sm-4 ps-2 mt-sm-0 mt-3 cataract-container">
          <CheckBoxInput
            v-model="patientDetails.cataract"
            group-label="Cataract"
            check-name="cataract"
            :options="cataractOptions"
          />
        </div>
      </div>
      <div class="mb-4 mt-3">
        <div class="fw-medium-bold mb-2">Additional information</div>
        <TextAreaInput
          v-model="patientDetails.additionalInput"
          text-area-name="additionalInput"
          placeholder="Ex: Mention of any other diagnosis"
          rows="3"
        />
      </div>
      <div class="d-grid">
        <button
          id="submit-patient-form-button"
          type="submit"
          class="btn btn-oivi-light"
        >
          {{ isNewPatient ? "Add Patient" : "Save" }}
        </button>
      </div>
    </Form>
  </div>
</template>

<script setup>
import backend from "@/backend";
import { ErrorMessage, Field, Form, defineRule } from "vee-validate";
import { reactive, ref } from "vue";

import { validateAge, validateEmail, validateName } from "@/util/validation";

import NumberInput from "../../../components/Forms/NumberInput.vue";
import PhoneInput from "../../../components/Forms/PhoneInput.vue";
import RadioInput from "../../../components/Forms/RadioInput.vue";
import TextAreaInput from "../../../components/Forms/TextAreaInput.vue";
import TextInput from "../../../components/Forms/TextInput.vue";
import {
  formatDateFromYearsMonthsPassed,
  yearsFromDate,
  yearsMonthsFromDate,
} from "../../../util/formatting";

import CheckBoxInput from "@/components/Forms/CheckBoxInput.vue";
import { useRouter } from "vue-router";

const props = defineProps(["patientInfo"]);
const emit = defineEmits(["finishEditing", "showExistingPatientsModal"]);

const router = useRouter();

const isNewPatient = props.patientInfo === undefined;

const newPatientDetails = {
  name: "",
  medicalRecordNumber: "",
  phoneNumber: "",
  email: "",
  age: null,
  gender: null,
  diabetes: {
    noHistory: null,
    period: {
      years: null,
      months: null,
    },
  },
  hyperTension: {
    noHistory: null,
    period: {
      years: null,
      months: null,
    },
  },
  cataract: [],
  additionalInput: "",
};

const patientDetails = isNewPatient
  ? reactive({ ...newPatientDetails })
  : reactive(convertToFrontendFormat({ ...props.patientInfo }));

const medicalConditions = [
  {
    label: "Diabetes",
    value: "diabetes",
  },
  {
    label: "Hypertension",
    value: "hyperTension",
  },
];

const cataractOptions = [
  {
    label: "Right Eye (OD)",
    value: "od",
  },
  {
    label: "Left Eye (OS)",
    value: "os",
  },
];

function convertToFrontendFormat(data) {
  const convertedData = {
    name: data.name,
    medicalRecordNumber: data.mrn,
    phoneNumber: data.phone.replace(" ", ""),
    email: data.email,
    age: yearsFromDate(data.birthday),
    gender: data.gender,
    diabetes: yearsMonthsFromDate(data.diabetes_onset),
    hyperTension: yearsMonthsFromDate(data.hypertension_onset),
    cataract: ["os", "od"].filter((key) => data[`cataract_${key}`]),
    additionalInput: data.notes,
  };
  return convertedData;
}
function convertToBackendFormat(reactiveFormData) {
  const data = Object.assign({}, reactiveFormData);
  const convertedData = {
    name: data.name,
    mrn: data.medicalRecordNumber,
    email: data.email,
    phone: data.phoneNumber,
    birthday: formatDateFromYearsMonthsPassed(data.age),
    gender: data.gender,
    diabetes_onset: formatDiseaseOnsetToDate(
      data.diabetes.noHistory,
      data.diabetes.period.years,
      data.diabetes.period.months
    ),
    hypertension_onset: formatDiseaseOnsetToDate(
      data.hyperTension.noHistory,
      data.hyperTension.period.years,
      data.hyperTension.period.months
    ),
    cataract_od: data.cataract.includes("od") ? true : null,
    cataract_os: data.cataract.includes("os") ? true : null,
    notes: data.additionalInput,
  };
  return convertedData;
}

const validateFullName = () => validateName(patientDetails.name);

function validateEmailInput() {
  if (patientDetails.email.length && !validateEmail(patientDetails.email))
    return "Enter a correct e-mail or leave field blank";
  return true;
}

function validateAgeInput(ageInput) {
  if (!ageInput) return "Enter age";
  if (!validateAge(ageInput)) {
    if (0 < ageInput && ageInput < 16) return "Age should be 16 or above";
    return "Enter a valid age";
  }
  return true;
}

function validateGenderInput(input) {
  if (input === null) return "Choose gender";
  return true;
}

function diseaseHasNoHistory(field) {
  if (
    (field.includes("diabetes") && patientDetails.diabetes.noHistory) ||
    (field.includes("hyperTension") && patientDetails.hyperTension.noHistory)
  )
    return true;
  return false;
}

defineRule(
  "validateDiseaseHistory",
  (_val, [targetMonths, targetYears], context) => {
    if (diseaseHasNoHistory(context.field)) return true;
    if (
      [targetMonths, targetYears].every(
        (item) => !item || parseFloat(item) === 0
      )
    ) {
      if (parseFloat(targetMonths) === 0 || parseFloat(targetYears) === 0)
        return "Period should be at least 1 month";
      return "Enter period or select N/A";
    }
    return true;
  }
);

defineRule("validateMonthsWithCrossCheck", (value, [target], context) => {
  if (diseaseHasNoHistory(context.field)) return true;
  if (value < 0 || value > 11) return "Enter number between 1 and 11";
  if (parseFloat(value) && parseFloat(value) % 1 !== 0)
    return "Enter whole number";
  if (!parseFloat(value) && !parseFloat(target)) {
    return " "; // error message is empty because error message is shown in validateDiseaseHistory
  }
  return true;
});

defineRule("validateYearsWithCrossCheck", (value, [target], context) => {
  if (diseaseHasNoHistory(context.field)) return true;
  if (validateAge(patientDetails.age) && parseInt(value) > patientDetails.age)
    return "Cannot be greater than patient's age";
  if (value < 0) return "Enter a positive number";
  if (parseFloat(value) && parseFloat(value) % 1 !== 0)
    return "Enter whole number";
  if (!parseFloat(value) && !parseFloat(target)) {
    return " "; // error message is empty because error message is shown in validateDiseaseHistory
  }
  return true;
});

const skipCheckPhoneExists = ref(false);
const skipCheckEmailExists = ref(false);

function triggerExistingPatientsModal(existingPatients) {
  if (existingPatients.patients.length > 0)
    emit("showExistingPatientsModal", existingPatients.patients);
}

async function checkIfPatientPhoneExists() {
  if (skipCheckPhoneExists.value) return;
  if (patientDetails.phoneNumber?.length < 4) return;
  const patientsWithIdenticalPhone = await backend.patients.getPatients(
    20,
    0,
    "",
    patientDetails.phoneNumber
  );
  triggerExistingPatientsModal(patientsWithIdenticalPhone);
  skipCheckPhoneExists.value = !skipCheckPhoneExists.value;
}

async function checkIfPatientEmailExists() {
  if (skipCheckEmailExists.value) return;
  if (typeof validateEmailInput(patientDetails.email) === "string") return;
  const patiensWithIdenticalEmail = await backend.patients.getPatients(
    20,
    0,
    "",
    patientDetails.email
  );
  triggerExistingPatientsModal(patiensWithIdenticalEmail);
  skipCheckEmailExists.value = !skipCheckEmailExists.value;
}

function formatDiseaseOnsetToDate(noHistory, years, months) {
  if (noHistory) return null;
  const turnEmptyToZero = (input) =>
    input === "" || input === null ? 0 : input;
  return formatDateFromYearsMonthsPassed(
    turnEmptyToZero(years),
    turnEmptyToZero(months)
  );
}

function addPatient(p) {
  return backend.patients.createPatient(
    p.name,
    p.mrn,
    p.email,
    p.phone,
    p.birthday,
    p.gender,
    p.diabetes_onset,
    p.hypertension_onset,
    p.cataract_od,
    p.cataract_os,
    p.notes
  );
}

async function updatePatient(patientId, p) {
  return backend.patients.updatePatient(
    patientId,
    p.name,
    p.mrn,
    p.email,
    p.phone,
    p.birthday,
    p.gender,
    p.diabetes_onset,
    p.hypertension_onset,
    p.cataract_od,
    p.cataract_os,
    p.notes
  );
}

async function handleFormSubmit() {
  if (isNewPatient) {
    const patientId = await addPatient(convertToBackendFormat(patientDetails));
    router.push({
      path: `/patients/${patientId}`,
      query: { newlyAdded: true },
    });
    return;
  }
  await updatePatient(
    props.patientInfo.id,
    convertToBackendFormat(patientDetails)
  );
  emit("finishEditing");
}
</script>
<style lang="scss" scoped>
@import "@/style/style.scss";

.error-message {
  color: $danger;
}
.add-patient-page-container {
  max-width: 640px;
}

.form-title {
  font-size: 24px;
  line-height: 1;
  margin-bottom: 24px;
}

.form-sub-title {
  font-size: 20px;
  line-height: 1;
  margin-top: 40px;
  margin-bottom: 16px;
}

.custom-title {
  font-size: 2rem;
  line-height: 0.6rem;
}

.disease-container:not(:last-child) {
  margin-bottom: 18px;
}

.cataract-container {
  @media only screen and (min-width: 576px) {
    border-left: 1px solid rgba($primary, 0.2);
  }
}
</style>
