<template>
  <select-label-popup
    v-show="isSelectLabelVisible"
    @labelSelected="labelSelected"
    @close="closeLabelSelector"
  />

  <div class="row">
    <div class="col-6">
      <ul v-if="imageData?.display_order" class="nav nav-tabs">
        <li
          v-for="[name, algo] in imageData.display_order"
          :key="algo"
          class="nav-item"
        >
          <span
            v-if="algo == icVars.vars.variant"
            class="nav-link active"
            href="#"
          >
            {{ name }}
          </span>
          <a v-else class="nav-link" href="#" @click="selectVariant(algo)">
            {{ name }}
          </a>
        </li>
      </ul>
    </div>
    <div class="col-6 text-end pt-2">
      <strong>{{ imageDetailsStr }}</strong>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div
        ref="canvasContainer"
        style="
          width: auto;
          position: relative;
          display: flex;
          justify-content: center;
        "
      >
        <div class="image-placeholder" :style="extra_styles">
          <p>{{ placeholderMessage }}</p>
        </div>
        <div class="enlarge-button" @click="toggleFullscreen">
          <i class="bi bi-fullscreen"></i>
        </div>
        <canvas
          ref="imageCanvas"
          width="700"
          height="560"
          class="image-canvas"
          :class="{ 'full-screen': isFullScreen }"
          :style="extra_styles"
          @[!view_only&&`mousedown`]="onMouseDown"
          @[view_only&&`mousedown`]="startPan"
          @[!view_only&&`mouseup`]="onMouseUp"
          @[view_only&&`mouseup`]="stopPan"
          @mousemove="onMouseMove"
          @[!view_only&&`keypress`]="onKeyPress"
          @mousewheel="onMouseWheel"
          @wheel="onMouseWheel"
          v-on:keydown.esc="(e) => exitFullscreenWithEscape(e)"
        ></canvas>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="form-check form-switch text-start d-inline-block me-2">
        <input
          class="form-check-input"
          type="checkbox"
          id="flexSwitchCheckChecked"
          v-model="icVars.vars.isShowingAnnotations"
          checked
        />
        <label class="form-check-label" for="flexSwitchCheckChecked"
          >Show Annotations</label
        >
      </div>
      <div
        class="form-check form-switch text-start d-inline-block me-2"
        v-if="enableAIDiag"
      >
        <input
          class="form-check-input"
          type="checkbox"
          id="flexSwitchCheckChecked"
          v-model="icVars.vars.isShowingAIGradingAssistant"
          @change="refreshAIAnnotations"
        />
        <label class="form-check-label" for="flexSwitchCheckChecked"
          >Enable AI grading assistant*</label
        >
      </div>
      <div class="float-end" v-if="icVars.vars.isShowingAIGradingAssistant">
        <strong :style="{ color: imageDrDiagStr.color }">{{
          imageDrDiagStr.text
        }}</strong>
      </div>
      <button
        v-show="!view_only"
        :class="{
          'cmd-active': icVars.vars.isPanToolActive,
          'btn-cmd cmd-pan': true,
        }"
        @click="
          icVars.vars.isPanToolActive = true;
          icVars.vars.isEditToolActive = false;
        "
      />
      <button
        v-show="!view_only"
        :class="{
          'cmd-active': icVars.vars.isEditToolActive,
          'btn-cmd cmd-edit': true,
        }"
        @click="
          icVars.vars.isPanToolActive = false;
          icVars.vars.isEditToolActive = true;
        "
      />
      <button
        v-show="!view_only"
        class="btn-cmd cmd-undo"
        @click="undo"
        :disabled="!icVars.vars.isEditToolActive"
      />
      <button
        v-show="!view_only"
        class="btn-cmd cmd-redo"
        @click="redo"
        :disabled="!icVars.vars.isEditToolActive"
      />
      <button
        v-show="!view_only"
        class="btn-cmd cmd-delete"
        @click="deleteSelected"
        :disabled="!icVars.vars.isEditToolActive"
      />
    </div>
    <div class="row pt-3" v-if="enableAIDiag">
      <label></label>
      <label> *AI grading assistant cannot replace a doctor examination.</label>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, watch, nextTick, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import backend from "@/backend";
import useHoverAndSelected from "../composables/useHoverAndSelected";
import useZoom from "../composables/useZoom";
import useCommandHistory from "../composables/useCommandHistory";
import useCanvasHelpers from "../composables/useCanvasHelpers";
import useDrawing from "../composables/useDrawing";
import useLabelColors from "../composables/useLabelColors";
import SelectLabelPopup from "./SelectLabelPopup.vue";
import fscreen from "fscreen";

defineProps(["view_only", "extra_styles"]);

const canvasContainer = ref(null);
const isFullScreen = ref(false);
const imageCanvas = ref(null);
const imageData = ref(null);
const icVars = reactive({
  vars: {
    ic: undefined,
    drawing: false,
    background: new Image(),
    strokes: [],
    diagStrokes: [],
    currentStroke: [],
    currentHoveredIndex: -1,
    currentSelectedIndex: -1,
    currentLabel: "",
    currentColor: "",
    commandHistory: [],
    currentCommandIndex: 0,
    minScale: 0.2,
    maxScale: 2.0,
    currentScale: 1.0,
    currentOffsetX: 0,
    currentOffsetY: 0,
    backgroundLoaded: false,
    hoveredOverLabel: "",
    currentImageID: -1,
    downloadedStrokes: [],
    isShowingAnnotations: true,
    isShowingAIGradingAssistant: false,
    currentMousePanX: 0,
    currentMousePanY: 0,
    isPanning: false,
    isPanToolActive: true,
    isEditToolActive: false,
    designedSizeRatio: 1,
    aspectRatio: 0,
    variant: "",
  },
});
const icFuncs = reactive({
  funcs: {
    getHoveredOverIndex: undefined,
    deleteSelectedHandler: undefined,
    clearCanvas: undefined,
    redrawCanvas: undefined,
    setScale: undefined,
    addStroke: undefined,
    removeStroke: undefined,
    rebuildStrokes: undefined,
    undoHandler: undefined,
    redoHandler: undefined,
    getScaledXY: undefined,
    startDrawingHandler: undefined,
    stopDrawingHandler: undefined,
    drawingHandler: undefined,
    getLabelColor: undefined,
    getAllLabelColors: undefined,
  },
});
const placeholderMessage = ref("");
const enableAIDiag = ref(false);

const { getLabelColor, getAllLabelColors } = useLabelColors();
icFuncs.funcs.getLabelColor = getLabelColor;
icFuncs.funcs.getAllLabelColors = getAllLabelColors;

const { initUseHoverAndSelected, getHoveredOverIndex, deleteSelectedHandler } =
  useHoverAndSelected();
icFuncs.funcs.getHoveredOverIndex = getHoveredOverIndex;
icFuncs.funcs.deleteSelectedHandler = deleteSelectedHandler;
initUseHoverAndSelected(icVars.vars, icFuncs.funcs);

const { initUseCanvasHelpers, clearCanvas, redrawCanvas } = useCanvasHelpers();
icFuncs.funcs.clearCanvas = clearCanvas;
icFuncs.funcs.redrawCanvas = redrawCanvas;
initUseCanvasHelpers(icVars.vars);

const {
  initUseCommandHistory,
  addStroke,
  removeStroke,
  rebuildStrokes,
  undoHandler,
  redoHandler,
} = useCommandHistory();
icFuncs.funcs.addStroke = addStroke;
icFuncs.funcs.removeStroke = removeStroke;
icFuncs.funcs.rebuildStrokes = rebuildStrokes;
icFuncs.funcs.undoHandler = undoHandler;
icFuncs.funcs.redoHandler = redoHandler;
initUseCommandHistory(icVars.vars, icFuncs.funcs);

const { initUseZoom, mouseWheelHandler, setScale, startPan, stopPan, movePan } =
  useZoom();
initUseZoom(icVars.vars, icFuncs.funcs);

const {
  getScaledXY,
  initUseDrawing,
  startDrawingHandler,
  stopDrawingHandler,
  drawingHandler,
} = useDrawing();
icFuncs.funcs.getScaledXY = getScaledXY;
icFuncs.funcs.startDrawingHandler = startDrawingHandler;
icFuncs.funcs.stopDrawingHandler = stopDrawingHandler;
icFuncs.funcs.drawingHandler = drawingHandler;
initUseDrawing(icVars.vars, icFuncs.funcs);

const imageDetailsStr = ref("");
const imageDrDiagStr = ref({ text: "", color: "black" });
const isSelectLabelVisible = ref(false);
const loadingStarted = ref(0);
const PROCESSING_MESSAGE = "Processing, please wait...";
const LOADING_MESSAGE = "Loading, please wait...";
const RELOAD_INTERVAL = 5000;
const MAX_PROCESSING_TIME = 900000;
let loadingMessageTimer = null;
let mounted = false;
let imageLoadFailed = false;

async function waitForImageToLoad() {
  while (!icVars.vars.backgroundLoaded)
    await new Promise((resolve) => setTimeout(resolve, 10));
}

icVars.vars.background.onload = function () {
  imageLoadFailed = false;
  icVars.vars.backgroundLoaded = true;
  placeholderMessage.value = "";
  if (loadingMessageTimer) clearTimeout(loadingMessageTimer);
  loadingMessageTimer = null;
};

icVars.vars.background.onerror = async function () {
  if (imageLoadFailed) return; // Prevent eternal loop
  imageLoadFailed = true;
  reloadImageData();
};

// making use of fscreen because vendor differences in full screen API
// see https://developer.mozilla.org/en-US/docs/Web/API/Fullscreen_API/Guide#prefixing
function handleFullScreenChange() {
  if (fscreen.fullscreenElement) isFullScreen.value = true;
  else isFullScreen.value = false;
}

onMounted(async () => {
  const store = useStore();
  const user = store.getters.currentUser;
  enableAIDiag.value = user && user.actions.includes("EXPERIMENTAL");
  icVars.vars.ic = imageCanvas.value.getContext("2d");
  mounted = true;
  imageLoadFailed = false;
  fscreen.addEventListener("fullscreenchange", handleFullScreenChange);
});

onUnmounted(async () => {
  mounted = false;
  fscreen.removeEventListener("fullscreenchange", handleFullScreenChange);
});

function getAspectRatio(img) {
  return img.width / img.height;
}

function setLoadingMessage() {
  loadingMessageTimer = null;
  placeholderMessage.value = LOADING_MESSAGE;
}

async function loadImageFromUrl(url) {
  loadingMessageTimer = setTimeout(setLoadingMessage, 200);
  icVars.vars.backgroundLoaded = false;
  icVars.vars.background.src = url;
  await waitForImageToLoad();
  icVars.vars.aspectRatio = getAspectRatio(icVars.vars.background);
  await nextTick();
  icVars.vars.minScale =
    icVars.vars.ic.canvas.width / icVars.vars.background.width;
  icVars.vars.designedSizeRatio = icVars.vars.background.width / 3500;
  icVars.vars.maxScale = 30 * icVars.vars.minScale;
}

async function showInitialImage() {
  if (imageData.value.display_order.length > 0) {
    if (!icVars.vars.variant)
      icVars.vars.variant = imageData.value.display_order[0][1];
    if (icVars.vars.variant in imageData.value.variants)
      await loadImageFromUrl(imageData.value.variants[icVars.vars.variant].url);
  } else {
    await loadImageFromUrl(imageData.value.original);
  }
  //loadAiDiagClassification(JSON.parse(imageData.value.ai_diag), imageData);
  loadAnnotations(imageData.value.id);
}

async function reloadImageData() {
  if (!mounted) return;
  const imageID = imageData.value.id;
  var result;
  try {
    result = await backend.images.getImage(imageID);
  } catch (err) {
    placeholderMessage.value = "Failed to load image: " + err;
    return;
  }
  imageData.value = result;
  imageData.value.id = imageID;
  if (!imageData.value.processing) {
    showInitialImage();
    return;
  }

  const elapsedMs = new Date() - loadingStarted.value;

  placeholderMessage.value = PROCESSING_MESSAGE;
  const reloadCount = Math.round(elapsedMs / RELOAD_INTERVAL);
  placeholderMessage.value += ".".repeat(reloadCount);

  if (elapsedMs < MAX_PROCESSING_TIME)
    setTimeout(reloadImageData, RELOAD_INTERVAL);
}

async function loadAnnotations(imageID) {
  const annotations = await backend.images.getImageAnnotations(imageID);
  annotations.forEach((ann) => {
    let stroke = {
      id: ann.id,
      label: ann.label,
      points: JSON.parse(ann.stroke),
      color: getLabelColor(ann.label),
    };
    icVars.vars.strokes.push(stroke);
    icVars.vars.downloadedStrokes.push(stroke);
  });

  const image = await backend.images.getImage(imageID);
  const aiDiag = JSON.parse(image.ai_diag);
  aiDiag.dr_annotation.forEach((ann) => {
    let diagStroke = {
      label: ann.label,
      confidence: ann.confidence,
      box: ann.box,
      color: getLabelColor(ann.label),
    };
    icVars.vars.diagStrokes.push(diagStroke);
  });

  setScale(0, 0, icVars.vars.minScale);
  redrawCanvas();
}

async function selectVariant(variant) {
  if (!(variant in imageData.value.variants)) return;
  icVars.vars.variant = variant;
  clearCanvas();
  const url = imageData.value.variants[variant].url;
  await loadImageFromUrl(url);
  redrawCanvas();
}

async function loadImage(imageID) {
  loadingStarted.value = new Date();
  clearAll();
  const imgData = await backend.images.getImage(imageID);
  imageData.value = imgData;
  imageData.value.id = imageID;
  icVars.vars.isShowingAnnotations = true;
  icVars.vars.isShowingAIGradingAssistant = false;
  icVars.vars.currentImageID = imageID;
  imageDetailsStr.value = createImageDetailsStr(imgData);
  loadAiDiagClassification(JSON.parse(imgData.ai_diag), imgData);
  if (imgData.processing) {
    placeholderMessage.value = PROCESSING_MESSAGE;
    setTimeout(reloadImageData, RELOAD_INTERVAL);
  } else {
    showInitialImage();
  }
}

function loadAiDiagClassification(diagData, imgData) {
  imageDrDiagStr.value = createAiDiagStr(diagData, imgData);
}

function createAiDiagStr(diagData, imgData) {
  let grade = "Not Available";
  let version = "";
  let imageQ = null;
  let color = "black"; // Default color
  let hashMap = {
    nomtmdr: {
      text: "No mtmDR",
      color: "green",
    },
    mtmdr: {
      text: "mtmDR",
      color: "red",
    },
  };
  imageQ = getImageQ(imgData);
  if (diagData) {
    version = diagData.version;
    if (version == "v2") {
      grade = diagData.dr_classification.ensemble_weighted_pred;
    } else if (version == "v3") {
      grade = diagData.dr_classification.ensemble_weighted_pred.class_name;
      if (grade in hashMap) {
        color = hashMap[grade].color;
        grade = hashMap[grade].text;
      }
    }
  }
  if (imageQ === "Non-Gradable") {
    grade = imageQ;
    color = "black";
  }
  // return "AI assisted DR Grade: " + grade;
  return {
    text: "AI assisted DR Grade: " + grade,
    color: color,
  };
}

function createImageDetailsStr(image) {
  let eye = "";
  let gradability = "";
  let gaze = "";
  if (image.eye === "l") eye = "left";
  else if (image.eye === "r") eye = "right";

  gaze = image.gaze_target;

  if (
    (eye === "left" && gaze === "right") ||
    (eye === "right" && gaze === "left")
  )
    gaze = "optic-disc centered";
  if (gaze === "center") gaze = "macula centered";

  let istr = "Image #" + image.id + ": " + eye + " eye, " + gaze + " gaze";

  if (image.pupil_size) istr += ", " + image.pupil_size.toFixed(1) + "mm";

  if (image.metadata) {
    gradability = JSON.parse(image.metadata).gradability;
    if (gradability) istr += ", " + gradability.prediction;
  }
  return istr;
}
function getImageQ(image) {
  let imageQ = null;
  let gradability = "";
  if (image.metadata) {
    gradability = JSON.parse(image.metadata).gradability;
    if (gradability) imageQ = gradability.prediction;
  }
  return imageQ;
}

function onMouseDown(e) {
  if (icVars.vars.isEditToolActive) {
    startDrawingHandler(e);
  } else {
    startPan(e);
  }
}

async function onMouseUp(e) {
  if (icVars.vars.isEditToolActive) {
    let isStrokeToBeAdded = await stopDrawingHandler(e);
    if (isStrokeToBeAdded) {
      isSelectLabelVisible.value = true;
    }
  } else {
    stopPan(e);
  }
}

async function labelSelected(label) {
  icVars.vars.currentLabel = label;
  await addStroke();
  isSelectLabelVisible.value = false;
}

function closeLabelSelector() {
  icVars.vars.drawing = false;
  icVars.vars.currentStroke = [];
  isSelectLabelVisible.value = false;
  redrawCanvas();
}

function onMouseMove(e) {
  drawingHandler(e);
  movePan(e);
}

function clearAll() {
  setScale(0, 0, icVars.vars.minScale);
  clearCanvas();
  icVars.vars.drawing = false;
  icVars.vars.strokes = [];
  icVars.vars.diagStrokes = [];
  icVars.vars.currentStroke = [];
  icVars.vars.currentHoveredIndex = -1;
  icVars.vars.currentSelectedIndex = -1;
  icVars.vars.currentLabel = "";
  icVars.vars.currentColor = "";
  icVars.vars.commandHistory = [];
  icVars.vars.currentCommandIndex = 0;
  icVars.vars.currentScale = icVars.vars.minScale;
  icVars.vars.currentOffsetX = 0;
  icVars.vars.currentOffsetY = 0;
  icVars.vars.backgroundLoaded = false;
  icVars.vars.hoveredOverLabel = "";
  icVars.vars.currentImageID = -1;
  icVars.vars.downloadedStrokes = [];
  icVars.vars.variant = "";
  placeholderMessage.value = "";
}

async function deleteSelected() {
  await deleteSelectedHandler();
}

function onKeyPress(e) {
  if (e.key === "Delete") {
    deleteSelectedHandler();
  }
}

function onMouseWheel(e) {
  mouseWheelHandler(e);
}

async function undo() {
  await undoHandler();
}

async function redo() {
  await redoHandler();
}

function refreshAIAnnotations() {
  redrawCanvas();
}

watch(
  () => icVars.vars.isShowingAnnotations,
  () => {
    redrawCanvas();
  }
);

defineExpose({ loadImage });

function toggleFullscreen() {
  if (!fscreen.fullscreenElement) {
    fscreen.requestFullscreen(canvasContainer.value);
  } else {
    fscreen.exitFullscreen();
  }
}
</script>
<style lang="scss" scoped>
@import "@/style/style.scss";
.btn-cmd {
  border: solid #d8d8d8 1px;
  width: 34px;
  height: 34px;
  background-position: center;
  background-repeat: no-repeat;
  background-color: white;
  border-radius: 4px;
  margin-right: 1rem;
}
.btn-cmd:hover:enabled {
  cursor: pointer;
  border-width: 2px;
  border-color: #699f7b;
}

.btn-cmd:disabled {
  background-color: darkgrey;
}

.cmd-active {
  border-width: 2px;
  border-color: #699f7b;
}
.cmd-pan {
  background-image: url("../../../assets/icons/annotation/pan.png");
}
.cmd-edit {
  background-image: url("../../../assets/icons/annotation/edit.png");
}
.cmd-undo {
  background-image: url("../../../assets/icons/annotation/undo.png");
}
.cmd-redo {
  background-image: url("../../../assets/icons/annotation/redo.png");
}
.cmd-delete {
  background-image: url("../../../assets/icons/annotation/delete.png");
}
.cmd-delete:hover:enabled {
  cursor: pointer;
  border-color: red;
}
.image-placeholder {
  aspect-ratio: 1.25;
  z-index: 11;
  width: 100%;
  position: absolute;
  padding: inherit;

  background-color: black;
  display: flex;
  color: #aaa;
  align-items: center;
  justify-content: center;
}
.image-canvas {
  width: 100%;
  aspect-ratio: 1.25;
  z-index: 12;
  position: relative;
  width: 100%;
  height: 100%;

  &.full-screen {
    height: 100vh;
    width: auto;
  }
}

.enlarge-button {
  position: absolute;
  bottom: 0;
  right: 0;
  color: $primary;
  background-color: rgba(255, 255, 255, 0.666);
  z-index: 13;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 5px;
  margin: 1rem;
  -webkit-text-stroke: 1px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.825);
    cursor: pointer;
  }
}
</style>
