<template>
  <figure class="figure thumbnail">
    <img
      :src="image.thumbnail ? image.thumbnail : image.s3_image_url"
      :class="{
        'figure-img': true,
        'img-fluid': true,
        rounded: true,
        selected: selected,
      }"
      @click="onClick(image.id)"
    />
    <figcaption class="figure-caption">{{ caption }}</figcaption>
  </figure>
</template>
<script setup>
import { computed } from "vue";

const props = defineProps(["image", "selected"]);
const emit = defineEmits(["click"]);

const caption = computed(() => {
  let result = "#" + props.image.id + " - ";
  if (props.image.eye === "l") result += "OS";
  else if (props.image.eye === "r") result += "OD";

  if (props.image.gaze_target) {
    let gaze = props.image.gaze_target;
    if (gaze === "center") gaze = "macula";
    if (
      (props.image.eye === "l" && gaze === "right") ||
      (props.image.eye === "r" && gaze === "left")
    )
      gaze = "optic-disc";
    result += " - " + gaze.substring(0, 1).toUpperCase();
  }
  return result;
});

function onClick(id) {
  emit("click", id);
}
</script>
<style scoped>
.thumbnail img {
  cursor: pointer;
}

.thumbnail img.selected {
  cursor: unset;
  border: 4px solid #699f7b;
}
</style>
