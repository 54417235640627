import axios from "axios";

function formatDate(date) {
  const formattedDate = new Date(date);
  const year = formattedDate.getFullYear();
  const month = String(formattedDate.getMonth() + 1).padStart(2, "0");
  const day = String(formattedDate.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

function useAuditLogs() {
  const endpoint = import.meta.env.VITE_BIRDHOUSE_ENDPOINT;
  const userActionTypes = {
    PHI_ACCESS: "PHI_ACCESS",
    VIEW_REPORT: "VIEW_REPORT",
    DOWNLOAD_REPORT: "DOWNLOAD_REPORT",
  };

  async function listAuditLogs(limit = 20, offset = 0, sortBy, filters) {
    const sort = sortBy === "+timestamp" ? "timestamp ASC" : "timestamp DESC";
    const params = { limit, offset, sort, ...filters };
    return (await axios.get(endpoint + "/audit/logs", { params })).data;
  }

  async function logUserAction(userId, actionType, visitId = null) {
    let data = {
      user_id: userId,
      action_type: actionType,
    };
    if (visitId) {
      data.info = { visit_id: visitId };
    }
    return axios.post(endpoint + "/audit/logs", data);
  }

  return {
    listAuditLogs,
    logUserAction,
    formatDate,
    userActionTypes,
  };
}

export default useAuditLogs;
