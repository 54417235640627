import axios from "axios";

function useImagesAPI() {
  async function getImageAnnotations(imageID) {
    return (
      await axios.get(
        import.meta.env.VITE_BIRDHOUSE_ENDPOINT +
          "/images/" +
          imageID +
          "/annotations"
      )
    ).data.annotations;
  }

  async function createImageAnnotation(imageID, label, stroke) {
    return (
      await axios.post(
        import.meta.env.VITE_BIRDHOUSE_ENDPOINT +
          "/images/" +
          imageID +
          "/annotations",
        JSON.stringify({
          label: label,
          stroke: stroke,
        }),
        { headers: { "Content-Type": "application/json" } }
      )
    ).data.id;
  }

  function deleteImageAnnotation(annotationID) {
    return axios.delete(
      import.meta.env.VITE_BIRDHOUSE_ENDPOINT + "/annotations/" + annotationID
    );
  }

  async function listImages(
    limit = 10,
    offset = 0,
    cataract = "",
    diabetes = "",
    min_diabetes = "",
    max_diabetes = "",
    min_age = "",
    max_age = "",
    dr_grading = "",
    location_id = "",
    patient_id = "",
    visit_id = "",
    sudden_pupil_contraction = "",
    start_date = "",
    end_date = ""
  ) {
    return (
      await axios.get(import.meta.env.VITE_BIRDHOUSE_ENDPOINT + "/images", {
        params: {
          limit,
          offset,
          cataract,
          diabetes,
          min_diabetes,
          max_diabetes,
          min_age,
          max_age,
          dr_grading,
          location_id,
          patient_id,
          visit_id,
          sudden_pupil_contraction,
          start_date,
          end_date,
        },
      })
    ).data;
  }

  async function getImage(id) {
    return (
      await axios.get(import.meta.env.VITE_BIRDHOUSE_ENDPOINT + "/images/" + id)
    ).data;
  }

  return {
    getImageAnnotations,
    createImageAnnotation,
    deleteImageAnnotation,
    listImages,
    getImage,
  };
}
export default useImagesAPI;
