<template>
  <BreadCrumbs
    :current="sw_package.version"
    parent="Software packages"
    parent-path="/packages"
  />
  <div class="body">
    <div class="d-flex mb-3">
      <h2>Software package: {{ sw_package.version }}</h2>
      <div class="ms-auto">
        <button class="btn btn-primary" @click="delPackage">Delete</button>
      </div>
    </div>

    <div class="row">
      <table class="table table-striped table-hover">
        <thead>
          <tr>
            <th>File name</th>
            <th>Size</th>
            <th>Destination</th>
            <th>MD5</th>
            <th>Download</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="f in sw_package.files" :key="f.source">
            <td>{{ f.source }}</td>
            <td>{{ f.size }}</td>
            <td>{{ f.dest }}</td>
            <td>{{ f.md5 }}</td>
            <td>
              <a :href="f.url">
                <img
                  src="@/assets/icons/download/download_icon.png"
                  width="32"
                  height="32"
                />
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import backend from "@/backend";

const router = useRouter();
const props = defineProps(["packageID"]);
const sw_package = ref({});

async function delPackage() {
  await backend.packages.deletePackage(props.packageID);
  router.push("/packages");
}

async function loadData() {
  sw_package.value = await backend.packages.getPackage(props.packageID);
}

onMounted(async function () {
  loadData();
});
</script>
