<template>
  <div v-if="eye_in_images(RIGHT_EYE)">
    <h5 class="border-bottom">Diabetic retinopathy grading (OD)</h5>
    <select v-model="formData.dr_grade_od" class="form-select mb-4" required>
      <option disabled value="undefined">- Please select -</option>
      <option v-for="(value, key) in DrGrades" :key="key" :value="key">
        {{ value }}
      </option>
    </select>

    <section v-for="diagnosisType in odDiagnosisTypes" :key="diagnosisType.id">
      <h5 class="border-bottom">{{ diagnosisType.label }}</h5>
      <select v-model="formData[diagnosisType.id]" class="form-select mb-4">
        <option disabled value="undefined">- Please select -</option>
        <option
          v-for="diagnosisOption in diagnosisType.options"
          :key="diagnosisOption.id"
          :value="diagnosisOption.id"
        >
          {{ diagnosisOption.label }}
        </option>
      </select>
    </section>
  </div>

  <div v-if="eye_in_images(LEFT_EYE)">
    <h5 class="border-bottom">Diabetic retinopathy grading (OS)</h5>
    <select v-model="formData.dr_grade_os" class="form-select mb-4" required>
      <option disabled value="undefined">- Please select -</option>
      <option v-for="(value, key) in DrGrades" :key="key" :value="key">
        {{ value }}
      </option>
    </select>

    <section v-for="diagnosisType in osDiagnosisTypes" :key="diagnosisType.id">
      <h5 class="border-bottom">{{ diagnosisType.label }}</h5>
      <select v-model="formData[diagnosisType.id]" class="form-select mb-4">
        <option disabled value="undefined">- Please select -</option>
        <option
          v-for="diagnosisOption in diagnosisType.options"
          :key="diagnosisOption.id"
          :value="diagnosisOption.id"
        >
          {{ diagnosisOption.label }}
        </option>
      </select>
    </section>
  </div>

  <h5 class="border-bottom">Recommendation</h5>
  <select v-model="formData.recommendation" class="form-select mb-4">
    <option disabled value="undefined">- Please select -</option>
    <option v-for="v in analystRecommendations" :key="v" :value="v">
      {{ v }}
    </option>
  </select>

  <h5 class="border-bottom">Images for report</h5>
  <!-- OD Image Dropdown -->
  <div class="row mb-4" v-if="eye_in_images(RIGHT_EYE)">
    <div class="col-md-3">
      <label for="odImage" class="form-label">OD Image</label>
    </div>
    <div class="col-md-9">
      <select v-model="formData.odImage" class="form-select">
        <option disabled value="undefined">- Please select -</option>
        <option v-for="image in odImages" :key="image.id" :value="image.id">
          {{ image.id }}
        </option>
      </select>
    </div>
  </div>
  <!-- OS Image Dropdown -->
  <div class="row mb-4" v-if="eye_in_images(LEFT_EYE)">
    <div class="col-md-3">
      <label for="osImage" class="form-label">OS Image</label>
    </div>
    <div class="col-md-9">
      <select v-model="formData.osImage" class="form-select">
        <option disabled value="undefined">- Please select -</option>
        <option v-for="image in osImages" :key="image.id" :value="image.id">
          {{ image.id }}
        </option>
      </select>
    </div>
  </div>

  <h5 class="border-bottom">Visit notes</h5>
  <VisitNotes ref="visitNotes" :visit-i-d="visit.id" @change="notesChanged" />

  <div class="text-end d-flex justify-content-end gap-3 mt-4">
    <button
      class="btn btn-oivi-light mb-2"
      :disabled="unsavedWork"
      @click="previewReport"
    >
      Preview report
    </button>
    <button
      class="btn btn-secondary"
      type="submit"
      :disabled="unsavedWork"
      @click="beginSubmit"
    >
      Submit analysis &raquo;
    </button>
  </div>

  <ModalForm v-show="errors.length > 0" title="Error" @close="errors = []">
    <p>The analysis is missing input:</p>
    <div v-for="error in errors" :key="error" class="alert alert-danger">
      {{ error }}
    </div>
    <div class="text-center mt-5">
      <button class="btn btn-secondary" @click="errors = []">Close</button>
    </div>
  </ModalForm>
  <ModalForm
    v-show="confirmVisible"
    title="Confirm submission"
    @close="confirmVisible = false"
  >
    <p>
      Please confirm that the analysis is complete and ready for submission.
      This action can't be undone.
    </p>
    <div class="text-center mt-5">
      <button class="btn btn-secondary me-3" @click="confirmVisible = false">
        Cancel
      </button>
      <button class="btn btn-primary" @click="confirmSubmit">Confirm</button>
    </div>
  </ModalForm>
</template>
<script setup>
import { reactive, ref, computed, watch } from "vue";
import { useRouter } from "vue-router";
import backend from "@/backend";
import ModalForm from "@/components/ModalForm.vue";
import VisitNotes from "./VisitNotes.vue";
import { DrGrades, analystRecommendations } from "../AnnotationGuide";
import { odDiagnosisTypes, osDiagnosisTypes } from "./diagnosisOptions";

const LEFT_EYE = "l";
const RIGHT_EYE = "r";

const props = defineProps(["visit", "images"]);

function eye_in_images(eye) {
  return props.images.map(({ eye }) => eye).includes(eye);
}

const osImages = computed(() => props.images.filter((x) => x.eye === "l"));
const odImages = computed(() => props.images.filter((x) => x.eye === "r"));

const formDataDefaults = {
  dr_grade_od: undefined,
  dme_od: undefined,
  htr_od: undefined,
  glaucoma_od: undefined,
  dr_grade_os: undefined,
  dme_os: undefined,
  htr_os: undefined,
  glaucoma_os: undefined,
  recommendation: undefined,
  odImage: undefined,
  osImage: undefined,
  notes: "",
};

watch(odImages, (newImages) => {
  if (odImages.value.length > 0) {
    formData.odImage = newImages[0].id;
  }
});

watch(osImages, (newImages) => {
  if (osImages.value.length > 0) {
    formData.osImage = newImages[0].id;
  }
});

const router = useRouter();
const formData = reactive({ ...formDataDefaults });
const errors = ref([]);
const confirmVisible = ref(false);
const visitNotes = ref(null);
const unsavedWork = ref(false);

function notesChanged(value) {
  if (value) unsavedWork.value = true;
  else unsavedWork.value = false;
}

function beginSubmit(ev) {
  ev.preventDefault();
  ev.stopPropagation();
  const errors = inputHasErrors();
  if (errors.value.length == 0) confirmVisible.value = true;
}

function inputHasErrors() {
  errors.value = [];
  if (
    (eye_in_images(RIGHT_EYE) && !formData.dr_grade_od) ||
    (eye_in_images(LEFT_EYE) && !formData.dr_grade_os)
  )
    errors.value.push("Diabetic retinopathy grading not selected");
  if (!formData.recommendation)
    errors.value.push("Recommendation not selected");
  if (odImages.value.length > 0) {
    if (!formData.odImage) {
      errors.value.push("OD Image not selected");
    }
  }
  if (osImages.value.length > 0) {
    if (!formData.osImage) {
      errors.value.push("OS Image not selected");
    }
  }

  return errors;
}

async function confirmSubmit() {
  confirmVisible.value = false;
  await backend.visits.submitAnalysis(
    props.visit.id,
    formData.dr_grade_od,
    formData.dme_od,
    formData.htr_od,
    formData.glaucoma_od,
    formData.dr_grade_os,
    formData.dme_os,
    formData.htr_os,
    formData.glaucoma_os,
    formData.recommendation,
    formData.odImage,
    formData.osImage
  );

  router.push("/visits");
}

async function previewReport() {
  const errors = inputHasErrors();
  if (errors.value.length) return;

  const previewConfig = {
    recommendation: formData.recommendation,
    selected_os_image: formData.osImage,
    selected_od_image: formData.odImage,
    dr_grading_od: formData.dr_grade_od,
    dme_od: formData.dme_od,
    htr_od: formData.htr_od,
    glaucoma_od: formData.glaucoma_od,
    dr_grading_os: formData.dr_grade_os,
    dme_os: formData.dme_os,
    htr_os: formData.htr_os,
    glaucoma_os: formData.glaucoma_os,
  };
  const result = await backend.visits.downloadReport(props.visit.id, {
    preview: true,
    ...previewConfig,
  });
  console.log(previewConfig);
  const url = URL.createObjectURL(result);
  window.open(url);
}
</script>
<style>
button.note-delete {
  float: right;
  border: none;
  background-image: url("../../../assets/icons/annotation/delete.png");
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent;
  width: 24px;
  height: 24px;
}
</style>
