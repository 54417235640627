<template>
  <ModalForm v-show="visible" @close="visible = false" title="Edit device">
    <div class="form-outline mb-4">
      <select class="form-select" v-model="selectedChannel">
        <option disabled value="">Select software channel</option>
        <option v-for="c in channels" :value="c.id" :key="c.id">
          {{ c.name }}
        </option>
      </select>
    </div>

    <div class="text-center mt-5">
      <button class="btn btn-lg btn-primary" @click="save">Save</button>
    </div>
  </ModalForm>
</template>
<script setup>
import { ref } from "vue";
import backend from "@/backend";
import ModalForm from "@/components/ModalForm.vue";

const emit = defineEmits(["dataChange"]);
const props = defineProps(["device"]);

const visible = ref(false);
const channels = ref([]);
const selectedChannel = ref(null);

async function save() {
  await backend.devices.updateDevice(props.device.serial, {
    sw_channel_id: selectedChannel.value,
  });
  visible.value = false;
  emit("dataChange");
}

async function loadData() {
  channels.value = await backend.channels.listChannels();
  selectedChannel.value = props.device.sw_channel_id;
}

async function show() {
  await loadData();
  visible.value = true;
}

defineExpose({ show });
</script>
