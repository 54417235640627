<template>
  <label v-if="props.label" :for="props.textAreaName" class="form-label fw-bold"
    >{{ props.label
    }}<i
      v-if="props.tooltipContent"
      class="bi bi-info-circle ms-2"
      id="info-icon"
      data-toggle="tooltip"
      :title="props.tooltipContent"
    ></i
  ></label>
  <Field
    :name="props.textAreaName"
    :value="props.modelValue"
    :rules="props.validator"
    v-slot="{ field, errors, handleChange }"
    @input="$emit('update:modelValue', $event.target.value)"
  >
    <textarea
      v-bind="field"
      :value="props.modelValue"
      class="form-control"
      id="additionalNotes"
      :placeholder="props.placeholder"
      :rows="props.rows"
      style="height: 100%"
      :maxlength="props.maxLength"
      @keyup="(e) => triggerChangeHandler(e, handleChange)"
      :class="{ 'is-invalid': !!errors.length }"
    ></textarea>
    <div class="row">
      <div class="col-6">
        <ErrorMessage
          class="error-message"
          :name="props.textAreaName"
          :id="props.textAreaName + 'ErrorMessage'"
        />
      </div>
      <div
        class="col-6 text-end max-count"
        :class="{
          'max-reached':
            parseInt(props.modelValue?.length) === parseInt(props.maxLength),
          'is-invalid': !!errors.length,
        }"
        v-if="props.maxLength"
      >
        {{ parseInt(props.modelValue?.length) }} /
        {{ parseInt(props.maxLength) }}
      </div>
    </div>
  </Field>
</template>

<script setup>
import { ErrorMessage, Field } from "vee-validate";

const props = defineProps([
  "modelValue",
  "label",
  "textAreaName",
  "tooltipContent",
  "placeholder",
  "rows",
  "validator",
  "maxLength",
]);

// do not trigger validation immediately when field was accessed with tab (has keyCode 9)
function triggerChangeHandler(e, changeHandler) {
  if (e.keyCode === 9) return;
  return changeHandler();
}
</script>
<style lang="scss">
@import "@/style/style.scss";

.max-count {
  color: $success;

  &.max-reached {
    color: $dark;
  }
}

.is-invalid {
  color: $danger;
}
</style>
