import backend from "@/backend";

function useCommandHistory() {
  var icVars, icFuncs;

  function initUseCommandHistory(icVarsR, icFuncsR) {
    icVars = icVarsR;
    icFuncs = icFuncsR;
  }

  function rebuildStrokes() {
    icVars.strokes = [];
    icVars.downloadedStrokes.forEach((stroke) => {
      icVars.strokes.push(stroke);
    });

    for (let i = 0; i < icVars.currentCommandIndex; i++) {
      if (icVars.commandHistory[i].command === "add") {
        icVars.strokes.push(icVars.commandHistory[i].stroke);
      } else if (icVars.commandHistory[i].command === "remove") {
        delete icVars.strokes[icVars.commandHistory[i].index];
      }
    }
  }

  async function addStroke() {
    const annotationID = await backend.images.createImageAnnotation(
      icVars.currentImageID,
      icVars.currentLabel,
      icVars.currentStroke
    );
    let stroke = {
      id: annotationID,
      points: icVars.currentStroke,
      label: icVars.currentLabel,
      color: icFuncs.getLabelColor(icVars.currentLabel),
    };
    icVars.commandHistory.splice(icVars.currentCommandIndex, 0, {
      command: "add",
      stroke: stroke,
    });
    icVars.currentCommandIndex += 1;
    icVars.strokes.push(stroke);
    icFuncs.redrawCanvas();
  }

  async function removeStroke(index) {
    await backend.images.deleteImageAnnotation(icVars.strokes[index].id);
    let stroke = {
      id: icVars.strokes[index].id,
      points: icVars.strokes[index].points,
      label: icVars.strokes[index].label,
      color: icVars.strokes[index].color,
    };
    icVars.commandHistory.splice(icVars.currentCommandIndex, 0, {
      command: "remove",
      index: index,
      stroke: stroke,
    });
    icVars.currentCommandIndex += 1;
    delete icVars.strokes[index];
  }

  async function updateCommandToBackend(cmd, undo, redo) {
    if ((cmd.command === "add" && undo) || (cmd.command === "remove" && redo)) {
      await backend.images.deleteImageAnnotation(cmd.stroke.id);
      for (let i = 0; i < icVars.downloadedStrokes; i++) {
        if (icVars.downloadedStrokes.id === cmd.stroke.id) {
          delete icVars.downloadedStrokes[i];
          break;
        }
      }
    } else if (
      (cmd.command === "remove" && undo) ||
      (cmd.command === "add" && redo)
    ) {
      cmd.stroke_id = await backend.images.createImageAnnotation(
        icVars.currentImageID,
        cmd.stroke.label,
        cmd.stroke.points
      );
    }

    return 0;
  }
  async function undoHandler() {
    if (icVars.currentCommandIndex > 0) {
      let cmd = icVars.commandHistory[icVars.currentCommandIndex - 1];
      updateCommandToBackend(cmd, true, false);
      icVars.currentCommandIndex -= 1;
      rebuildStrokes();
      icFuncs.redrawCanvas();
    }
  }

  async function redoHandler() {
    if (icVars.currentCommandIndex < icVars.commandHistory.length) {
      let cmd = icVars.commandHistory[icVars.currentCommandIndex];
      updateCommandToBackend(cmd, false, true);
      icVars.currentCommandIndex += 1;
      rebuildStrokes();
      icFuncs.redrawCanvas();
    }
  }

  return {
    initUseCommandHistory,
    rebuildStrokes,
    addStroke,
    removeStroke,
    undoHandler,
    redoHandler,
  };
}
export default useCommandHistory;
