<template>
  <div class="modal-backdrop-custom" style="z-index: 20" @click="close">
    <div class="container py-5 h-100">
      <div class="row d-flex justify-content-center align-items-center h-100">
        <div class="col-12 col-md-8 col-lg-6 col-xl-5">
          <div class="card" style="border-radius: 1rem" @click.stop="">
            <div class="card-body p-5 text-center">
              <div class="mb-md-5 mt-md-4 pb-2">
                <h2 class="fw-bold mb-5 text-uppercase">Select Label</h2>

                <button
                  type="button"
                  class="btn-close"
                  @click="close"
                  aria-label="Close modal"
                />

                <div class="table-responsive">
                  <table class="table table-hover">
                    <tbody>
                      <template v-for="{ id, label } in labelColors" :key="id">
                        <tr
                          :class="{
                            'table-row': true,
                          }"
                          @click="labelSelected(label)"
                        >
                          <td>{{ label }}</td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import useLabelColors from "../composables/useLabelColors";
const emit = defineEmits(["close", "labelSelected"]);
const { getAllLabelColors } = useLabelColors();
const labelColors = ref(getAllLabelColors());

function close() {
  emit("close");
}

function labelSelected(label) {
  emit("labelSelected", label);
}
</script>

<style>
.modal-backdrop-custom {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}

.btn-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  font-weight: bold;
  background: transparent;
}

.table-row {
  cursor: pointer;
}

.table-row-selected {
  background-color: #ddd;
}

.table-responsive {
  max-height: 400px;
}
</style>
