<template>
  <div class="body container-fluid">
    <div class="d-flex mb-3 gap-3">
      <h2>Audit Logs</h2>
      <div>
        <select
          class="form-select"
          v-model="actiontype"
          :onchange="applyFilter"
        >
          <option value="INSERT">INSERT</option>
          <option value="UPDATE">UPDATE</option>
          <option value="DELETE">DELETE</option>
          <option value="PHI_ACCESS">PHI_ACCESS</option>
          <option value="VIEW_REPORT">VIEW_REPORT</option>
          <option value="DOWNLOAD_REPORT">DOWNLOAD_REPORT</option>
        </select>
      </div>
    </div>
    <div></div>
    <div>
      <div class="input-group mb-2 mt-3 gap-4">
        <input
          type="date"
          class="form-control"
          v-model="fromDate"
          :max="toDate"
        />
        <input
          type="date"
          class="form-control"
          v-model="toDate"
          :min="fromDate"
        />
        <input
          type="number"
          class="form-control"
          placeholder="User ID"
          v-model="userid"
        />
      </div>
      <div class="row mt-1 justify-content-start py-2 mb-2">
        <div class="col-md-6 text-start">
          <button
            type="submit"
            class="btn btn-primary"
            @click.prevent="applyFilter"
            style="margin-right: 15px"
          >
            Filter
          </button>
          <button @click="clearFilter" class="btn btn-secondary btn-sm">
            Reset
          </button>
        </div>
      </div>
    </div>
    <ViewDiffDialog
      v-if="dialogOpen"
      :oldData="oldData"
      :newData="newData"
      @close="handleCloseViewDiffDialog"
    ></ViewDiffDialog>

    <table class="table table-striped table-hover table-sm">
      <thead>
        <tr>
          <th>Id</th>
          <th v-if="shouldShowColumn('table_name')">Table Name</th>
          <th>Action Type</th>
          <th>User</th>
          <th v-if="shouldShowColumn('ip_address')">IP Address</th>
          <th v-if="shouldShowColumn('old_data')">Old Data</th>
          <th v-if="shouldShowColumn('new_data')">New Data</th>
          <th v-if="shouldShowColumn('info')">Info</th>
          <th @click="sortBy('timestamp')" role="button">
            Timestamp
            <SortIcon col="timestamp" :sorting="sortorder" />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="log in logs" :key="log.id">
          <td style="min-width: 60px">{{ log.id }}</td>
          <td
            style="min-width: 110px"
            class="text-uppercase"
            v-if="shouldShowColumn('table_name')"
          >
            {{ log.table_name }}
          </td>
          <td style="min-width: 110px">{{ log.action_type }}</td>
          <td style="min-width: 80px">
            <a :href="'/users/' + log.user_id">{{ log.user_id }}</a>
          </td>
          <td style="min-width: 110px" v-if="shouldShowColumn('ip_address')">
            {{ log.ip_address }}
          </td>
          <td v-if="shouldShowColumn('old_data')">
            <div class="two-lines">{{ log.old_data }}</div>
          </td>
          <td v-if="shouldShowColumn('new_data')">
            <div class="two-lines">{{ log.new_data }}</div>
          </td>
          <td v-if="shouldShowColumn('info')">
            <div class="two-lines">
              <a :href="'/visits/' + extractVisitId(log.info)">{{
                log.info
              }}</a>
            </div>
          </td>
          <td>{{ formatDate(log.timestamp) }}</td>
          <td
            v-if="shouldShowColumn('new_data') || shouldShowColumn('old_data')"
            style="min-width: 60px"
          >
            <button @click="openDiffDialog(log.old_data, log.new_data)">
              View
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <PageSelect
      :totalEntries="totalEntries"
      :limitPerPage="limitPerPage"
      :offsetValue="offsetValue"
      @newOffsetValue="newOffsetValue"
      @newLimitPerPage="newLimitPerPage"
    />
  </div>
</template>
<script setup>
import { ref, onMounted } from "vue";
import backend from "@/backend";
import { formatDate } from "@/util/formatting";
import PageSelect from "@/components/PageSelect.vue";
import ViewDiffDialog from "@/components/ViewDiffDialog.vue";
import SortIcon from "@/components/SortIcon.vue";

const logs = ref([]);
const totalEntries = ref(0);
const limitPerPage = ref(20);
const offsetValue = ref(0);
const fromDate = ref("");
const toDate = ref("");
const actiontype = ref("PHI_ACCESS");
const userid = ref("");
const sortorder = ref("-timestamp");
const dialogOpen = ref(false);
const oldData = ref("");
const newData = ref("");

async function loadData() {
  const filters = getActiveFilters();
  const logData = await backend.audit.listAuditLogs(
    limitPerPage.value,
    offsetValue.value,
    sortorder.value,
    filters
  );
  logs.value = logData.audit_logs;
  totalEntries.value = logData.total_count;
}

function applyFilter() {
  offsetValue.value = 0;
  loadData();
}

function clearFilter() {
  fromDate.value = "";
  toDate.value = "";
  userid.value = "";
  loadData();
}

function getActiveFilters() {
  const fields = {
    from_date: fromDate,
    to_date: toDate,
    action_type: actiontype,
    user_id: userid,
  };
  return Object.entries(fields).reduce((filters, [key, field]) => {
    if (field.value) {
      if (key.includes("date")) {
        const date = new Date(field.value);
        if (key === "from_date") {
          date.setHours(0, 0, 0, 0);
        } else {
          date.setDate(date.getDate() + 1);
          date.setHours(0, 0, 0, 0);
        }
        filters[key] = backend.audit.formatDate(date);
      } else {
        filters[key] = field.value;
      }
    }
    return filters;
  }, {});
}

function newLimitPerPage(limit) {
  limitPerPage.value = limit;
  loadData();
}

function newOffsetValue(newOffsetVal) {
  offsetValue.value = newOffsetVal;
  loadData();
}

async function openDiffDialog(o, n) {
  oldData.value = o;
  newData.value = n;
  console.log(n);
  dialogOpen.value = true;
}

function handleCloseViewDiffDialog() {
  dialogOpen.value = false;
  oldData.value = "";
  newData.value = "";
}

function sortBy(column) {
  sortorder.value =
    sortorder.value === `+${column}` ? `-${column}` : `+${column}`;
  console.log(sortorder.value);
  loadData();
}

function shouldShowColumn(column) {
  const hideColumns = {
    PHI_ACCESS: ["table_name", "ip_address", "old_data", "new_data", "info"],
    VIEW_REPORT: ["table_name", "ip_address", "old_data", "new_data"],
    DOWNLOAD_REPORT: ["table_name", "ip_address", "old_data", "new_data"],
    INSERT: ["old_data", "info"],
    DELETE: ["new_data", "info"],
    UPDATE: ["info"],
  };
  return !hideColumns[actiontype.value]?.includes(column);
}

function extractVisitId(info) {
  try {
    const parsedInfo = JSON.parse(info);
    return parsedInfo.visit_id;
  } catch (error) {
    console.error("Error parsing 'info' field:", error);
    return null;
  }
}

onMounted(async function () {
  loadData();
});
</script>

<style scoped>
.table .two-lines {
  max-height: 3.6em;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  display: -webkit-inline-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
