<template>
  <div class="text-center">
    <h5>IMAGES</h5>

    <ThumbnailImage
      v-for="image in images"
      :key="image.id"
      :image="image"
      :selected="image.id == selectedId"
      @click="onClick"
    />
  </div>
</template>

<script setup>
import { ref } from "vue";
import ThumbnailImage from "@/components/ThumbnailImage.vue";

defineProps(["images"]);
const emit = defineEmits(["onImageSelected"]);

const selectedId = ref(0);

function onClick(id) {
  selectedId.value = id;
  emit("onImageSelected", id);
}
</script>
