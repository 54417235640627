<template>
  <div>
    <canvas ref="chartCanvas"></canvas>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, watchEffect } from "vue";
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

const props = defineProps({
  data: Object,
  options: Object,
  type: String,
});

const chartCanvas = ref(null);
let myChart = null;

const createChart = () => {
  if (myChart) {
    myChart.destroy();
  }
  try {
    myChart = new Chart(chartCanvas.value.getContext("2d"), {
      type: props.type,
      data: props.data,
      options: props.options,
    });
  } catch (_err) {
    // do nothing
  }
};

onMounted(createChart);

watchEffect(() => {
  createChart();
});

onBeforeUnmount(() => {
  if (myChart) {
    myChart.destroy();
  }
});
</script>
