import axios from "axios";

function useLocationsAPI() {
  const endpoint = import.meta.env.VITE_BIRDHOUSE_ENDPOINT;

  function addOrgLocation(orgId, data) {
    return axios.post(
      endpoint + "/organizations/" + orgId + "/locations",
      JSON.stringify(data),
      {
        headers: { "Content-Type": "application/json" },
      }
    );
  }

  async function listLocations() {
    return (await axios.get(endpoint + "/locations")).data.locations;
  }

  async function listOrgLocations(orgId) {
    return (
      await axios.get(endpoint + "/organizations/" + orgId + "/locations")
    ).data.locations;
  }

  async function getLocation(id) {
    return (await axios.get(endpoint + "/locations/" + id)).data;
  }

  function updateLocation(id, data) {
    return axios.patch(endpoint + "/locations/" + id, JSON.stringify(data), {
      headers: { "Content-Type": "application/json" },
    });
  }

  function deleteLocation(id) {
    return axios.delete(endpoint + "/locations/" + id);
  }

  async function listLabels() {
    return (await axios.get(endpoint + "/locations/labels")).data.labels;
  }

  return {
    listLocations,
    listOrgLocations,
    addOrgLocation,
    getLocation,
    updateLocation,
    deleteLocation,
    listLabels,
  };
}

export default useLocationsAPI;
