import axios from "axios";

function useUsersAPI() {
  const endpoint = import.meta.env.VITE_BIRDHOUSE_ENDPOINT;

  async function getCurrentUser() {
    return (await axios.get(endpoint + "/users/current")).data;
  }

  function updateCurrentUser(name, phone, qualifications) {
    return axios.patch(
      endpoint + "/users/current",
      JSON.stringify({
        name: name,
        phone: phone,
        qualifications: qualifications,
      }),
      { headers: { "Content-Type": "application/json" } }
    );
  }

  function addUser(data) {
    return axios.post(endpoint + "/users", JSON.stringify(data), {
      headers: { "Content-Type": "application/json" },
    });
  }

  function updateUser(id, data) {
    return axios.patch(endpoint + "/users/" + id, JSON.stringify(data), {
      headers: { "Content-Type": "application/json" },
    });
  }

  function deleteUser(id) {
    return axios.delete(endpoint + "/users/" + id);
  }

  async function getUser(id) {
    return (await axios.get(endpoint + "/users/" + id)).data;
  }

  async function listUsers(limit = 0, offset = 0, search = null) {
    const params = { limit, offset };
    if (search?.name) params["name"] = search.name;
    if (search?.email) params["email"] = search.email;
    if (search?.org) params["org"] = search.org;
    if (search?.role) params["roles"] = search.role;
    return (await axios.get(endpoint + "/users", { params })).data;
  }

  function getAvailableRoles(user) {
    let result = [];
    if (user.roles.includes("sys_admin")) {
      result.push("experimental");
      result.push("sys_admin");
    }
    if (user.roles.includes("sys_admin") || user.roles.includes("sales")) {
      result.push("sales");
      result.push("medical_analyst");
    }
    result.push("org_admin");
    result.push("operator");
    result.push("medical_practitioner");
    result.push("opensearch_analyst");
    return result;
  }

  function getAllRoles() {
    return getAvailableRoles({ roles: ["sys_admin"] });
  }

  return {
    getCurrentUser,
    updateCurrentUser,
    listUsers,
    addUser,
    updateUser,
    deleteUser,
    getUser,
    getAvailableRoles,
    getAllRoles,
  };
}

export default useUsersAPI;
