<template>
  <BreadCrumbs
    :current="channel.name"
    parent="Channels"
    parent-path="/channels"
  />
  <div class="body">
    <div class="row">
      <div class="col-sm-3">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{{ channel.name }}</h5>
            <p class="card-text">
              <router-link
                :to="'/packages/' + channel.sw_package_id"
                @click.stop
              >
                {{ channel.software_version }}
              </router-link>
            </p>
            <button
              class="btn btn-oivi-light me-2"
              @click="editChannelModal.show()"
            >
              Edit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <EditChannelModal
    ref="editChannelModal"
    :channel="channel"
    @dataChange="loadData"
  />
</template>
<script setup>
import { ref, onMounted } from "vue";
import backend from "@/backend";

import EditChannelModal from "./components/EditChannelModal.vue";
const editChannelModal = ref(null);

const props = defineProps(["channelID"]);
const channel = ref({});

async function loadData() {
  channel.value = await backend.channels.getChannel(props.channelID);
}

onMounted(loadData);
</script>
