interface DiagnosisOption {
  id: string;
  label: string;
}

interface DiagnosisTypes {
  id: string;
  label: string;
  options: DiagnosisOption[];
}

export const odDiagnosisTypes: DiagnosisTypes[] = [
  {
    id: "dme_od",
    label: "Diabetic Macular Edema (OD)",
    options: [
      {
        id: "no_dme",
        label: "No DME",
      },
      {
        id: "non_centre_significant_dme",
        label: "Non-centre involving clinically significant DME",
      },
      {
        id: "centre_significant_dme",
        label: "Centre involving clinically significant DME",
      },
    ],
  },
  {
    id: "htr_od",
    label: "Hypertensive Retinopathy (OD)",
    options: [
      {
        id: "no_htr",
        label: "No HTR",
      },
      {
        id: "mild_htr",
        label: "Mild HTR",
      },
      {
        id: "moderate_htr",
        label: "Moderate HTR",
      },
      {
        id: "severe_htr",
        label: "Severe HTR",
      },
    ],
  },
  {
    id: "glaucoma_od",
    label: "Glaucoma (OD)",
    options: [
      {
        id: "normal_0_7_ratio",
        label: "Normal with less than 0.7 cup: disc ratio",
      },
      {
        id: "suspicious_0_7_ratio",
        label: "Suspicious of glaucoma with =/>0.7 cup: disc ratio",
      },
      {
        id: "suspicious_with_cdr_symmetry",
        label: "Suspicious of glaucoma with CDR asymmetry between the two eyes",
      },
      {
        id: "suspicious_disc_haemorrhage",
        label: "Suspicious of glaucoma with disc haemorrhage",
      },
      {
        id: "suspicious_abnormal_disc",
        label: "Suspicious of glaucoma with abnormal disc features",
      },
    ],
  },
];

export const osDiagnosisTypes: DiagnosisTypes[] = [
  {
    ...odDiagnosisTypes[0],
    id: "dme_os",
    label: "Diabetic Macular Edema (OS)",
  },
  {
    ...odDiagnosisTypes[1],
    id: "htr_os",
    label: "Hypertensive Retinopathy (OS)",
  },
  {
    ...odDiagnosisTypes[2],
    id: "glaucoma_os",
    label: "Glaucoma (OS)",
  },
];
