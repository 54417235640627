<template>
  <BreadCrumbs :current="user.email" parent="Users" parent-path="/users" />
  <div class="body">
    <div class="card">
      <div class="card-body">
        <dl class="row">
          <dt class="col-sm-3">Name:</dt>
          <dd class="col-sm-9">{{ user.name ? user.name : "-" }}</dd>

          <dt class="col-sm-3">Email:</dt>
          <dd class="col-sm-9">{{ user.email }}</dd>

          <dt class="col-sm-3">Phone:</dt>
          <dd class="col-sm-9">{{ user.phone ? user.phone : "-" }}</dd>

          <dt class="col-sm-3">Organization:</dt>
          <dd class="col-sm-9">{{ user.organization }}</dd>

          <dt class="col-sm-3">Roles:</dt>
          <dd class="col-sm-9">
            <span v-for="role in user.roles" :key="role" class="me-1">
              {{ role }}
            </span>
            <span v-if="!user.roles || user.roles.length == 0"> - </span>
          </dd>
          <dt v-if="user.qualifications" class="col-sm-3">Qualifications:</dt>
          <dd v-if="user.qualifications" class="col-sm-9">
            {{ user.qualifications }}
          </dd>

          <dt class="col-sm-3">Last login:</dt>
          <dd class="col-sm-9">
            {{ user.last_login ? user.last_login : "Never" }}
          </dd>
        </dl>

        <button class="btn btn-primary me-2" @click="showEdit">Edit</button>
        <button
          v-if="user.email && !user.last_login"
          class="btn btn-primary"
          @click="showDeleteDialog"
        >
          Delete
        </button>
      </div>
    </div>
  </div>
  <UserDetailsModal
    v-if="editVisible"
    @close="hideEdit"
    @change="loadUser"
    :userID="props.userID"
    :roles="roles"
    :user="user"
  />
  <ModalForm
    v-show="deleteVisible"
    @close="hideDeleteDialog"
    title="Delete user?"
  >
    <p>Please confirm that you want to delete this user:</p>
    <p>
      <b>{{ user.email }}</b>
    </p>
    <div class="mt-5">
      <button class="btn btn-primary me-2" @click="doDelete">Confirm</button>
      <button class="btn btn-secondary" @click="hideDeleteDialog">
        Cancel
      </button>
    </div>
  </ModalForm>
</template>
<script setup>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import backend from "@/backend";
import ModalForm from "@/components/ModalForm.vue";
import UserDetailsModal from "./components/UserDetailsModal.vue";

const store = useStore();
const router = useRouter();
const props = defineProps(["userID"]);
const user = ref({});
const editVisible = ref(false);
const deleteVisible = ref(false);
const me = store.getters.currentUser;
const roles = computed(() => backend.users.getAvailableRoles(me));

function showEdit() {
  editVisible.value = true;
}

function hideEdit() {
  editVisible.value = false;
}

async function loadUser() {
  user.value = await backend.users.getUser(props.userID);
}

function showDeleteDialog() {
  deleteVisible.value = true;
}

function hideDeleteDialog() {
  deleteVisible.value = false;
}

async function doDelete() {
  await backend.users.deleteUser(props.userID);
  deleteVisible.value = false;
  router.push("/users");
}

onMounted(async function () {
  await loadUser();
});
</script>
