import axios from "axios";

function useLogsAPI() {
  const endpoint = import.meta.env.VITE_BIRDHOUSE_ENDPOINT;
  async function listCrashLogs(limit = 20, offset = 0, sort = "", serial = "") {
    return (
      await axios.get(endpoint + "/logs/crash", {
        params: {
          limit: limit,
          offset: offset,
          sort: sort,
          serial: serial,
        },
      })
    ).data;
  }

  const activityLogsEndpoint = endpoint + "/logs/cloud";

  async function postCloudActivityLogs(logs) {
    return axios.post(activityLogsEndpoint, JSON.stringify(logs), {
      headers: { "Content-Type": "application/json" },
    });
  }

  return {
    postCloudActivityLogs,
    listCrashLogs,
  };
}
export default useLogsAPI;
