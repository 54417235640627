<template>
  <component :is="currentComponent" v-bind="props" v-on="listeners" />
</template>

<script setup>
import { computed } from "vue";
import PatientFormIN from "./PatientFormIN.vue";
import PatientFormUS from "./PatientFormUS.vue";
import { AppConfig } from "@/util/AppConfig";

const region = AppConfig.region;
const props = defineProps(["patientInfo"]);
const emit = defineEmits(["finishEditing", "showExistingPatientsModal"]);

const listeners = {
  finishEditing: (...args) => emit("finishEditing", ...args),
  showExistingPatientsModal: (...args) =>
    emit("showExistingPatientsModal", ...args),
};

const currentComponent = computed(() => {
  switch (region) {
    case "us":
      return PatientFormUS;
    case "in":
    default:
      return PatientFormIN;
  }
});
</script>
