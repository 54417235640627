<template>
  <div
    id="tosModal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="tosModalLabel"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h1 id="tosModalLabel" class="modal-title fs-5">Terms of service</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body tos-content-wrapper">
          <ol>
            <li>
              <b>Acceptance of Terms</b>
              By accessing and using our software solution (the "Service"), you
              agree to comply with and be bound by these Terms of Service
              ("Terms"). If you do not agree with these Terms, you may not use
              the Service.
            </li>
            <li>
              <b>License</b> We grant you a limited, non-exclusive,
              non-transferable, and revocable license to use the Service
              according to the terms and conditions outlined in the agreement
              you have signed (the "Agreement").
            </li>
            <li>
              <b>Responsible Use</b>
              You agree to use the Service responsibly and in compliance with
              all applicable laws and regulations. You shall not:
              <ul>
                <li>
                  Use the Service for any unlawful or unauthorized purpose.
                </li>
                <li>
                  Attempt to interfere with the proper working of the Service or
                  gain unauthorized access.
                </li>
                <li>
                  Distribute, sublicense, or modify the Service unless
                  explicitly permitted in your Agreement.
                </li>
              </ul>
            </li>
            <li>
              <b>User Data</b>
              You are responsible for the accuracy, legality, and integrity of
              the data you provide while using the Service. We take no
              responsibility for any consequences arising from your data
              management practices.
            </li>
            <li>
              <b>Termination</b>
              We reserve the right to suspend or terminate your access to the
              Service if you violate these Terms or the Agreement. In the event
              of termination, your right to use the Service ceases immediately.
            </li>
            <li>
              <b>Limitation of Liability</b>
              Our liability is limited as specified in the Agreement. We are not
              liable for any indirect, incidental, or consequential damages
              arising from your use of the Service.
            </li>
            <li>
              <b>Amendments</b>
              We may update these Terms from time to time. Any changes will be
              posted, and your continued use of the Service constitutes
              acceptance of the revised Terms.
            </li>
            <li>
              <b>Governing Law</b>
              These Terms are governed by and construed in accordance with the
              laws specified in your Agreement.
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
li {
  text-align: start;
}
</style>
