<template>
  <img
    v-if="sorting.includes('+' + col)"
    src="../assets/icons/table/sort-asc.png"
  />
  <img
    v-if="sorting.includes('-' + col)"
    src="../assets/icons/table/sort-des.png"
  />
</template>
<script setup>
defineProps(["col", "sorting"]);
</script>
