import axios from "axios";

function useChannelsAPI() {
  const endpoint = import.meta.env.VITE_BIRDHOUSE_ENDPOINT;

  async function listChannels() {
    return (await axios.get(endpoint + "/software/channels")).data.channels;
  }

  function addChannel(data) {
    return axios.post(endpoint + "/software/channels", JSON.stringify(data), {
      headers: { "Content-Type": "application/json" },
    });
  }

  async function getChannel(channel_id) {
    return (await axios.get(endpoint + "/software/channels/" + channel_id)).data
      .channel;
  }

  function updateChannel(channel_id, data) {
    return axios.put(
      endpoint + "/software/channels/" + channel_id,
      JSON.stringify(data),
      { headers: { "Content-Type": "application/json" } }
    );
  }

  return {
    listChannels,
    addChannel,
    getChannel,
    updateChannel,
  };
}

export default useChannelsAPI;
