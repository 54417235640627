<template>
  <form>
    <div class="row fs-4 fw-bold m-0 mb-3">Patient Details</div>
    <div class="row m-0 mb-3">
      <div class="filterbox col-sm-6 col-md-3 col-xl-2">
        <label for="CataractSelect" class="form-label"
          >Cataract
          <i
            class="bi bi-info-circle ms-2"
            id="info-icon"
            data-toggle="tooltip"
            title="Selecting 'N/A' includes patients for whom previously 'No Cataract' was selected"
          ></i
        ></label>
        <select
          class="form-select w-100"
          id="CataractSelect"
          v-model="imageFilters.cataract"
        >
          <option value="">- any -</option>
          <option value="n-a">N/A</option>
          <option value="yes">Yes</option>
        </select>
      </div>
      <div class="filterbox col-sm-6 col-md-3 col-xl-2">
        <label for="DiabetesSelect" class="form-label">Diabetes</label>
        <select
          class="form-select w-100"
          id="DiabetesSelect"
          v-model="imageFilters.diabetes"
        >
          <option value="">- any -</option>
          <option value="none">No Diabetes</option>
          <option value="all">Diabetes (all)</option>
          <option value="less_than_1">Diabetes less than 1 year</option>
          <option value="between_1_and_5">
            Diabetes between 1 and 5 years
          </option>
          <option value="between_5_and_10">
            Diabetes between 5 and 10 year
          </option>
          <option value="more_than_10">Diabetes more than 10 years</option>
        </select>
      </div>
      <div class="filterbox col-sm-6 col-md-3 col-xl-2">
        <label for="minAge" class="form-label">Minimum Age</label>
        <input
          class="form-control"
          id="minAge"
          placeholder="Enter Minimum Age"
          v-model="imageFilters.minAge"
        />
      </div>
      <div class="filterbox col-sm-6 col-md-3 col-xl-2">
        <label for="maxAge" class="form-label">Maximum Age</label>
        <input
          class="form-control"
          id="maxAge"
          placeholder="Enter Maximum Age"
          v-model="imageFilters.maxAge"
        />
      </div>
      <div class="filterbox col-sm-6 col-md-3 col-xl-2">
        <label for="DRSelect" class="form-label">DR Grade</label>
        <select
          class="form-select w-100"
          id="DRSelect"
          v-model="imageFilters.drGrade"
        >
          <option value="">- any -</option>
          <option value="Grade 1">Grade 0: no apparent retinopathy</option>
          <option value=">=Grade 1">
            Grade 0: no apparent retinopathy and above
          </option>
          <option value="Grade 2">Grade 1: mild NPDR</option>
          <option value=">=Grade 2">Grade 1: mild NPDR and above</option>
          <option value="Grade 3">Grade 2: moderate NPDR</option>
          <option value=">=Grade 3">Grade 2: moderate NPDR and above</option>
          <option value="Grade 4">Grade 3: severe NPDR</option>
          <option value=">=Grade 4">Grade 3: severe NPDR and above</option>
          <option value="Grade 5">
            Grade 4: proliferative diabetic Retinopathy
          </option>
          <option value="Inconclusive">Inconclusive</option>
        </select>
      </div>
      <div class="filterbox col-sm-6 col-md-3 col-xl-2">
        <label for="clinicSelect" class="form-label">Clinic</label>
        <select
          class="form-select w-100"
          id="clinicSelect"
          v-model="imageFilters.locId"
        >
          <option value="">- any -</option>
          <option v-for="loc in locations" :key="loc.id" :value="loc.id">
            {{ loc.name }}
          </option>
        </select>
      </div>
      <div class="filterbox col-sm-6 col-md-3 col-xl-2">
        <label for="patientId" class="form-label">Patient Id</label>
        <input
          class="form-control"
          id="patientId"
          placeholder="Enter Patient Id"
          v-model="imageFilters.patientId"
        />
      </div>
      <div class="filterbox col-sm-6 col-md-3 col-xl-2">
        <label for="visitId" class="form-label">Visit Id</label>
        <input
          class="form-control"
          id="visitId"
          placeholder="Enter Visit Id"
          v-model="imageFilters.visitId"
        />
      </div>
    </div>
    <div class="row fs-4 fw-bold m-0 mb-3">Image Data</div>
    <div class="row m-0 mb-3">
      <div class="form-check col-sm-6 col-md-4 col-xl-3 ps-0">
        <div class="mb-2 form-label">
          <label for="pupilContraction" class="form-check-label"
            >Sudden pupil contraction
          </label>
          <i
            class="bi bi-info-circle ms-2"
            id="info-icon"
            data-toggle="tooltip"
            title="Filter on images with pupil size smaller at capture than alignment start. Use bar to set minimum size difference in %"
          ></i>
          <input
            type="checkbox"
            class="form-check-input ms-0 me-3"
            id="pupilContraction"
            v-model="isSuddenPupilContractionSelected"
          />
        </div>
        <div class="mb-2">
          <input
            v-model="imageFilters.suddenPupilContraction"
            type="range"
            min="0"
            max="1"
            step="0.01"
            class="slider w-50"
            v-bind:disabled="!isSuddenPupilContractionSelected"
          />
        </div>
        <input
          v-model="imageFilters.suddenPupilContraction"
          type="number"
          class="form-control w-50"
          v-bind:disabled="!isSuddenPupilContractionSelected"
        />
      </div>
      <div class="filterbox col-sm-6 col-md-3 col-xl-2">
        <label for="startDate" class="form-label">Captured on or after:</label>
        <input
          class="form-control"
          type="date"
          id="startDate"
          v-model="imageFilters.startDate"
        />
      </div>
      <div class="filterbox col-sm-6 col-md-3 col-xl-2">
        <label for="EndDate" class="form-label">Captured on or before:</label>
        <input
          class="form-control"
          type="date"
          id="endDate"
          v-model="imageFilters.endDate"
        />
      </div>
    </div>
    <div class="d-flex justify-content-center mb-2">
      <button
        type="submit"
        class="btn btn-primary"
        @click.prevent="filterParams(imageFilters)"
      >
        Search
      </button>
    </div>
  </form>
</template>
<script setup>
import { ref, onBeforeMount, reactive, watch } from "vue";
import backend from "@/backend";
import { validateAge, validateId } from "@/util/validation";
import { showPopupMessage } from "@/util/popup";
import { Tooltip } from "bootstrap";

const emit = defineEmits(["setImageFilters"]);
const props = defineProps(["modelValue"]);

new Tooltip(document.body, {
  selector: "[data-toggle='tooltip']",
});
const isSuddenPupilContractionSelected = ref(false);

const imageFilters = reactive({
  cataract: "",
  diabetes: "",
  minAge: "",
  maxAge: "",
  drGrade: "",
  locId: "",
  patientId: "",
  visitId: "",
  suddenPupilContraction: "",
  startDate: "",
  endDate: "",
});

const locations = ref([]);
async function loadLocations() {
  locations.value = await backend.locations.listLocations();
}

function filterParams(imageFilters) {
  if (imageFilters.patientId && !validateId(imageFilters.patientId)) {
    showPopupMessage("Please Enter Valid Patient Id");
  } else if (imageFilters.visitId && !validateId(imageFilters.visitId)) {
    showPopupMessage("Please Enter Valid Visit Id");
  } else if (
    (imageFilters.minAge && !validateAge(imageFilters.minAge)) ||
    (imageFilters.maxAge && !validateAge(imageFilters.maxAge))
  ) {
    showPopupMessage("Please Enter Valid Age");
  } else {
    imageFilters.offset = 0;
    emit("setImageFilters", imageFilters);
  }
}

watch(isSuddenPupilContractionSelected, () => {
  if (isSuddenPupilContractionSelected.value == true)
    imageFilters.suddenPupilContraction = "0.1";
  else imageFilters.suddenPupilContraction = "";
});

onBeforeMount(() => {
  for (const key in imageFilters) {
    imageFilters[key] = props.modelValue[key];
  }

  loadLocations();
});
</script>
<style scoped>
.form-label {
  font-size: max(12px, 1vw);
}
.filterbox {
  padding: 0.25rem;
  min-width: 7rem;
  text-align: left;
}
</style>
