import useOrganizationsAPI from "./useOrganizationsAPI";
import * as authAPI from "./useCognitoAPI";
import useChannelsAPI from "./useChannelsAPI";
import useDevicesAPI from "./useDevicesAPI";
import useImagesAPI from "./useImagesAPI";
import useLocationsAPI from "./useLocationsAPI";
import useLogsAPI from "./useLogsAPI";
import usePackagesAPI from "./usePackagesAPI";
import usePatientsAPI from "./usePatientsAPI";
import useSettingsAPI from "./useSettingsAPI";
import useUsersAPI from "./useUsersAPI";
import useVisitsAPI from "./useVisitsAPI";
import useContactAPI from "./useContactAPI";
import useStatisticsAPI from "./useStatisticsAPI";
import useAuditLogsAPI from "./useAuditLogsAPI";

export default {
  auth: authAPI,
  channels: useChannelsAPI(),
  devices: useDevicesAPI(),
  images: useImagesAPI(),
  locations: useLocationsAPI(),
  logs: useLogsAPI(),
  organizations: useOrganizationsAPI(),
  packages: usePackagesAPI(),
  patients: usePatientsAPI(),
  settings: useSettingsAPI(),
  users: useUsersAPI(),
  visits: useVisitsAPI(),
  contact: useContactAPI(),
  statistics: useStatisticsAPI(),
  audit: useAuditLogsAPI(),
};
