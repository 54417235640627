function useDrawing() {
  var icVars, icFuncs;

  function initUseDrawing(icVarsR, icFuncsR) {
    icVars = icVarsR;
    icFuncs = icFuncsR;
  }

  function getScaledXY(e, absolute = true) {
    const canvas = e.target;
    const canvasX = (e.offsetX * canvas.width) / canvas.offsetWidth;
    const canvasY = (e.offsetY * canvas.height) / canvas.offsetHeight;
    if (absolute) {
      return [
        canvasX / icVars.currentScale + icVars.currentOffsetX,
        canvasY / icVars.currentScale + icVars.currentOffsetY,
      ];
    }
    return [canvasX, canvasY];
  }

  function startDrawingHandler(e) {
    var [scaledX, scaledY] = getScaledXY(e);
    icVars.drawing = true;
    icVars.currentStroke = [];
    icVars.ic.strokeStyle = "#ffffff";
    icVars.currentStroke.push({ x: scaledX, y: scaledY });
    icVars.ic.beginPath();
    icVars.ic.moveTo(scaledX, scaledY);
  }

  async function stopDrawingHandler(e) {
    var [scaledX, scaledY] = getScaledXY(e);
    if (icVars.drawing) {
      icVars.drawing = false;
      let firstPoint = icVars.currentStroke[0];
      icVars.currentStroke.push({ x: firstPoint.x, y: firstPoint.y });
      icVars.ic.lineTo(firstPoint.x, firstPoint.y);
      icVars.ic.stroke();
      icVars.ic.closePath();
      if (icVars.currentStroke.length > 5) {
        return true;
      } else {
        let hoveredIndex = icFuncs.getHoveredOverIndex(scaledX, scaledY);
        if (hoveredIndex !== -1) {
          if (hoveredIndex !== icVars.currentSelectedIndex) {
            if (icVars.currentSelectedIndex !== -1) {
              delete icVars.strokes[icVars.currentSelectedIndex].selected;
            }
            icVars.strokes[hoveredIndex].selected = true;
            icVars.currentSelectedIndex = hoveredIndex;
            icVars.hoveredOverLabel = icVars.strokes[hoveredIndex].label;
            icFuncs.redrawCanvas();
            icVars.drawing = false;
          }
        } else {
          if (icVars.currentSelectedIndex !== -1) {
            delete icVars.strokes[icVars.currentSelectedIndex].selected;
            icFuncs.redrawCanvas();
          }
        }
      }
    }
    return false;
  }

  function drawingHandler(e) {
    var [scaledX, scaledY] = getScaledXY(e);
    if (icVars.drawing) {
      icVars.currentStroke.push({ x: scaledX, y: scaledY });
      icVars.ic.lineTo(scaledX, scaledY);
      icVars.ic.stroke();
      if (icVars.currentStroke.length > 1) {
        if (icVars.currentSelectedIndex !== -1) {
          delete icVars.strokes[icVars.currentSelectedIndex].selected;
          icVars.currentSelectedIndex = -1;
        }
        if (icVars.currentHoveredIndex !== -1) {
          delete icVars.strokes[icVars.currentHoveredIndex].hovered;
          icVars.currentHoveredIndex = -1;
        }
        icVars.ic.lineWidth = 1;
      }
    } else {
      let hoveredIndex = icFuncs.getHoveredOverIndex(scaledX, scaledY);
      if (hoveredIndex !== -1) {
        icVars.strokes[hoveredIndex].hovered = true;
        if (icVars.currentHoveredIndex !== hoveredIndex) {
          if (icVars.currentHoveredIndex !== -1)
            delete icVars.strokes[icVars.currentHoveredIndex].hovered;
          icVars.currentHoveredIndex = hoveredIndex;
          icVars.hoveredOverLabel = icVars.strokes[hoveredIndex].label;
          icFuncs.redrawCanvas();
        }
      } else {
        if (icVars.currentHoveredIndex !== -1) {
          delete icVars.strokes[icVars.currentHoveredIndex].hovered;
          icVars.currentHoveredIndex = -1;
          icVars.hoveredOverLabel = "";
          icFuncs.redrawCanvas();
        }
      }
    }
  }

  return {
    getScaledXY,
    initUseDrawing,
    startDrawingHandler,
    stopDrawingHandler,
    drawingHandler,
  };
}
export default useDrawing;
