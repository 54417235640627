<template>
  <ModalForm
    v-show="visible"
    @close="visible = false"
    title="Edit organization"
    footerButtonLabel="Save"
    :footerButtonAction="updateOrg"
    footerButtonClass="btn-lg btn-primary"
  >
    <div class="form-outline mb-4">
      <label class="form-label">Name:</label>
      <input
        type="text"
        class="form-control form-control-lg"
        placeholder="Organization name"
        v-model="formData.name"
      />
    </div>

    <div class="form-outline mb-4">
      <label class="form-label">Email:</label>
      <input
        type="text"
        class="form-control form-control-lg"
        placeholder="Email"
        v-model="formData.email"
      />
    </div>

    <div class="form-outline mb-4">
      <label class="form-label">Phone number:</label>
      <input
        type="text"
        class="form-control form-control-lg"
        placeholder="Phone number"
        v-model="formData.phone"
      />
    </div>

    <div class="form-outline mb-4">
      <label class="form-label">Address:</label>
      <input
        type="text"
        class="form-control form-control-lg"
        placeholder="Address"
        v-model="formData.address"
      />
    </div>

    <div class="form-outline mb-4" v-if="requiresSiteID">
      <label class="form-label"
        >Site Id:
        <i
          class="bi bi-info-circle ms-2"
          id="info-icon"
          data-toggle="tooltip"
          title="Site id must be added before sending images for grading"
        ></i>
      </label>
      <input
        type="text"
        class="form-control form-control-lg"
        placeholder="Site Id"
        v-model="formData.site_id"
      />
    </div>

    <div class="form-outline mb-4">
      <label class="form-label">Analyst organization:</label>
      <select class="form-select" v-model="formData.analyst_org_id">
        <option value="null" disabled>- select analyst organization -</option>
        <option v-for="org in orgs" :key="org.id" :value="org.id">
          {{ org.name }}
        </option>
      </select>
    </div>

    <div class="alert alert-danger" v-if="formError">
      {{ formError }}
    </div>
  </ModalForm>
</template>
<script setup>
import backend from "@/backend";
import ModalForm from "@/components/ModalForm.vue";
import { AppConfig } from "@/util/AppConfig";
import { validateEmail } from "@/util/validation";
import { Tooltip } from "bootstrap";
import { computed, ref } from "vue";

const emit = defineEmits(["dataChange"]);
const props = defineProps(["org"]);

const visible = ref(false);
const formData = ref({
  name: "",
  email: "",
  phone: "",
  address: "",
  analyst_org_id: "",
  site_id: "",
});
const formError = ref("");
const orgs = ref(null);
const requiresSiteID = computed(() => AppConfig.organizationNeedSiteID);

async function updateOrg() {
  if (!formData.value.name.trim()) {
    formError.value = "Required: organization name";
  } else if (formData.value.email && !validateEmail(formData.value.email)) {
    formError.value = "Invalid email address";
  } else if (!formData.value.analyst_org_id) {
    formError.value = "Required: analyst organization";
  } else if (AppConfig.organizationNeedSiteID && !formData.value.site_id) {
    formError.value = "Required: Site Id";
  } else {
    await backend.organizations.updateOrganization(
      props.org.id,
      formData.value
    );
    visible.value = false;
    emit("dataChange");
  }
}

new Tooltip(document.body, { selector: "[data-toggle='tooltip']" });

async function show() {
  formError.value = "";
  orgs.value = await backend.organizations.listOrganizations();
  for (var key in formData.value) formData.value[key] = props.org[key];
  visible.value = true;
}

defineExpose({ show });
</script>
