<template>
  <ModalForm v-show="visible" @close="onNoPressed" :title="title">
    <slot />

    <div v-if="yesSafetyInput">
      <p>
        Type <strong>{{ yesSafetyInput.toUpperCase() }}</strong> to confirm.
      </p>

      <input
        type="text"
        v-model="safetInputValue"
        @input="(val) => (safetInputValue = safetInputValue.toUpperCase())"
      />
    </div>

    <div class="mt-5 text-center">
      <button class="btn btn-secondary" @click="onNoPressed">
        {{ noLabel ?? "Cancel" }}
      </button>
      <button
        class="btn ms-2"
        :class="yesCustomStyle"
        @click="onYesPressed"
        :disabled="
          yesSafetyInput &&
          yesSafetyInput.toUpperCase() !== safetInputValue.toUpperCase()
        "
      >
        {{ yesLabel }}
      </button>
    </div>
  </ModalForm>
</template>
<script setup>
import { ref } from "vue";
import ModalForm from "@/components/ModalForm.vue";

const props = defineProps([
  "title",
  "yesLabel",
  "noLabel",
  "onYes",
  "yesCustomStyle",
  "makeLarge",
  "yesSafetyInput",
  "onNo",
]);

const safetInputValue = ref("");

const visible = ref(false);

async function show() {
  visible.value = true;
}

async function onNoPressed() {
  visible.value = false;
  if (props.onNo) props.onNo();
}

async function onYesPressed() {
  visible.value = false;
  if (props.onYes) props.onYes();
}
defineExpose({ show });
</script>
