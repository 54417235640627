<template>
  <div class="d-flex flex-column">
    <div class="fw-medium-bold w-100 mb-2">
      {{ props.groupLabel }}
    </div>
    <div class="d-flex flex-column flex-wrap">
      <select
        class="form-select select-input"
        @input="$emit('update:modelValue', $event.target.value)"
      >
        <option disabled selected v-if="props.placeholder">
          {{ props.placeholder }}
        </option>
        <option
          v-for="option in props.options"
          :key="option.value"
          :value="option.value"
          :name="props.selectName"
          :id="option.value + 'SelectOption'"
          :selected="props.modelValue === option.value"
        >
          {{ option.label }}
        </option>
      </select>
    </div>
  </div>
</template>

<script setup>
const props = defineProps([
  "modelValue",
  "groupLabel",
  "selectName",
  "options",
  "tooltipContent",
  "placeholder",
]);
</script>
<style lang="scss">
@import "@/style/style.scss";
</style>
