import axios from "axios";

function useStatisticsAPI() {
  async function getStatisticsOverview(
    organization_id = null,
    location_id = null,
    start_date = null,
    end_date = null,
    monthly = null
  ) {
    return (
      await axios.get(
        import.meta.env.VITE_BIRDHOUSE_ENDPOINT + "/statistics/overview",
        {
          params: {
            organization_id,
            location_id,
            start_date,
            end_date,
            monthly,
          },
        }
      )
    ).data.statistics;
  }

  return {
    getStatisticsOverview,
  };
}

export default useStatisticsAPI;
