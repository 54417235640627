<template>
  <h5 class="border-bottom">Patient details</h5>
  <dl class="row lh-sm mb-4">
    <dt class="col-6">Age:</dt>
    <dd class="col-6 text-end">{{ visit.age }}</dd>

    <dt class="col-6">Gender:</dt>
    <dd class="col-6 text-end">{{ visit.gender }}</dd>

    <dt class="col-6">Diabetes:</dt>
    <dd class="col-6 text-end">
      {{ formatYearsMonthsOnset(visit.diabetes_onset) }}
    </dd>

    <dt class="col-6">Hypertension:</dt>
    <dd class="col-6 text-end">
      {{ formatYearsMonthsOnset(visit.hypertension_onset) }}
    </dd>

    <dt class="col-6">Cataract (OD):</dt>
    <dd class="col-6 text-end">{{ visit.cataract_od }}</dd>

    <dt class="col-6">Cataract (OS):</dt>
    <dd class="col-6 text-end">{{ visit.cataract_os }}</dd>

    <dt class="col-12">Patient notes:</dt>
    <dd class="col-12 mt-1 note">{{ visit.notes }}</dd>

    <dd class="col-12 text-end">
      <a href="#">View all visits for this patient</a>
    </dd>
  </dl>
</template>
<script setup>
import { formatYearsMonthsOnset } from "@/util/formatting";

defineProps(["visit"]);
</script>
