<template>
  <SplashCard @onEnter="handleOnEnter">
    <h2 v-show="!isNewPasswordSet" class="text-uppercase text-center mb-5">
      Forgot Password
    </h2>

    <form v-show="!isNewPasswordSet">
      <div class="form-outline mb-4">
        <input
          type="email"
          class="form-control form-control-lg"
          :class="[
            isEmailValid
              ? 'border-success'
              : email === ''
                ? ''
                : 'border-danger',
          ]"
          placeholder="Email"
          v-model="email"
          :disabled="isOtpSent"
        />
      </div>
      <div class="alert alert-danger" v-if="sendConfirmationCodeError">
        {{ sendConfirmationCodeError }}
      </div>

      <div class="d-flex justify-content-center" v-show="!isOtpSent">
        <button
          v-show="!isOtpSent"
          type="button"
          class="btn btn-primary btn-lg px-5"
          @click="sendConfirmationCode"
          :disabled="isOptBeingSent"
        >
          <div
            class="spinner-border spinner-border-sm"
            role="status"
            v-if="isOptBeingSent"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
          <span v-if="!isOptBeingSent">Send code</span>
        </button>
      </div>

      <div class="form-outline mb-4" v-show="isOtpSent">
        <input
          type="text"
          maxlength="6"
          class="form-control form-control-lg"
          :class="[
            isOtpValid ? 'border-success' : otp === '' ? '' : 'border-danger',
          ]"
          placeholder="Enter OTP Sent"
          v-model="otp"
        />
      </div>

      <div class="form-outline mb-4" v-show="isOtpSent">
        <div id="hoverText">
          <img
            title="Password should contain atleast 1 lowercase ,1 uppercase alphabet, 1 number and should be atleast 8 characters long."
            src="https://img.icons8.com/material-outlined/24/000000/info.png"
          />
        </div>
        <input
          :type="[isPasswordVisible ? 'text' : 'password']"
          id="form3Example4cg"
          class="form-control form-control-lg"
          :class="[
            isPasswordValid
              ? 'border-success'
              : password === ''
                ? ''
                : 'border-danger',
          ]"
          placeholder="Enter New Password"
          v-model="password"
        />
        <div class="mt-1 text-start">
          <input
            type="checkbox"
            class="btn"
            v-model="isPasswordVisible"
            name="Show Password"
          />
          Show Password
        </div>
      </div>

      <div class="form-outline mb-4" v-show="isOtpSent">
        <input
          :type="[isPasswordVisible ? 'text' : 'password']"
          id="form3Example4cdg"
          class="form-control form-control-lg"
          :class="[
            password === confirmPassword && isPasswordValid === true
              ? 'border-success'
              : confirmPassword === ''
                ? ''
                : 'border-danger',
          ]"
          placeholder="Confirm New Password"
          v-model="confirmPassword"
        />
      </div>

      <div class="d-flex justify-content-center">
        <button
          v-show="isOtpSent"
          type="button"
          class="btn btn-primary btn-lg px-5"
          @click="setNewPassword"
          :disabled="isNewPasswordBeingSet"
        >
          <div
            class="spinner-border spinner-border-sm"
            role="status"
            v-if="isNewPasswordBeingSet"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
          <span v-if="!isNewPasswordBeingSet">Set new password</span>
        </button>
      </div>
    </form>
    <div class="text-center" v-show="isNewPasswordSet">
      <p>New password is set for {{ email }}</p>
      <br />
      <p class="link" @click="gotoLoginPage">Click here to go to Login Page</p>
    </div>
  </SplashCard>
</template>

<script setup>
import { ref, watch } from "vue";
import { useRouter } from "vue-router";
import backend from "@/backend";
import { showPopupMessage } from "@/util/popup";
import { validateEmail, validatePassword } from "@/util/validation";
import SplashCard from "@/components/SplashCard.vue";

const router = useRouter();

const isNewPasswordSet = ref(false);
const isOtpSent = ref(false);
const isOptBeingSent = ref(false);
const sendConfirmationCodeError = ref("");
const isOtpValid = ref(false);
const isEmailValid = ref(false);
const isPasswordValid = ref(false);
const isPasswordVisible = ref(false);
const isNewPasswordBeingSet = ref(false);

const email = ref("");
const otp = ref("");
const password = ref("");
const confirmPassword = ref("");

watch(
  () => email.value,
  function (newValue) {
    isEmailValid.value = validateEmail(newValue);
  }
);
watch(
  () => otp.value,
  function (newValue) {
    isOtpValid.value = newValue.toString().length === 6;
  }
);
watch(
  () => password.value,
  function (newValue) {
    isPasswordValid.value = validatePassword(newValue);
  }
);

async function sendConfirmationCode() {
  if (!isEmailValid.value) {
    showPopupMessage("Invalid Email");
    return;
  }
  sendConfirmationCodeError.value = "";
  isOptBeingSent.value = true;
  try {
    await backend.auth.forgotPassword(email.value);
    isOtpSent.value = true;
  } catch (error) {
    console.error(error);
    sendConfirmationCodeError.value = error.message;
  }
  isOptBeingSent.value = false;
}

async function setNewPassword() {
  isNewPasswordBeingSet.value = true;
  var errorMessage = "";
  if (!isEmailValid.value) {
    errorMessage += "Invalid email\n";
  }
  if (!isOtpValid.value) {
    errorMessage += "Invalid OTP\n";
  }
  if (!isPasswordValid.value) {
    errorMessage += "Invalid password \n";
  }
  if (confirmPassword.value !== password.value) {
    errorMessage += "Your password and confirmation password do not match.\n";
  }

  if (errorMessage.length > 0) {
    showPopupMessage(errorMessage);
    isNewPasswordBeingSet.value = false;
    return;
  }

  await backend.auth.forgotPasswordSubmit(
    email.value,
    otp.value.toString(),
    password.value
  );
  isNewPasswordBeingSet.value = false;
  isNewPasswordSet.value = true;
}

function gotoLoginPage() {
  router.push("/sign-in");
}

const handleOnEnter = () => {
  if (isOptBeingSent.value || isNewPasswordBeingSet.value) return;
  if (isOtpSent.value) return setNewPassword();
  return sendConfirmationCode();
};
</script>

<style scoped>
.link {
  cursor: pointer;
  color: green;
}

#hoverText {
  position: absolute;
  right: 60px;
  padding-top: 8px;
}
</style>
