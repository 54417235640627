import { defineStore } from "pinia";

const useDeviceStore = defineStore("devices", {
  state: () => ({}),
  actions: {
    updateSort(payload) {
      this.sortBy = payload;
    },
  },
});

export default useDeviceStore;
