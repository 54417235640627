<template>
  <div class="body">
    <h2 class="mb-4">Patient file imports by users</h2>
    <table class="table table-striped table-hover" id="id-table-patients">
      <thead>
        <tr>
          <th>Organization</th>
          <th>Uploaded by</th>
          <th>Uploaded at</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="p in items" :key="p.organization_id">
          <td>
            <router-link
              :to="`/organizations/${p.organization_id}`"
              @click.stop
              >{{ p.organization_name }}</router-link
            >
          </td>
          <td>
            <router-link :to="`/user/${p.user_id}`" @click.stop>{{
              p.user_name
            }}</router-link>
          </td>
          <td>
            {{ getPrettyDate(p.created) }}
          </td>
        </tr>
      </tbody>
    </table>
    <PageSelect
      :totalEntries="totalEntries"
      :limitPerPage="tableAttributes.limitPerPage"
      :offsetValue="tableAttributes.offsetValue"
      @newOffsetValue="newOffsetValue"
      @newLimitPerPage="newLimitPerPage"
    />
  </div>
</template>

<script setup>
import backend from "@/backend";
import PageSelect from "@/components/PageSelect.vue";
import { onBeforeMount, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

const router = useRouter();
const items = ref([]);
const store = useStore();

const totalEntries = ref(0);
const tableAttributes = reactive({
  limitPerPage: null,
  offsetValue: null,
  sortBy: null,
});

async function getListData() {
  const result = await backend.patients.getPatientImportsData(
    tableAttributes.limitPerPage,
    tableAttributes.offsetValue,
    tableAttributes.sortBy
  );
  store.commit(
    "SET_PATIENTS_LIST_LIMIT",
    parseInt(tableAttributes.limitPerPage)
  );
  store.commit(
    "SET_PATIENTS_LIST_OFFSET",
    parseInt(tableAttributes.offsetValue)
  );
  store.commit("SET_PATIENTS_LIST_SORT_BY", tableAttributes.sortBy);

  items.value = result.items;
  totalEntries.value = result.total_count;
}

function setTableFilters(imageFilters) {
  for (const [key, value] of Object.entries(imageFilters)) {
    if (key in tableAttributes) {
      tableAttributes[key] = ["limitPerPage", "offsetValue"].includes(key)
        ? parseInt(value)
        : value;
    }
  }
}

watch(tableAttributes, () => {
  const query = {};
  for (const [key, value] of Object.entries(tableAttributes)) {
    if (value) query[key] = value;
  }
  router.push({ name: "Patient imports", query: query });
  getListData();
});

onBeforeMount(() => {
  const url = new URL(window.location);
  setTableFilters(Object.fromEntries(url.searchParams));
  tableAttributes.limitPerPage = tableAttributes?.limitPerPage ?? getLimit();
  tableAttributes.offsetValue = tableAttributes?.offsetValue ?? getOffset();
  tableAttributes.sortBy = tableAttributes?.sortBy ?? getSort();
  getListData();
});

function getLimit() {
  const limit = store.state?.patientsListLimit ?? 20;
  return parseInt(limit);
}

function getOffset() {
  const offset = store.state?.patientsListOffset ?? 0;
  return parseInt(offset);
}

function getSort() {
  const sort = store.state?.patientsListSortBy ?? "-id";
  return sort;
}

function newLimitPerPage(limit) {
  store.commit("SET_PATIENTS_LIST_LIMIT", parseInt(limit));
  tableAttributes.limitPerPage = limit;
}
function newOffsetValue(newOffsetVal) {
  store.commit("SET_PATIENTS_LIST_OFFSET", parseInt(newOffsetVal));
  tableAttributes.offsetValue = newOffsetVal;
}

function getPrettyDate(dateStr = "") {
  const date = new Date(dateStr);
  return date.toLocaleString();
}
</script>
