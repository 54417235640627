<template>
  <ModalForm
    v-show="visible"
    @close="visible = false"
    title="Edit software channel"
  >
    <div class="form-outline mb-4">
      <input
        type="text"
        class="form-control form-control-lg"
        placeholder="Organization name"
        v-model="formData.name"
      />
    </div>

    <div class="form-outline mb-4">
      <select
        class="form-control form-control-lg"
        v-model="formData.sw_package_id"
      >
        <option :value="null">No software version selected</option>
        <option v-for="p in packages" :value="p.id" :key="p.id">
          {{ p.version }}
        </option>
      </select>
    </div>

    <div class="text-center mt-5">
      <button class="btn btn-lg btn-primary" @click="saveChannel">Save</button>
    </div>
  </ModalForm>
</template>
<script setup>
import { ref, watch, onMounted } from "vue";
import backend from "@/backend";
import ModalForm from "@/components/ModalForm.vue";

const emit = defineEmits(["dataChange"]);
const props = defineProps(["channel"]);

const visible = ref(false);
const formData = ref({ name: "", channel_id: "", sw_package_id: "" });
const formError = ref("");

watch(visible, async (newValue) => {
  if (newValue) {
    for (var key in formData.value) formData.value[key] = props.channel[key];
    formError.value = "";
  }
});

async function saveChannel() {
  await backend.channels.updateChannel(props.channel.id, formData.value);
  visible.value = false;
  emit("dataChange");
}

const packages = ref([]);
async function loadData() {
  packages.value = await backend.packages.listPackages();
}

onMounted(async function () {
  loadData();
});

function show() {
  visible.value = true;
}

defineExpose({ show });
</script>
