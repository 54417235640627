import axios from "axios";

export default function useContactAPI() {
  async function sendContactMessage(data) {
    return axios.post(
      import.meta.env.VITE_BIRDHOUSE_ENDPOINT + "/contact",
      JSON.stringify(data),
      { headers: { "Content-Type": "application/json" } }
    );
  }
  return { sendContactMessage };
}
