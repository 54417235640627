<template>
  <div class="modal-backdrop-custom" @mousedown="close">
    <div
      class="modal-container rounded"
      :style="makeLarge && largeModalDimensions"
      @mousedown.stop=""
    >
      <button
        type="button"
        class="btn-close p-0"
        @click="close"
        aria-label="Close modal"
      />
      <div class="py-3 w-75 mx-auto">
        <h2 class="fw-bold text-uppercase text-center bg-white">
          {{ title }}
        </h2>
      </div>
      <div
        class="modal-content-container overflow-scroll"
        :class="{
          'modal-with-footer': footerButtonLabel,
          'large-modal': makeLarge,
        }"
      >
        <slot />
      </div>
      <div
        v-if="footerButtonLabel"
        class="sticky-bottom d-flex justify-content-center bg-white p-2 rounded"
      >
        <button
          class="btn btn-secondary"
          :class="footerButtonClass"
          :id="footerButtonId"
          @click="footerButtonAction"
        >
          {{ footerButtonLabel }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref } from "vue";

defineProps([
  "title",
  "footerButtonLabel",
  "footerButtonAction",
  "footerButtonClass",
  "footerButtonId",
  "makeLarge",
]);

defineExpose({ close });
const emit = defineEmits(["close"]);

function close() {
  emit("close");
}

// found inspiration here: https://stackoverflow.com/a/63944126
function useBreakpoints() {
  let largeModalDimensions = ref(getLargeModalDimensions());
  const onResize = () => {
    largeModalDimensions.value = getLargeModalDimensions();
  };
  onMounted(() => window.addEventListener("resize", onResize));
  onUnmounted(() => window.removeEventListener("resize", onResize));
  return { largeModalDimensions };
}

const { largeModalDimensions } = useBreakpoints();

function getLargeModalDimensions() {
  if (window.innerHeight > window.innerWidth) {
    return { width: "80%" };
  } else {
    const percentage = (window.innerHeight / window.innerWidth) * 100;
    return { width: `${percentage}%` };
  }
}
const onEscKeyDown = (e) => {
  if (e.key == "Escape") {
    close();
  }
};

onMounted(() => {
  document.addEventListener("keydown", onEscKeyDown);
});

onUnmounted(() => document.removeEventListener("keydown", onEscKeyDown));
</script>

<style lang="scss">
.modal-backdrop-custom {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);

  /* needs to be more than 1030 (bootstrap fixed z-index) 
  but under 1040 (bootstrap modal backdrop z-index)
  See: https://getbootstrap.com/docs/5.0/layout/z-index/ */

  z-index: 1031 !important;
}

.modal-container {
  background-color: white;
  position: absolute;
}

.modal-content-container {
  max-height: 85vh;
  min-width: 25vw;
  max-width: 60vw;
  overflow: scroll;
  padding: 1rem 3rem;

  &.large-modal {
    max-width: 100%;
    max-height: 100%;
  }
}

.modal-with-footer {
  max-height: 75vh;
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}
</style>
