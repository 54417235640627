<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-primary sticky-top">
    <div class="container-fluid">
      <img
        class="oivi-brand-logo"
        src="../assets/images/oivi_logo_white.png"
        @click="$router.push('/about')"
      />
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div id="navbarContent" :key="navKey" class="collapse navbar-collapse">
        <ul class="navbar-nav d-flex align-items-center ms-auto mb-2 mb-lg-0">
          <li>
            <img
              class="oivi-region-flag"
              :src="regionImages[AppConfig.region]"
            />
          </li>
          <li v-if="isDevEnv" class="oivi-env oivi-env--dev">
            <div>Developer</div>
          </li>
          <li v-if="isTestEnv" class="oivi-env oivi-env--test">
            <div>Testing</div>
          </li>
          <template v-for="route in routesInNavFormat()" :key="route.name">
            <li
              v-if="routeVisible(route)"
              class="nav-item"
              :class="{ dropdown: route.isDropDown }"
            >
              <a
                class="nav-link"
                :class="{
                  'dropdown-toggle': route.isDropDown,
                  'nav-icon': route.meta?.icon,
                  active: isActiveNavLink(route, router.currentRoute.value),
                }"
                :href="route.path"
                :data-bs-toggle="route.isDropDown ? 'dropdown' : 'collapse'"
                :data-bs-target="
                  route.isDropDown ? '' : '.navbar-collapse.show'
                "
                :title="route.name"
                @click="$router.push(route.path)"
              >
                {{ route.meta?.icon ? "" : route.name }}
                <i
                  v-if="route.meta?.icon"
                  :class="'bi ' + route.meta?.icon"
                ></i>
                <ul
                  v-if="route.isDropDown"
                  class="dropdown-menu"
                  aria-labelledby="navbarDropdown"
                >
                  <template
                    v-for="dropDownItem in route.dropDownItems"
                    :key="dropDownItem.name"
                  >
                    <li v-if="routeVisible(dropDownItem)">
                      <a
                        class="dropdown-item"
                        :href="dropDownItem.path"
                        @click="$router.push(dropDownItem.path)"
                        >{{ dropDownItem.name }}</a
                      >
                    </li>
                  </template>
                </ul>
              </a>
            </li>
          </template>
          <li class="nav-item">
            <a class="nav-link nav-icon" title="Sign Out" @click="signout"
              ><i class="bi bi-box-arrow-right"></i
            ></a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script setup>
import { ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { routes } from "@/router";
import { AppConfig } from "@/util/AppConfig";

const router = useRouter();
const store = useStore();
const navKey = ref(1);
const user = store.getters.currentUser;

const isTestEnv = AppConfig.deploymentEnv === "test";
const isDevEnv = AppConfig.deploymentEnv === "dev";

const regionImages = {
  us: "/flag-us.png",
  in: "/flag-in.png",
  vn: "/flag-vn.png",
};

async function signout() {
  router.push({ name: "SignOut" });
}

const getDropDownItems = (navBarGroup, routes) => {
  return routes.filter(
    (r) => r.meta?.isNavDropDown && r.meta?.navBarGroup === navBarGroup
  );
};

const createDropDownItem = (navBarGroup, dropDownItems) => {
  return {
    name: navBarGroup,
    path: "",
    isDropDown: true,
    meta: {
      navBarGroup,
    },
    dropDownItems,
  };
};

const routesInNavFormat = () => {
  return routes.reduce((accumulator, route) => {
    if (route.meta?.isNavDropDown) {
      const navBarGroup = route.meta.navBarGroup;
      if (!accumulator.find(({ name }) => name === navBarGroup)) {
        const dropDownItems = getDropDownItems(navBarGroup, routes);
        const navDropDown = createDropDownItem(navBarGroup, dropDownItems);
        return [...accumulator, navDropDown];
      }
    } else if (route.meta?.inNavBar) {
      return [...accumulator, route];
    }
    return accumulator;
  }, []);
};

function routeVisible(route) {
  if (!route.meta?.privilege && !route.isDropDown) return true;
  if (!user || !user.actions) return false;
  if (user.actions.includes("ANY_ACTION")) return true;
  if (route.isDropDown && route.dropDownItems.some(routeVisible)) return true;
  if (
    []
      .concat(route.meta?.privilege)
      .every((privilege) => user.actions.includes(privilege))
  )
    return true;
  return false;
}

function isActiveNavLink(route, currentRoute) {
  return route.meta?.navBarGroup === currentRoute.meta?.navBarGroup;
}

watch(
  () => store.getters.currentUser,
  () => {
    navKey.value += 1;
  }
);
</script>
<style lang="scss" scoped>
@import "@/style/style.scss";

.oivi-brand-logo {
  width: 5rem;
  padding-left: 0.2rem;
  margin-left: 0.5rem;
}
.nav-item {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  text-align: center;
}
.nav-link {
  color: white;
  cursor: pointer;
  padding: 0 0 0.1rem 0 !important;
  margin: 0 0.2rem;
  border-bottom: 2px solid $primary;

  &.active,
  &:hover {
    color: white;
    border-bottom-color: $oivi-light-green;
  }
}
.navbar-nav > li {
  border-right: 1px solid #333;
}
.navbar-nav > li:last-child {
  border: none;
}

/* needed to silence console warning, see:
https://stackoverflow.com/questions/64141163/popper-css-margin-styles-cannot-be-used-to-apply-padding-between-the-popper-a */
.dropdown-menu {
  margin: 0 !important;
}
.nav-link.nav-icon {
  padding: 0.2rem !important;
  i {
    font-size: 1.4rem;
    padding: 0.2rem !important;
  }
}

.oivi-brand-logo {
  &:hover {
    cursor: pointer;
  }
}

.oivi-region-flag {
  width: 24px;
}

.oivi-env {
  border-radius: 8px;
  border-radius: 24px;
  padding: 6px 12px;
  margin: 0px 4px;
  font-weight: bold;

  &--dev {
    background-color: #e0e9ff;
  }

  &--test {
    background-color: #ffddfb;
  }
}
</style>
