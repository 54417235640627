<template>
  <div class="body container-fluid">
    <div class="d-flex mb-3">
      <h2>Organizations</h2>
      <div class="ms-auto">
        <button class="btn btn-oivi-light" @click="addOrgModal.show()">
          Add organization
        </button>
      </div>
    </div>

    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Phone</th>
          <th>Address</th>
          <th>Analyst</th>
        </tr>
      </thead>
      <tbody>
        <tr
          role="button"
          v-for="org in orgs"
          :key="org.id"
          @click="router.push('/organizations/' + org.id)"
        >
          <td>{{ org.name }}</td>
          <td>{{ org.email }}</td>
          <td>{{ org.phone }}</td>
          <td>{{ org.address }}</td>
          <td>
            <router-link :to="'/organizations/' + org.analyst_org_id">
              {{ org.analyst_org_name }}
            </router-link>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <AddOrgModal ref="addOrgModal" @dataChange="loadData" />
</template>
<script setup>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import backend from "@/backend";
import AddOrgModal from "./components/AddOrgModal.vue";

const router = useRouter();

const addOrgModal = ref(null);
const orgs = ref([]);

async function loadData() {
  orgs.value = await backend.organizations.listOrganizations();
}

onMounted(async function () {
  loadData();
});
</script>
