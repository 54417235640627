<template>
  <div class="body container-fluid">
    <div class="d-flex mb-3">
      <h2>Software channels</h2>
      <div class="ms-auto">
        <button class="btn btn-oivi-light" @click="addChannelModal.show()">
          Add software channel
        </button>
      </div>
    </div>
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th>Channel name</th>
          <th>Software version</th>
        </tr>
      </thead>
      <tbody>
        <tr
          role="button"
          v-for="channel in channels"
          :key="channel.name"
          @click="router.push('/channels/' + channel.id)"
        >
          <td>{{ channel.name }}</td>
          <td>
            <router-link :to="'/packages/' + channel.sw_package_id" @click.stop>
              {{ channel.software_version }}
            </router-link>
          </td>
        </tr>
      </tbody>
    </table>
    <AddChannelModal ref="addChannelModal" @dataChange="loadData" />
  </div>
</template>
<script setup>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import backend from "@/backend";
import AddChannelModal from "./components/AddChannelModal.vue";

const router = useRouter();
const addChannelModal = ref(null);
const channels = ref([]);

async function loadData() {
  channels.value = await backend.channels.listChannels();
}

onMounted(async function () {
  loadData();
});
</script>
