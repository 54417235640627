<template>
  <div
    class="add-patient-page-container mx-auto mb-2 bg-white p-4 border rounded"
    id="patient-form-container"
  >
    <Form @submit="handleFormSubmit">
      <div class="fw-bold form-title">
        {{ isNewPatient ? "New Patient" : "Edit Patient" }}
      </div>
      <div class="row mb-sm-0">
        <div class="col-sm-2 mb-sm-0">
          <label for="prefixDropdown" class="form-label fw-medium-bold"
            >Prefix</label
          >
          <select
            v-model="patientDetails.prefix"
            id="prefixDropdown"
            class="form-select"
          >
            <option value="Mr">Mr</option>
            <option value="Ms">Ms</option>
            <option value="Mrs">Mrs</option>
            <option value="Dr">Dr</option>
          </select>
        </div>

        <div class="col-sm-10 mb-sm-0">
          <TextInput
            v-model="patientDetails.first_name"
            inputLabel="First Name *"
            inputName="patientFirstNameInput"
            :validator="validateFirstName"
            required
            placeholder="Patient First Name"
            tooltipContent="Enter legal name as listed in your insurance or medical records."
          />
        </div>

        <div class="row mb-sm-0 w-100 m-0 p-0 mt-3">
          <div class="col-sm-4 mb-sm-0">
            <TextInput
              v-model="patientDetails.middle_name"
              :validator="validateMiddleName"
              inputLabel="Middle Initial"
              inputName="patientMiddleNameInput"
            />
          </div>
          <div class="col-sm-4 mb-sm-0">
            <TextInput
              v-model="patientDetails.last_name"
              inputLabel="Last Name *"
              inputName="patientLastNameInput"
              :validator="validateLastName"
              placeholder="Patient Last Name"
              required
              tooltipContent="Enter legal name as listed in your insurance or medical records."
            />
          </div>
          <div class="col-sm-4 mb-sm-0">
            <label for="suffixDropdown" class="form-label fw-medium-bold"
              >Suffix</label
            >
            <select
              v-model="patientDetails.suffix"
              id="suffixDropdown"
              class="form-select"
            >
              <option value="Jr">Jr</option>
              <option value="Sr">Sr</option>
              <option value="III">III</option>
              <option value="IV">IV</option>
            </select>
          </div>
        </div>

        <div class="col-sm-12 mb-sm-0 mt-3">
          <TextInput
            v-model="patientDetails.mrn"
            inputLabel="MRN"
            inputName="patienMedicalRecordInput"
            :validator="validateMRN"
            @handleOnBlurAction="checkIfPatientMrnExists"
            placeholder="Medical record number"
            tooltipContent="Cross reference to other patient journal systems"
          />
        </div>
      </div>

      <div class="row mb-sm-0 mt-3">
        <div class="col-sm-6 mb-sm-0">
          <PhoneInput
            v-model="patientDetails.phone"
            inputLabel="Phone *"
            inputName="patientPhoneInput"
            :validator="validatePhoneInput"
            required
            @handleOnBlurAction="checkIfPatientPhoneExists"
          />
        </div>
        <div class="col-sm-6 mb-sm-0">
          <TextInput
            v-model="patientDetails.email"
            inputLabel="Email"
            inputName="patientEmailInput"
            :validator="validateEmailInput"
            placeholder="Patient Email"
            required
            @handleOnBlurAction="checkIfPatientEmailExists"
          />
        </div>
      </div>

      <div class="row mb-sm-0 mt-3">
        <div class="col-sm-6 mb-sm-0">
          <DateInput
            v-model="patientDetails.birthday"
            :max="getTodayDate()"
            inputLabel="DOB *"
            inputName="dob"
            :validator="validateDOB"
            placeholder="yyyy-mm-dd"
          />
        </div>
        <div class="col-sm-6 mb-sm-0">
          <TextInput
            v-model="patientDetails.ssn"
            inputLabel="Last 4 of SSN"
            inputName="patientssn"
            :validator="validateSSN"
            placeholder="SSN"
            required
            @handleOnBlurAction="checkIfPatientEmailExists"
            tooltipContent="This is only used to match to medical records."
          />
        </div>
      </div>

      <div class="col-md-9 col-6 d-sm-flex mt-3">
        <RadioInput
          tooltipContent="Enter sex as shown on a government-issued ID, such as drivers license."
          v-model="patientDetails.legal_sex"
          inputLabel="Legal Sex *"
          inputName="patientLegalsexInput"
          :validator="validateGenderInput"
          required
          :options="[
            { label: 'Female', value: 'F' },
            { label: 'Male', value: 'M' },
            { label: 'Other', value: 'O' },
          ]"
        />
      </div>

      <div class="col-md-9 col-6 d-sm-flex mt-3">
        <RadioInput
          v-model="patientDetails.assigned_sex"
          inputLabel="Sex Assigned at Birth"
          inputName="patientsexAssignedInput"
          :validator="() => true"
          :options="[
            { label: 'Female', value: 'F' },
            { label: 'Male', value: 'M' },
            { label: 'Intersex', value: 'I' },
          ]"
        />
      </div>

      <div class="mb-4 mt-3">
        <div class="fw-medium-bold mb-2">Additional Patient Notes</div>
        <TextAreaInput
          v-model="patientDetails.additionalInput"
          textAreaName="additionalInput"
          placeholder="Ex: Mention of any other diagnosis"
          rows="3"
        />
      </div>
      <div class="d-grid">
        <button
          type="submit"
          class="btn btn-oivi-light"
          id="submit-patient-form-button"
        >
          {{ isNewPatient ? "Continue" : "Save" }}
        </button>
      </div>
    </Form>
  </div>
</template>

<script setup>
import backend from "@/backend";
import { Form, defineRule } from "vee-validate";
import { reactive, ref } from "vue";

import { validateEmail, validateName } from "@/util/validation";
import DateInput from "../../../components/Forms/DateInput.vue";
import PhoneInput from "../../../components/Forms/PhoneInput.vue";
import RadioInput from "../../../components/Forms/RadioInput.vue";
import TextAreaInput from "../../../components/Forms/TextAreaInput.vue";
import TextInput from "../../../components/Forms/TextInput.vue";
import { yearsFromDate } from "../../../util/formatting";

import { useRouter } from "vue-router";

const props = defineProps(["patientInfo"]);
const emit = defineEmits(["finishEditing", "showExistingPatientsModal"]);

const router = useRouter();

const isNewPatient = props.patientInfo === undefined;

const newPatientDetails = {
  name: "",
  first_name: "",
  middle_name: "",
  last_name: "",
  prefix: "",
  suffix: "",
  phone: "",
  email: "",
  birthday: null,
  legal_sex: null,
  assigned_sex: null,
  ssn: "",
  mrn: "",
  additionalInput: "",
};

const patientDetails = isNewPatient
  ? reactive({ ...newPatientDetails })
  : reactive(convertToFrontendFormat({ ...props.patientInfo }));

function convertToFrontendFormat(data) {
  const convertedData = {
    prefix: data.prefix,
    suffix: data.suffix,
    name: data.name,
    first_name: data.first_name,
    middle_name: data.middle_name,
    last_name: data.last_name,
    email: data.email,
    phone: data.phone.replace(" ", ""),
    birthday: data.birthday,
    age: yearsFromDate(data.birthday),
    assigned_sex: data.assigned_sex,
    legal_sex: data.legal_sex,
    ssn: data.ssn,
    mrn: data.mrn,
    additionalInput: data.notes,
  };
  return convertedData;
}

function convertToBackendFormat(reactiveFormData) {
  const data = Object.assign({}, reactiveFormData);
  const convertedData = {
    first_name: data.first_name,
    middle_name: data.middle_name,
    last_name: data.last_name,
    prefix: data.prefix,
    suffix: data.suffix,
    mrn: data.mrn,
    email: data.email,
    phone: data.phone,
    birthday: data.birthday,
    assigned_sex: data.assigned_sex,
    legal_sex: data.legal_sex,
    ssn: data.ssn,
    notes: data.additionalInput,
  };
  return convertedData;
}

const validateFirstName = () => validateName(patientDetails.first_name);
const validateMiddleName = () => validateName(patientDetails.middle_name, true);
const validateLastName = () => validateName(patientDetails.last_name);

function validatePhoneInput() {
  if (!patientDetails.phone?.trim()) return "Phone Number is required";
  return true;
}

function validateEmailInput() {
  if (!patientDetails.email?.trim()) return true;
  if (patientDetails.email.length && !validateEmail(patientDetails.email))
    return "Enter a correct e-mail or leave field blank";
  return true;
}

function getTodayDate() {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

async function validateMRN() {
  if (!patientDetails.mrn) return true;
  const mrn = patientDetails.mrn.trim();
  if (mrn.length < 4) {
    return "Enter at least 4 characters";
  }

  const mrnRegex = /^[a-zA-Z0-9]+$/;
  if (!mrnRegex.test(mrn)) {
    return "Please enter a valid MRN";
  }
  return true;
}

function validateGenderInput(input) {
  if (input === null) return "Please select an option";
  return true;
}

function validateSSN() {
  if (!patientDetails.ssn) return true;
  const ssn = patientDetails.ssn.trim();
  if (ssn.length < 4) {
    return "Enter at least 4 characters";
  }

  const ssnRegex = /^[a-zA-Z0-9]+$/;
  if (!ssnRegex.test(ssn)) {
    return "Please enter a valid SSN";
  }
  return true;
}

function validateDOB() {
  const dob = patientDetails.birthday?.trim();
  if (!dob) {
    return "Please select DOB";
  }
  return true;
}

function diseaseHasNoHistory(field) {
  if (
    (field.includes("diabetes") && patientDetails.diabetes.noHistory) ||
    (field.includes("hyperTension") && patientDetails.hyperTension.noHistory)
  )
    return true;
  return false;
}

defineRule(
  "validateDiseaseHistory",
  (_v, [targetMonths, targetYears], context) => {
    if (diseaseHasNoHistory(context.field)) return true;
    if (
      [targetMonths, targetYears].every(
        (item) => !item || parseFloat(item) === 0
      )
    ) {
      if (parseFloat(targetMonths) === 0 || parseFloat(targetYears) === 0)
        return "Period should be at least 1 month";
      return "Enter period or select N/A";
    }
    return true;
  }
);

const skipCheckPhoneExists = ref(false);
const skipCheckEmailExists = ref(false);
const skipCheckMrnExists = ref(false);

function triggerExistingPatientsModal(existingPatients) {
  if (existingPatients.patients.length > 0)
    emit("showExistingPatientsModal", existingPatients.patients);
}

async function checkIfPatientPhoneExists() {
  if (skipCheckPhoneExists.value) return;
  if (typeof validatePhoneInput(patientDetails.phone) === "string") return;
  const patientsWithIdenticalPhone = await backend.patients.getPatients(
    20,
    0,
    "",
    patientDetails.phone
  );
  triggerExistingPatientsModal(patientsWithIdenticalPhone);
  skipCheckPhoneExists.value = !skipCheckPhoneExists.value;
}

async function checkIfPatientMrnExists() {
  if (skipCheckMrnExists.value) return;
  if (typeof validateMRN(patientDetails.mrn) === "string") return;

  const patiensWithIdenticalMrn = await backend.patients.getPatientsByMrn(
    patientDetails.mrn
  );
  triggerExistingPatientsModal(patiensWithIdenticalMrn);
  skipCheckMrnExists.value = !skipCheckMrnExists.value;
}

async function checkIfPatientEmailExists() {
  if (skipCheckEmailExists.value) return;
  if (typeof validateEmailInput(patientDetails.email) === "string") return;
  const patiensWithIdenticalEmail = await backend.patients.getPatients(
    20,
    0,
    "",
    patientDetails.email
  );
  triggerExistingPatientsModal(patiensWithIdenticalEmail);
  skipCheckEmailExists.value = !skipCheckEmailExists.value;
}

async function handleFormSubmit() {
  const patient = convertToBackendFormat(patientDetails);
  if (isNewPatient) {
    const patientId = await backend.patients.createPatientV1(patient);
    router.push({
      path: `/patients/${patientId}`,
      query: { newlyAdded: true },
    });
    return;
  }
  await backend.patients.updatePatientV1(props.patientInfo.id, patient);
  emit("finishEditing");
}
</script>
<style lang="scss" scoped>
@import "@/style/style.scss";

.error-message {
  color: $danger;
}
.add-patient-page-container {
  max-width: 640px;
}

.form-title {
  font-size: 24px;
  line-height: 1;
  margin-bottom: 24px;
}

.form-select {
  height: 48px;
  margin-block: 10;
  border: 1px solid #a5a9ab;
}

.form-sub-title {
  font-size: 20px;
  line-height: 1;
  margin-top: 40px;
  margin-bottom: 16px;
}

.custom-title {
  font-size: 2rem;
  line-height: 0.6rem;
}

.form-control[type="date"] {
  width: 100%;
  height: 48px;
  border: 1px solid #aeb0b2;
  border-radius: 0.25rem;
  padding: 10px;
}
</style>
