export function validateName(inputName, allowEmpty = false) {
  const nameRegex = new RegExp("^[\\p{L}\\p{M}\\p{Pd} ]+$", "u");
  if (!nameRegex.test(inputName?.trim())) {
    if (allowEmpty && inputName?.trim() === "") {
      return true;
    }
    return "Please enter a valid name";
  }
  return true;
}

export function validateEmail(email) {
  return (
    String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ) != null
  );
}

export function validatePassword(password) {
  return (
    String(password).match(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^$*.[\]{}()?\-"!@#%&,><':;|_~`])\S{8,99}$/
    ) != null
  );
}

export function validateAge(age) {
  return (
    !isNaN(age) &&
    parseInt(Number(age)) == age &&
    !isNaN(parseInt(age, 10)) &&
    age >= 16 &&
    age <= 150
  );
}

export function validateId(id) {
  return (
    !isNaN(id) &&
    parseInt(Number(id)) == id &&
    !isNaN(parseInt(id, 10)) &&
    id >= 0
  );
}
