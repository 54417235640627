function useLabelColors() {
  const labelColors = [
    { id: 0, label: "microaneurysm", color: "#234c17" },
    { id: 1, label: "dot_hemorrhage", color: "#12fcae" },
    { id: 2, label: "flame_hemorrhage", color: "#234c17" },
    { id: 3, label: "blot_hemorrhage", color: "#fff47d" },
    { id: 4, label: "venous_loop", color: "#7900ff" },
    { id: 5, label: "venous_tortuosity", color: "#305C5C" },
    { id: 6, label: "venous_beading", color: "#fc28fb" },
    { id: 7, label: "irma", color: "#06ff00" },
    { id: 8, label: "nvd", color: "#ffaaa6" },
    { id: 9, label: "nve", color: "#f2f013" },
    { id: 10, label: "vitreous_hemorrhage", color: "#FF3333" },
    { id: 11, label: "hard_exudate", color: "#58391C" },
    { id: 12, label: "soft_exudate", color: "#eda4ff" },
    { id: 13, label: "hard_drusen", color: "#8cba51" },
    { id: 14, label: "soft_drusen", color: "#09698e" },
    { id: 15, label: "arterial_narrowing", color: "#04009a" },
    { id: 16, label: "hyper_pigmentation", color: "#fca311" },
    { id: 17, label: "laser_burn", color: "#19daf9" },
    { id: 18, label: "optic_disc", color: "#663300" },
    { id: 19, label: "intra_retinal_hemorrhage", color: "#ffff00" },
    { id: 20, label: "other", color: "#631708" },
  ];

  function getLabelColor(label) {
    for (let i = 0; i < labelColors.length; i++) {
      if (labelColors[i].label === label) return labelColors[i].color;
    }
    return "#000000";
  }

  function getAllLabelColors() {
    return labelColors;
  }

  return { getLabelColor, getAllLabelColors };
}
export default useLabelColors;
