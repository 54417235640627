function useHoverAndSelected() {
  var icVars, icFuncs;

  function initUseHoverAndSelected(icVarsR, icFuncsR) {
    icVars = icVarsR;
    icFuncs = icFuncsR;
  }

  function getHoveredOverIndex(x, y) {
    var index = -1;
    var indices = [];
    icVars.strokes.forEach((stroke, i) => {
      var minX = icVars.background.width;
      var minY = icVars.background.height;
      var maxX = 0;
      var maxY = 0;
      stroke.points.forEach((p) => {
        if (Math.abs(p.y - y) < 100) {
          if (p.x < minX) minX = p.x;
          if (p.x > maxX) maxX = p.x;
        }
        if (p.y < minY) minY = p.y;
        if (p.y > maxY) maxY = p.y;
      });
      if (x > minX && x < maxX && y > minY && y < maxY) {
        indices.push({ index: i, minX: minX, maxX: maxX });
      }
    });
    var minXSize = icVars.background.width;
    indices.forEach((v) => {
      let size = v.maxX - v.minX;
      if (size < minXSize) {
        minXSize = size;
        index = v.index;
      }
    });
    return index;
  }

  async function deleteSelectedHandler() {
    if (icVars.currentSelectedIndex !== -1) {
      delete icVars.strokes[icVars.currentSelectedIndex].selected;
      delete icVars.strokes[icVars.currentSelectedIndex].hovered;
      await icFuncs.removeStroke(icVars.currentSelectedIndex);
      icVars.currentSelectedIndex = -1;
      icVars.currentHoveredIndex = -1;
      icVars.hoveredOverLabel = "";
      icFuncs.redrawCanvas();
    }
  }

  return {
    initUseHoverAndSelected,
    getHoveredOverIndex,
    deleteSelectedHandler,
  };
}

export default useHoverAndSelected;
