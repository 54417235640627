<template>
  <ModalForm
    v-show="visible"
    @close="visible = false"
    title="Delete organization?"
  >
    <p>Please confirm that you want to delete this organization:</p>
    <p>
      <b>{{ props.orgName }}</b>
    </p>
    <div class="alert alert-danger" v-if="formError">
      {{ formError }}
    </div>
    <div class="mt-5">
      <button class="btn btn-primary me-2" @click="doDelete">Confirm</button>
      <button class="btn btn-secondary" @click="visible = false">Cancel</button>
    </div>
  </ModalForm>
</template>
<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import backend from "@/backend";
import ModalForm from "@/components/ModalForm.vue";

const router = useRouter();

const props = defineProps(["orgID", "orgName"]);

const formError = ref("");
const visible = ref(false);

async function doDelete() {
  try {
    await backend.organizations.deleteOrganization(props.orgID);
  } catch (error) {
    if (error?.response?.status == 409) {
      formError.value =
        "Organization is referenced by other data. Deletion not allowed.";
      return;
    } else {
      throw error;
    }
  }
  visible.value = false;
  router.push("/organizations");
}

function show() {
  visible.value = true;
}

defineExpose({ show });
</script>
