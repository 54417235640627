<template>
  <dialog open>
    <div class="mfa-dialog">
      <h4>Setup Multi-Factor Authentication</h4>
      <div v-if="qrCode" class="mt-4">
        <qrcode-vue v-if="qrCode" :value="qrCode" :size="200" />
        <p>Scan the QR Code with your authenticator app</p>
      </div>

      <div class="form-outline mb-4 mt-4">
        <input
          type="text"
          class="form-control form-control-lg"
          placeholder="Enter OTP from your app"
          v-model="otp"
        />
      </div>

      <div class="d-flex gap-2">
        <button class="btn btn-secondary" @click="emit('close', false)">
          Cancel
        </button>
        <button
          class="btn btn-primary flex-grow-1"
          @click="verifyAndSetupMFA"
          :disabled="!otp"
        >
          Verify and Setup MFA
        </button>
      </div>
    </div>
  </dialog>
</template>

<script setup>
import QrcodeVue from "qrcode.vue";
import { onMounted, ref } from "vue";
import backend from "@/backend";

const qrCode = ref("");
const otp = ref("");
const emit = defineEmits(["close"]);

async function generateQRCode() {
  try {
    const setupOTPRes = await backend.auth.setupTOTP();
    if (setupOTPRes) {
      const appName = "oivi-cloud";
      const setupUri = setupOTPRes.getSetupUri(appName);
      qrCode.value = setupUri.href;
    }
  } catch (_err) {
    alert("Failed to generate QR code. Please try again.");
  }
}

async function verifyAndSetupMFA() {
  let mfaEnabled = false;
  try {
    await backend.auth.verifyTOTP(otp.value);
    await backend.auth.setPreferredMFA("totp");
    mfaEnabled = true;
  } finally {
    emit("close", mfaEnabled);
  }
}

onMounted(() => {
  generateQRCode();
});
</script>

<style scoped>
dialog {
  width: min(100%, 400px) !important;
  border: 2px solid black;
  border-radius: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  background-color: white;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
</style>
