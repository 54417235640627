<template>
  <div class="container-fluid py-4 mt-5">
    <div class="row mb-5">
      <div class="col-6 fs-3 fs-bold">
        <h1>Welcome to the Oivi Dashboard!</h1>
        <p>Here’s how our operations are doing</p>
      </div>
      <!-- <div class="col-6">
        <div class="float-end">
          <button class="btn btn-oivi-light btn-lg mx-2">View Report</button>
          <button class="btn btn-oivi-light btn-lg mx-2">Download CSV</button>
        </div>
      </div> -->
    </div>

    <!-- Key Metrics -->
    <div class="row card-key-metrics text-center g-4">
      <div class="col-md fs-3 fs-bold">
        <div class="card-body">
          <h1>{{ statistics?.total_organizations }}</h1>
          <p>Organizations</p>
        </div>
      </div>
      <div class="col-md">
        <div class="card-body fs-3 fs-bold">
          <h1>{{ statistics?.total_visits_scanned }}</h1>
          <p>Visits scanned</p>
        </div>
      </div>
      <div class="col-md">
        <div class="card-body fs-3 fs-bold">
          <h1>
            {{ statistics?.total_dr_cases }} ({{
              statistics?.percentage_dr_cases
            }}%)
          </h1>
          <p>DR cases found</p>
        </div>
      </div>
      <div class="col-md">
        <div class="card-body fs-3 fs-bold">
          <h1>-</h1>
          <p>reports downloaded</p>
        </div>
      </div>
    </div>

    <!-- Performance Metrics -->
    <div class="row mt-4">
      <div class="col-3 mb-4">
        <!-- List group within card -->
        <div class="card">
          <div class="card-body fs-3 fs-bold">
            <h3>Product & service performance</h3>
            <hr />
            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <h1>{{ statistics?.percentage_analysis_within_5_minutes }}%</h1>
                visits analyzed under 5 mins
              </li>
              <li class="list-group-item">
                <h1>
                  {{ statistics?.percentage_analysis_within_10_minutes }}%
                </h1>
                visits analyzed under 10 mins
              </li>
              <li class="list-group-item">
                <h1>{{ statistics?.percentage_gradable }}%</h1>
                gradability success rate
              </li>
              <li class="list-group-item">
                <h1>
                  {{ statistics?.percentage_devices_with_latest_software }}%
                </h1>
                devices running latest SW
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-lg-9">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Visits</h5>
            <GenericChart
              v-if="plotData && graphOptions"
              :data="plotData"
              :options="graphOptions"
              type="line"
            ></GenericChart>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onBeforeMount } from "vue";
import backend from "@/backend";
import GenericChart from "../../components/GenericChart.vue";

const statistics = ref({});
const monthlyStatistics = ref([]);
const plotData = ref(null);

const graphOptions = ref({
  responsive: true,
  plugins: {
    title: {
      display: true,
      text: () => "Statistics",
    },
    tooltip: {
      mode: "index",
    },
  },
  interaction: {
    mode: "nearest",
    axis: "x",
    intersect: false,
  },
  scales: {
    x: {
      title: {
        display: true,
        text: "Month",
      },
    },
    y: {
      stacked: true,
      title: {
        display: true,
        text: "Value",
      },
    },
  },
  animation: false,
});

async function loadStatistics() {
  plotData.value = null;
  statistics.value = await backend.statistics.getStatisticsOverview();
  monthlyStatistics.value = await backend.statistics.getStatisticsOverview(
    null,
    null,
    null,
    null,
    true
  );

  const graphColors = [
    "rgb(202, 217, 249)",
    "rgb(241, 200, 195)",
    "rgb(187, 214, 191)",
    "rgb(251, 236, 185)",
    "rgb(248, 213, 182)",
  ];

  function getColor(index) {
    return graphColors[index % graphColors.length];
  }

  plotData.value = {
    labels: monthlyStatistics.value.map((stat) => stat.visit_month),
    datasets: [
      {
        label: "Total Visits Scanned",
        data: monthlyStatistics.value.map((stat) => stat.total_visits_scanned),
        fill: true,
        backgroundColor: getColor(2),
        borderColor: getColor(2),
        tension: 0.1,
      },
    ],
  };
}

onBeforeMount(() => {
  loadStatistics();
});
</script>

<style scoped>
.card {
  border: none;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}

.card-key-metrics {
  background-color: #cbdbff;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  border-radius: 13pt;
}
</style>
