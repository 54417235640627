<template>
  <template v-if="userID">
    by
    <span v-if="userID == user.id">
      <router-link v-if="!disableLink" to="/users/current">you</router-link>
      <span v-else>you</span>
    </span>
    <router-link
      v-else-if="!disableLink && allowed('VIEW_USERS')"
      :to="'/users/' + userID"
      >{{ userName ? userName : "User #" + userID }}</router-link
    >
    <span v-else>{{ userName ? userName : "User #" + userID }}</span>
  </template>
</template>
<script setup>
import { useStore } from "vuex";
import usePolicy from "@/util/usePolicy";
const store = useStore();
const user = store.getters.currentUser;

const { allowed } = usePolicy();

defineProps(["userID", "userName", "disableLink"]);
</script>
