<template>
  <BreadCrumbs
    current="Analysis"
    parent="Visits"
    parent-path="/visits"
    :parent2="'Visit ' + visit.id"
    :parent2-path="'/visits/' + visit.id"
  />

  <div class="body" style="padding-top: 0">
    <div class="row">
      <div class="col-md-9 mb-3">
        <div class="card bg-light">
          <div class="card-body row">
            <div class="col-2">
              <ImageTable :images="images" @onImageSelected="onImageSelected" />
            </div>
            <div class="col-10">
              <ImageCanvas ref="ic" extra_styles="z-index: 9" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <VisitDetails :visit="visit" />
        <AnalystInput :visit="visit" :images="images" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import backend from "@/backend";
import ImageCanvas from "./components/ImageCanvas.vue";
import ImageTable from "./components/ImageTable.vue";
import VisitDetails from "./components/VisitDetails.vue";
import AnalystInput from "./components/AnalystInput.vue";

const props = defineProps(["visitID"]);

const ic = ref(null);
const visit = ref({});
const images = ref([]);

onMounted(async () => {
  visit.value = await backend.visits.getVisit(props.visitID);
  images.value = await backend.visits.getVisitImages(props.visitID, 20, 0);
  if (images.value.length > 0) ic.value.loadImage(images.value[0].id);
});

function onImageSelected(id) {
  ic.value.loadImage(id);
}
</script>

<style>
.card h5 {
  color: #333333;
  text-transform: uppercase;
}
</style>
