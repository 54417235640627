<template>
  <div class="d-flex flex-column">
    <label
      for="id-modal-add-patient-input-phone"
      class="form-label fw-medium-bold"
      >{{ props.inputLabel
      }}<i
        v-if="props.tooltipContent"
        class="bi bi-info-circle ms-2"
        id="info-icon"
        data-toggle="tooltip"
        :title="props.tooltipContent"
      ></i
    ></label>
    <Field
      :name="props.inputName"
      :value="props.modelValue"
      :rules="validatePhoneNumber"
      v-slot="{ field, errors }"
      @input="$emit('update:modelValue', iti.getNumber())"
    >
      <input
        id="id-modal-add-patient-input-phone"
        v-bind="field"
        type="tel"
        class="form-control d-flex text-input-field"
        :class="{ 'is-invalid': !!errors.length }"
        @blur="$emit('handleOnBlurAction')"
      />
    </Field>
    <div class="mt-1 ms-1">
      <ErrorMessage
        class="error-message"
        :name="props.inputName"
        :id="props.inputName + 'ErrorMessage'"
      />
    </div>
  </div>
</template>

<script setup>
import { onMounted } from "vue";
import { Field, ErrorMessage } from "vee-validate";
import { Tooltip } from "bootstrap";
import intlTelInput from "intl-tel-input";
import { AppConfig } from "@/util/AppConfig";

const props = defineProps([
  "modelValue",
  "inputLabel",
  "inputName",
  "isRequired",
]);

new Tooltip(document.body, {
  selector: "[data-toggle='tooltip']",
});

var iti;
function validatePhoneNumber(number) {
  if (props.isRequired && number === "") return "Enter a phone number";
  if (iti?.getNumber() && iti.isValidNumber() === false)
    return "Enter valid phone number";
  return true;
}

onMounted(() => {
  const input = document.querySelector("#id-modal-add-patient-input-phone");
  iti = intlTelInput(input, {
    autoPlaceholder: "polite",
    separateDialCode: true,
    initialCountry: AppConfig.countryPhoneCode,
    utilsScript:
      "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/18.1.0/js/utils.js",
  });
});
</script>
<style>
.iti {
  width: 100%;
}

/* Make sure the width of the tel input is the same as the input field */

.iti__flag-container {
  position: static !important;
}

.iti__selected-flag {
  position: absolute !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  height: 100% !important;
}

.iti__country-list {
  width: 100% !important;
  top: 100% !important;
  white-space: normal !important;
}
</style>
