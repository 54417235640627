<template>
  <div class="container">
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th>Visit ID</th>
          <th>Clinic</th>
          <th>Date</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="v in visits"
          :key="v.id"
          role="button"
          @click="router.push('/visits/' + v.id)"
        >
          <td>{{ v.id }}</td>
          <td>{{ v.location_name }}</td>
          <td>{{ formatDate(v.created) }}</td>
          <td>{{ formatStatus(v) }}</td>
        </tr>
      </tbody>
    </table>
    <PageSelect
      :totalEntries="totalEntries"
      :limitPerPage="limitPerPage"
      :offsetValue="offsetValue"
      @newOffsetValue="newOffsetValue"
      @newLimitPerPage="newLimitPerPage"
    />
  </div>
</template>

<script setup>
import { onBeforeMount, watch, ref } from "vue";
import { useRouter } from "vue-router";
import backend from "@/backend";
import { formatDate, formatStatus } from "@/util/formatting";
import PageSelect from "@/components/PageSelect.vue";

const props = defineProps(["patientID"]);

const totalEntries = ref(0);
const limitPerPage = ref(5);
const offsetValue = ref(0);
const router = useRouter();
const visits = ref([]);

async function reload() {
  const result = await backend.patients.getPatientVisits(
    props.patientID,
    limitPerPage.value,
    offsetValue.value
  );
  visits.value = result.visits;
  totalEntries.value = result.total_count;
}

watch([limitPerPage, offsetValue], () => {
  reload();
});

onBeforeMount(() => {
  reload();
});

function newLimitPerPage(limit) {
  limitPerPage.value = limit;
}
function newOffsetValue(newOffsetVal) {
  offsetValue.value = newOffsetVal;
}

defineExpose({ reload });
</script>
