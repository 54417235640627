<template>
  <div class="body container-fluid">
    <h2 class="mb-3">Settings</h2>

    <div class="row" v-for="(value, name) in settings" :key="name">
      <div class="col-md-4">
        <strong>{{ name }}</strong>
      </div>
      <div class="col-md-4">
        <input type="text" class="form-control" v-model="settings[name]" />
      </div>
      <div class="col-md-4" v-if="settings[name] != storedSettings[name]">
        <button
          type="button"
          class="btn btn-sm btn-primary me-1"
          @click="saveSetting(name)"
        >
          Save
        </button>
        <button
          type="button"
          class="btn btn-sm btn-secondary"
          @click="settings[name] = storedSettings[name]"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import backend from "@/backend";

const storedSettings = ref({});
const settings = ref({});

async function loadSettings() {
  storedSettings.value = await backend.settings.listSettings();
  settings.value = { ...storedSettings.value };
}

async function saveSetting(name) {
  await backend.settings.setSetting(name, settings.value[name]);
  loadSettings();
}

onMounted(loadSettings);
</script>
