<template>
  <ModalForm
    v-show="visible"
    @close="visible = false"
    title="Add software channel"
  >
    <div class="form-outline mb-4">
      <input
        type="text"
        class="form-control form-control-lg"
        placeholder="Channel name"
        v-model="formData.name"
      />
    </div>

    <div class="form-outline mb-4">
      <select
        class="form-control form-control-lg"
        v-model="formData.sw_package_id"
      >
        <option :value="null">No software version selected</option>
        <option v-for="p in packages" :value="p.id" :key="p.id">
          {{ p.version }}
        </option>
      </select>
    </div>

    <div class="alert alert-danger" v-if="formError">
      {{ formError }}
    </div>

    <div class="text-center mt-5">
      <button class="btn btn-oivi-light" @click="newChannel">
        Add software channel
      </button>
    </div>
  </ModalForm>
</template>

<script setup>
import { reactive, ref, watch, onMounted } from "vue";
import backend from "@/backend";
import ModalForm from "@/components/ModalForm.vue";

const emit = defineEmits("dataChange");

const visible = ref(false);
const formDataDefaults = {
  name: "",
  sw_package_id: "",
};
const formData = reactive({ ...formDataDefaults });
const formError = ref("");

watch(visible, async (newValue) => {
  if (newValue == false) {
    for (var key in formData) formData[key] = formDataDefaults[key];
    formError.value = "";
  }
});

async function newChannel() {
  await backend.channels.addChannel(formData);
  visible.value = false;
  emit("dataChange");
}

function show() {
  visible.value = true;
}

const packages = ref([]);
async function loadData() {
  packages.value = await backend.packages.listPackages();
}

onMounted(async function () {
  loadData();
});

defineExpose({ show });
</script>
