<template>
  <div class="body container-fluid">
    <ModalForm
      v-show="isTimeSeriesPlotVisible"
      @close="isTimeSeriesPlotVisible = false"
      title="Pupil Time Series Plot"
      :makeLarge="true"
    >
      <LineGraph
        v-if="isTimeSeriesPlotVisible"
        :data="pupilTimeSeries"
        :options="options"
      ></LineGraph>
    </ModalForm>
    <ImageFilters @setImageFilters="setImageFilters" v-model="filters" />
    <div class="d-flex mb-3">
      <h2>Images</h2>
    </div>
    <div>
      <h4>{{ totalEntries }} images found:</h4>
      <hr style="text-align: left; margin-top: 0" />
    </div>
    <div class="container">
      <div class="row">
        <div
          class="col-sm-12 col-lg-6 col-xl-4 mb-3"
          v-for="img in images"
          :key="img.id"
        >
          <ImageCard
            @imageClicked="imageClicked"
            @showPupilTimeSeries="showPupilTimeSeries"
            :img="img"
          />
        </div>
      </div>
    </div>

    <PageSelect
      :totalEntries="totalEntries"
      :limitPerPage="parseInt(filters.limit)"
      :offsetValue="parseInt(filters.offset)"
      @newOffsetValue="newOffsetValue"
      @newLimitPerPage="newLimitPerPage"
    />
    <ModalForm
      v-show="showImageCanvas"
      @close="showImageCanvas = false"
      :makeLarge="true"
    >
      <ImageCanvas :view_only="true" ref="ic" />
    </ModalForm>
  </div>
</template>
<script setup>
import { ref, reactive, watch, onBeforeMount } from "vue";
import { useRouter } from "vue-router";
import backend from "@/backend";
import PageSelect from "@/components/PageSelect.vue";
import ModalForm from "@/components/ModalForm.vue";
import ImageCanvas from "@/pages/Annotation/components/ImageCanvas.vue";
import ImageFilters from "./ImageFilters.vue";
import ImageCard from "./ImageCard.vue";
import LineGraph from "../../components/LineGraph.vue";
const router = useRouter();

const images = ref([]);
const totalEntries = ref(0);
const showImageCanvas = ref(false);
const pupilTimeSeries = ref("");
const isTimeSeriesPlotVisible = ref(false);
const options = {
  scales: {
    y: {
      title: {
        text: "Pupil Size",
        display: true,
      },
    },
    x: {
      type: "linear",
      title: {
        text: "Time",
        display: true,
      },
      ticks: {
        // forces step size to be 0.5 units
        stepSize: 0.5,
      },
    },
  },
};
const diabetesRange = {
  minDiabetes: "",
  maxDiabetes: "",
};

const defaultFilters = {
  cataract: "",
  diabetes: "",
  minAge: "",
  maxAge: "",
  drGrade: "",
  locId: "",
  patientId: "",
  visitId: "",
  suddenPupilContraction: "",
  limit: 6,
  offset: 0,
  startDate: "",
  endDate: "",
};
const filters = reactive(defaultFilters);

const ic = ref(null);

async function loadData() {
  const result = await backend.images.listImages(
    filters.limit,
    filters.offset,
    filters.cataract,
    filters.diabetes,
    diabetesRange.minDiabetes,
    diabetesRange.maxDiabetes,
    filters.minAge,
    filters.maxAge,
    filters.drGrade,
    filters.locId,
    filters.patientId,
    filters.visitId,
    filters.suddenPupilContraction,
    filters.startDate,
    filters.endDate
  );
  for (const img of result.images) {
    img.metadata = JSON.parse(img.metadata);
  }
  images.value = result.images;
  totalEntries.value = result.total_count;
}

function setDiabetesRange(diabetesStatus) {
  if (diabetesStatus == "less_than_1") {
    diabetesRange.maxDiabetes = "1";
    diabetesRange.minDiabetes = "";
  } else if (diabetesStatus == "between_1_and_5") {
    diabetesRange.minDiabetes = "1";
    diabetesRange.maxDiabetes = "5";
  } else if (diabetesStatus == "between_5_and_10") {
    diabetesRange.minDiabetes = "5";
    diabetesRange.maxDiabetes = "10";
  } else if (diabetesStatus == "more_than_10") {
    diabetesRange.minDiabetes = "10";
    diabetesRange.maxDiabetes = "";
  } else if (
    diabetesStatus == "all" ||
    diabetesStatus == "none" ||
    diabetesStatus == ""
  ) {
    diabetesRange.minDiabetes = "";
    diabetesRange.maxDiabetes = "";
  }
}

function setImageFilters(imageFilters) {
  if ("diabetes" in imageFilters) {
    setDiabetesRange(imageFilters.diabetes);
  }

  for (const [key, value] of Object.entries(imageFilters)) {
    if (key in filters) filters[key] = value;
  }
}

function newLimitPerPage(limit) {
  filters.limit = limit;
  filters.offset = 0;
}

function newOffsetValue(newOffsetVal) {
  filters.offset = newOffsetVal;
}

function imageClicked(id) {
  ic.value.loadImage(id);
  showImageCanvas.value = true;
}

function getImagePupilTimeSeries(id) {
  for (const img of images.value) {
    if (img.id == id) {
      const timeSeries =
        img?.metadata?.alignment?.states?.pre_analysis_alignment
          ?.pupil_time_series;
      return Object.entries(timeSeries).sort();
    }
  }
}

function showPupilTimeSeries(id) {
  const response = getImagePupilTimeSeries(id);
  pupilTimeSeries.value = {
    labels: response.map((a) => {
      return a[0];
    }),
    datasets: [
      {
        data: response.map((a) => {
          return a[1];
        }),
        label: "Pupil Size",
      },
    ],
  };
  isTimeSeriesPlotVisible.value = true;
}

onBeforeMount(async function () {
  const url = new URL(window.location);
  setImageFilters(Object.fromEntries(url.searchParams));
  loadData();
});

watch(filters, () => {
  const query = {};
  for (const [key, value] of Object.entries(filters)) {
    if (value) query[key] = value;
  }
  loadData();
  router.push({ name: "Images", query: query });
});
</script>
<style scope="local">
.thumb {
  max-width: 200px;
  max-height: 200px;
  cursor: pointer;
}
</style>
