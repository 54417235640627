<template>
  <div class="d-flex justify-content-center">
    <button class="btn btn-oivi-light" @click="addVisit">Start Visit</button>
  </div>

  <ModalForm
    v-show="checkinVisible"
    @close="checkinVisible = false"
    title="Start visit"
  >
    <p>
      Start visit for <strong>{{ props.patient.name }}</strong> at:
    </p>

    <select v-model="user.clinic_id" class="form-select">
      <option disabled value="undefined">- please select clinic -</option>
      <option v-for="clinic in clinics" :key="clinic.id" :value="clinic.id">
        {{ clinic.name }}
      </option>
    </select>

    <div class="alert alert-danger mt-3" v-if="clinics.length == 0">
      There are no clinics configured for your organization. Please contact your
      system administrator.
    </div>

    <div class="alert alert-warning mt-3" v-if="backendReportedConflict">
      Visit already started for this patient.
    </div>

    <div class="mt-5 d-flex justify-content-center">
      <button class="btn btn-secondary me-3" @click="checkinVisible = false">
        Cancel
      </button>
      <button
        class="btn btn-primary"
        @click="checkinConfirmed"
        :disabled="!user.clinic_id"
      >
        Confirm
      </button>
    </div>
  </ModalForm>
</template>
<script setup>
import { ref, watch } from "vue";
import { useStore } from "vuex";
import backend from "@/backend";
import ModalForm from "@/components/ModalForm.vue";

const props = defineProps(["patient"]);
const emit = defineEmits(["dataChange"]);

const store = useStore();
const user = store.getters.currentUser;

const checkinVisible = ref(false);
const clinics = ref([]);
const backendReportedConflict = ref(false);

watch(checkinVisible, () => {
  backendReportedConflict.value = false;
});

async function addVisit() {
  clinics.value = await backend.locations.listOrgLocations(
    user.organization_id
  );
  checkinVisible.value = true;
  if (!user.clinic_id && clinics.value.length == 1)
    user.clinic_id = clinics.value[0].id;
}

async function checkinConfirmed() {
  try {
    await backend.patients.createPatientVisit(props.patient.id, user.clinic_id);
  } catch (error) {
    if (error?.response?.status == 409) {
      backendReportedConflict.value = true;
      return;
    }
    throw error;
  }
  backendReportedConflict.value = false;
  checkinVisible.value = false;
  emit("dataChange");
}
</script>
