<template>
  <ModalForm
    v-show="visible"
    @close="visible = false"
    title="Edit clinic"
    footerButtonLabel="Save"
    :footerButtonAction="saveChanges"
    footerButtonClass="btn-lg btn-primary"
  >
    <div class="form-outline mb-4">
      <input
        type="text"
        class="form-control form-control-lg"
        placeholder="Clinic name"
        v-model="formData.name"
      />
    </div>

    <div class="form-outline mb-4">
      <input
        type="text"
        class="form-control form-control-lg"
        placeholder="Email"
        v-model="formData.email"
      />
    </div>

    <div class="form-outline mb-4">
      <input
        type="text"
        class="form-control form-control-lg"
        placeholder="Notification email"
        v-model="formData.notification_email"
      />
      <small class="text-muted">Notification email.</small>
    </div>

    <div class="form-outline mb-4">
      <input
        type="text"
        class="form-control form-control-lg"
        placeholder="Phone number"
        v-model="formData.phone"
      />
    </div>

    <div class="form-outline mb-4">
      <input
        type="text"
        class="form-control form-control-lg"
        placeholder="Address"
        v-model="formData.address"
      />
    </div>

    <div class="alert alert-danger" v-if="formError">
      {{ formError }}
    </div>
  </ModalForm>
</template>
<script setup>
import { ref, watch } from "vue";
import backend from "@/backend";
import { validateEmail } from "@/util/validation";
import ModalForm from "@/components/ModalForm.vue";

const emit = defineEmits(["dataChange"]);
const props = defineProps(["location"]);

const visible = ref(false);
const formData = ref({
  name: "",
  email: "",
  phone: "",
  address: "",
  notification_email: "",
});
const formError = ref("");

watch(visible, async (newValue) => {
  if (newValue) {
    for (var key in formData.value) formData.value[key] = props.location[key];
    formError.value = "";
  }
});

async function saveChanges() {
  if (!formData.value.name.trim()) {
    formError.value = "Required: clinic name";
  } else if (formData.value.email && !validateEmail(formData.value.email)) {
    formError.value = "Invalid email address";
  } else {
    await backend.locations.updateLocation(props.location.id, formData.value);
    visible.value = false;
    emit("dataChange");
  }
}

function show() {
  visible.value = true;
}

defineExpose({ show });
</script>
