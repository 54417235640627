<template>
  <div v-for="note in notes" :key="note.id" class="mb-3">
    <button class="note-delete" @click="noteToDelete = note"></button>
    <div class="fw-bold">{{ formatDate(note.updated) }}</div>
    <div>{{ note.notes }}</div>
  </div>
  <textarea
    class="form-control"
    placeholder="Write visit notes here"
    v-model="draftNotes"
  ></textarea>
  <div class="text-end mt-1" v-if="draftNotes">
    <button class="btn btn-sm btn-secondary me-1" @click="draftNotes = ''">
      Cancel
    </button>
    <button class="btn btn-sm btn-primary" @click="addNoteClicked">Save</button>
  </div>
  <ModalForm
    title="Delete note?"
    v-show="noteToDelete"
    @close="noteToDelete = false"
  >
    <blockquote>{{ noteToDelete.notes }}</blockquote>
    <p class="text-danger">Are you sure you want to delete this note?</p>
    <div class="text-center mt-5">
      <button class="btn btn-secondary me-3" @click="noteToDelete = false">
        Cancel
      </button>
      <button
        class="btn btn-primary"
        @click="confirmDeleteNote(noteToDelete.id)"
      >
        Delete
      </button>
    </div>
  </ModalForm>
</template>
<script setup>
import { onMounted, ref, watch } from "vue";
import backend from "@/backend";
import { formatDate } from "@/util/formatting";
import ModalForm from "@/components/ModalForm.vue";

const props = defineProps(["visitID"]);
const notes = ref([]);
const draftNotes = ref("");
const noteToDelete = ref(false);

const emit = defineEmits(["change"]);

watch(draftNotes, (value) => {
  emit("change", value);
});

async function loadNotes() {
  notes.value = [];
  if (props?.visitID)
    notes.value = await backend.visits.getNotes(props.visitID);
  else notes.value = [];
}

async function addNoteClicked() {
  await backend.visits.addNote(props.visitID, draftNotes.value);
  draftNotes.value = "";
  loadNotes();
}

async function confirmDeleteNote(noteId) {
  await backend.visits.deleteNote(noteId);
  noteToDelete.value = false;
  loadNotes();
}

onMounted(loadNotes);
watch(props, loadNotes);
</script>
