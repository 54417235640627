<template>
  <dialog :open="true" class="dialog-overlay">
    <div class="dialog">
      <div class="dialog-content">
        <Diff
          mode="split"
          theme="light"
          language="json"
          :prev="formatJSON(props.oldData)"
          :current="formatJSON(props.newData)"
          :folding="true"
        />
      </div>
      <button @click="closeDialog">Close</button>
    </div>
  </dialog>
</template>

<script setup>
const props = defineProps(["oldData", "newData"]);
const emit = defineEmits(["close"]);

function closeDialog() {
  emit("close");
}

function formatJSON(data) {
  return JSON.stringify(JSON.parse(data), null, 2);
}
</script>

<style scoped>
.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.dialog {
  width: min(100%, 800px);
  border: 2px solid black;
  border-radius: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  background-color: white;
  padding: 20px;
}

.dialog button {
  margin-top: 10px;
}
.dialog-content {
  max-height: 300px;
  overflow: auto;
}
</style>
