<template>
  <div class="d-flex flex-column">
    <label :for="props.inputName" class="form-label fw-medium-bold"
      >{{ props.inputLabel
      }}<i
        v-if="props.tooltipContent"
        class="bi bi-info-circle ms-2"
        id="info-icon"
        data-toggle="tooltip"
        :title="props.tooltipContent"
      ></i
    ></label>
    <div class="input">
      <Field
        :name="props.inputName"
        :value="props.modelValue"
        :rules="props.validator"
        v-slot="{ field, errors, handleChange }"
        @input="$emit('update:modelValue', $event.target.value)"
      >
        <input
          :id="props.inputName"
          v-bind="field"
          :max="props.max"
          :value="props.modelValue"
          type="date"
          class="text-input-field form-control mb-0"
          :class="{
            'is-invalid': !!errors?.length,
          }"
          :placeholder="props.placeholder"
          autocomplete="off"
          @keyup="(e) => triggerChangeHandler(e, handleChange)"
          :disabled="props.disabled"
        />
      </Field>
      <div class="my-1">
        <ErrorMessage
          class="error-message"
          :name="props.inputName"
          :id="props.inputName + 'ErrorMessage'"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { configure, Field, ErrorMessage } from "vee-validate";
import { Tooltip } from "bootstrap";

const props = defineProps([
  "modelValue",
  "inputLabel",
  "inputName",
  "validator",
  "placeholder",
  "tooltipContent",
  "disabled",
  "max",
]);

new Tooltip(document.body, {
  selector: "[data-toggle='tooltip']",
});

// do not trigger validation immediately when field was accessed with tab (has keyCode 9)
function triggerChangeHandler(e, changeHandler) {
  if (e.keyCode === 9) return;
  return changeHandler();
}

configure({
  validateOnInput: false,
});
</script>
<style lang="scss"></style>
