<template>
  <section class="vh-100">
    <div class="py-5 h-100">
      <div class="container h-100">
        <div
          class="d-flex flex-column align-items-center justify-content-start h-100"
        >
          <div
            class="col-12 col-md-8 col-lg-6 col-xl-5 d-flex justify-content-center p-2 my-3"
          >
            <img class="img-fluid w-25" src="../assets/images/oivi_logo.png" />
          </div>
          <div class="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5">
            <div class="card border-primary" style="border-radius: 1rem">
              <div class="card-body p-5 text-center">
                <slot></slot>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { onMounted, onUnmounted } from "vue";
const emit = defineEmits(["onEnter"]);
const keyPressListener = (e) => {
  if (e.key === "Enter") {
    emit("onEnter");
  }
};
onMounted(() => window.addEventListener("keypress", keyPressListener));
onUnmounted(() => window.removeEventListener("keypress", keyPressListener));
</script>

<style scoped>
.link {
  cursor: pointer;
}
</style>
