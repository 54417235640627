import axios from "axios";
import { Amplify } from "aws-amplify";
import { fetchAuthSession } from "aws-amplify/auth";

function configureAuth() {
  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolClientId: import.meta.env.VITE_AWS_COGNITO_APP_CLIENT_ID,
        userPoolId: import.meta.env.VITE_AWS_COGNITO_USER_POOL_ID,
        signUpVerificationMethod: "link",
        loginWith: {
          email: true,
        },
      },
    },
  });

  axios.interceptors.request.use(async function (config) {
    if (
      config.url &&
      config.url.startsWith(import.meta.env.VITE_BIRDHOUSE_ENDPOINT)
    ) {
      try {
        const session = await fetchAuthSession();
        const token = session.tokens.accessToken;
        config.headers.Authorization = token;
      } catch (_err) {
        // empty
      }
    }
    return config;
  });
}

export default configureAuth;
