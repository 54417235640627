<template>
  <div class="body container-fluid">
    <p v-if="environment === 'local'">
      This is a local development version of the Oivi Cloud.
    </p>
    <p v-if="environment !== 'local'">
      You are using version <span id="ui-version">{{ version }}</span> of the
      Oivi cloud.
    </p>
    <p>
      If you have feeback or questions, please contact us with the form on the
      <router-link to="/contact"> Contact page</router-link>.
    </p>
    <p>
      For more information about Oivi, please visit our
      <a href="https://www.oivi.co">website</a>.
    </p>
  </div>
</template>
<script setup>
import { version } from "../../package.json";
const environment = import.meta.env["VITE_DEPLOYMENT"];
</script>
<style>
#ui-version {
  font-weight: bold;
}
</style>
