<template>
  <div class="d-flex flex-column">
    <div class="fw-medium-bold w-100 mb-2">
      {{ props.groupLabel }}
    </div>
    <div class="d-flex flex-column flex-wrap check-options-container">
      <div
        v-for="option in props.options"
        v-bind:key="option.value"
        class="mt-0 mb-3"
      >
        <Field :name="props.checkName" v-slot="{ field }">
          <label
            :for="option.value + 'CheckBox'"
            class="form-check-label btn btn-light check-box-label d-flex align-items-center px-2"
          >
            <input
              :name="props.checkName"
              v-bind="field"
              type="checkbox"
              :id="option.value + 'CheckBox'"
              class="form-check-input check-box-square me-2 mt-0"
              :checked="props.modelValue?.includes(option.value)"
              @input="
                (event) => handleInputChange(event.target.checked, option.value)
              "
            />
            {{ option.label }}
          </label>
        </Field>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Field } from "vee-validate";
const props = defineProps([
  "modelValue",
  "groupLabel",
  "checkName",
  "options",
  "tooltipContent",
  "flexRow",
]);
const emit = defineEmits(["update:modelValue"]);
function handleInputChange(checked, value) {
  if (checked) {
    return emit("update:modelValue", [...props.modelValue, value]);
  }
  return emit(
    "update:modelValue",
    props.modelValue.filter((item) => item !== value)
  );
}
</script>
<style lang="scss">
@import "@/style/style.scss";

// .check-options-container {
//   @media only screen and (max-width: 576px) {
//     align-items: start;
//   }
// }
</style>
