<template>
  <div aria-label="breadcrumb" id="breadcrumbs">
    <ol class="breadcrumb mb-0">
      <template v-for="item in pathItems()" :key="item.name">
        <li v-if="item.path" class="breadcrumb-item">
          <router-link :to="item.path">{{ item.name }}</router-link>
        </li>
        <li v-else class="breadcrumb-item active" aria-current="page">
          {{ item.name }}
        </li>
      </template>
    </ol>
  </div>
</template>
<script setup>
const props = defineProps([
  "current",
  "parent",
  "parentPath",
  "parent2",
  "parent2Path",
]);

function pathItems() {
  let result = [];
  if (props.parent && props.parentPath) {
    result.push({ name: props.parent, path: props.parentPath });
  }
  if (props.parent2 && props.parent2Path) {
    result.push({ name: props.parent2, path: props.parent2Path });
  }
  if (props.current) result.push({ name: props.current });
  return result;
}
</script>
<style scoped>
#breadcrumbs {
  padding-top: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
}
.breadcrumb-item a {
  text-decoration: none;
  color: #415998;
}
</style>
