import { useStore } from "vuex";

function usePolicy() {
  const store = useStore();
  const user = store.getters.currentUser;

  function allowed(action) {
    if (!user || !user.actions) return false;
    return user.actions.includes("ANY_ACTION") || user.actions.includes(action);
  }

  return { allowed };
}
export default usePolicy;
