<template>
  <div class="body container-fluid">
    <div class="d-flex mb-3">
      <h2>Software packages</h2>
      <div class="ms-auto">
        <button class="btn btn-oivi-light" @click="addPackageModal.show()">
          Add software package
        </button>
      </div>
    </div>
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th>Software version</th>
          <th>Upload date</th>
          <th>Channel</th>
        </tr>
      </thead>
      <tbody>
        <tr
          role="button"
          v-for="sw_package in packages"
          :key="sw_package.id"
          @click="router.push('/packages/' + sw_package.id)"
        >
          <td>{{ sw_package.version }}</td>
          <td>{{ sw_package.created }}</td>
          <td>
            <router-link :to="'/channels/' + sw_package.channel_id" @click.stop>
              {{ sw_package.channel_name }}
            </router-link>
          </td>
        </tr>
      </tbody>
    </table>
    <AddPackageModal ref="addPackageModal" @dataChange="loadData" />
  </div>
</template>
<script setup>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import backend from "@/backend";
import AddPackageModal from "./components/AddPackageModal.vue";

const addPackageModal = ref(null);
const packages = ref([]);
const router = useRouter();

async function loadData() {
  packages.value = await backend.packages.listPackages();
}

onMounted(async function () {
  loadData();
});
</script>
