<template>
  <ModalForm v-show="visible" @close="visible = false" title="Delete clinic?">
    <p>Please confirm that you want to delete this clinic:</p>
    <p>
      <b>{{ props.locationName }}</b>
    </p>
    <div class="alert alert-danger" v-if="formError">
      {{ formError }}
    </div>
    <div class="mt-5">
      <button class="btn btn-primary me-2" @click="doDelete">Confirm</button>
      <button class="btn btn-secondary" @click="visible = false">Cancel</button>
    </div>
  </ModalForm>
</template>
<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import backend from "@/backend";
import ModalForm from "@/components/ModalForm.vue";

const router = useRouter();

const props = defineProps(["orgID", "locationID", "locationName"]);

const formError = ref("");
const visible = ref(false);

async function doDelete() {
  try {
    backend.locations.deleteLocation(props.locationID);
  } catch (error) {
    if (error?.response?.status == 409) {
      formError.value =
        "Location is referenced by other data. Deletion not allowed.";
      return;
    }
    throw error;
  }
  visible.value = false;
  router.push("/organizations/" + props.orgID);
}

function show() {
  visible.value = true;
}

defineExpose({ show });
</script>
