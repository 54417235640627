<template>
  <div class="container">
    <div
      class="alert alert-success d-flex flex-row justify-content-between align-items-center py-2 mb-5"
      v-if="isNew"
    >
      <div>Patient added</div>
      <button class="btn btn-sm btn-primary" @click="navigateToAddPatientPage">
        Add other patient
      </button>
    </div>
    <div class="row">
      <div class="col-md-6">
        <h5>{{ patientInfo.name }}</h5>
      </div>
      <div class="col-md-6">
        <h5>Medical History</h5>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="row">
          <p class="infocell">Patient ID: {{ patientInfo.id }}</p>
        </div>
        <div class="row">
          <p class="infocell">
            {{ patientInfo.gender }},
            {{ yearsFromDate(patientInfo.birthday) + " years" }}
          </p>
        </div>
        <div class="row">
          <p class="infocell">Phone: {{ patientInfo.phone }}</p>
        </div>
        <div class="row">
          <p class="infocell">Email: {{ patientInfo.email }}</p>
        </div>
        <div class="row">
          <p class="infocell">Medical record number: {{ patientInfo.mrn }}</p>
        </div>
        <div v-if="patientInfo.orgs" class="row mt-2">
          <p class="infocell">
            Organizations:
            <span v-for="org in patientInfo.orgs" :key="org.id" class="ms-2">
              <router-link :to="'/organizations/' + org.id">
                {{ org.name }}
              </router-link>
              <a
                href="#"
                class="bi bi-trash ms-1"
                @click="emit('removeFromOrg', org.id)"
                :title="'Remove patient from ' + org.name"
              ></a>
            </span>
            <a
              href="#"
              class="bi bi-plus-circle ms-2"
              title="Add patient to organization"
              @click="emit('addToOrg')"
            ></a>
          </p>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <p class="infocell">
            Diabetes:
            <span v-if="patientInfo.diabetes_onset !== undefined">
              {{ formatYearsMonthsOnset(patientInfo.diabetes_onset) }}
            </span>
          </p>
        </div>
        <div class="row">
          <p class="infocell">
            Hypertension:
            <span v-if="patientInfo.diabetes_onset !== undefined">
              {{ formatYearsMonthsOnset(patientInfo.hypertension_onset) }}
            </span>
          </p>
        </div>
        <div class="row">
          <p class="infocell">
            Cataract (OD / Right eye):
            {{ formatCataract(patientInfo.cataract_od) }}
          </p>
        </div>
        <div class="row">
          <p class="infocell">
            Cataract (OS / Left eye):
            {{ formatCataract(patientInfo.cataract_os) }}
          </p>
        </div>
      </div>
    </div>
    <div class="row mt-3 mb-3">
      <h5>Notes</h5>
      <form>
        <div class="form-group">
          <textarea
            class="form-control"
            rows="3"
            v-bind:value="patientInfo.notes"
            readonly
          ></textarea>
        </div>
      </form>
    </div>
    <div class="row" style="margin-bottom: 20px">
      <div class="d-flex justify-content-center">
        <div
          v-if="userCanDeletePatients"
          class="col-md-6 d-flex justify-content-end p-2"
        >
          <button
            class="btn btn-outline-danger me-3"
            @click="emit('removePatient')"
          >
            Delete Patient
          </button>
        </div>
        <div :class="{ 'col-md-6 p-2': userCanDeletePatients }">
          <button class="btn btn-primary" @click="emit('editPatient')">
            Edit Patient Details
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import {
  yearsFromDate,
  formatYearsMonthsOnset,
  formatCataract,
} from "@/util/formatting";
import { useRoute, useRouter } from "vue-router";
import usePolicy from "@/util/usePolicy";

const router = useRouter();
const route = useRoute();
const isNew = route.query?.newlyAdded === "true";
const { allowed } = usePolicy();

defineProps(["patientInfo"]);
const emit = defineEmits([
  "editPatient",
  "removeFromOrg",
  "addToOrg",
  "removePatient",
]);

function navigateToAddPatientPage() {
  router.push("/patients/add");
}

const userCanDeletePatients = allowed("DELETE_PATIENTS");
</script>
<style scopeclass>
.infocell {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-bottom: 0rem;
}
</style>
