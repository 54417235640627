<template>
  <BreadCrumbs
    :current="org.name"
    parent="Organizations"
    parent-path="/organizations"
  />
  <div class="body">
    <div class="row">
      <div class="col-sm-3">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{{ org.name }}</h5>
            <p class="card-text">{{ org.email }}</p>
            <p class="card-text">{{ org.phone }}</p>
            <p class="card-text">{{ org.address }}</p>
            <p class="card-text">
              <b class="me-2">Analyst:</b>
              <router-link :to="'/organizations/' + org.analyst_org_id">
                {{ org.analyst_org_name }}
              </router-link>
            </p>
            <button
              class="btn btn-oivi-light me-2"
              @click="editOrgModal.show()"
            >
              Edit
            </button>
            <button
              class="btn btn-outline-danger"
              @click="deleteOrgModal.show()"
              v-show="showOrgDeleteButton"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
      <div class="col-sm-9">
        <div class="d-flex mb-3">
          <div class="ms-auto">
            <button
              class="btn btn-lg btn-oivi-light"
              @click="addLocationModal.show()"
            >
              Add clinic
            </button>
          </div>
        </div>
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>Clinic name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Address</th>
              <th>Analyst Organizations</th>
            </tr>
          </thead>
          <tbody>
            <tr
              role="button"
              v-for="location in locations"
              :key="location.id"
              @click="clickLocation(location.id)"
            >
              <td>{{ location.name }}</td>
              <td>{{ location.email }}</td>
              <td>{{ location.phone }}</td>
              <td>{{ location.address }}</td>
              <td>
                <template
                  v-for="(analyst_org, index) in location?.analyst_orgs"
                  :key="index"
                >
                  <router-link :to="'/organizations/' + analyst_org.id">
                    {{ analyst_org.name }}
                  </router-link>
                  &nbsp;
                </template>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <EditOrgModal ref="editOrgModal" :org="org" @dataChange="loadData" />
  <AddLocationModal
    ref="addLocationModal"
    :orgID="org.id"
    @dataChange="loadData"
  />
  <DeleteOrgModal ref="deleteOrgModal" :orgID="org.id" :orgName="org.name" />
</template>
<script setup>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import backend from "@/backend";
import EditOrgModal from "./components/EditOrgModal.vue";
import DeleteOrgModal from "./components/DeleteOrgModal.vue";
import AddLocationModal from "./components/AddLocationModal.vue";

const router = useRouter();

const addLocationModal = ref(null);
const deleteOrgModal = ref(null);
const editOrgModal = ref(null);
const showOrgDeleteButton = ref(false);
const props = defineProps(["orgID"]);
const org = ref({});
const locations = ref([]);

async function loadData() {
  showOrgDeleteButton.value = false;
  org.value = await backend.organizations.getOrganization(props.orgID);
  locations.value = await backend.locations.listOrgLocations(props.orgID);
}

function clickLocation(location_id) {
  router.push("/locations/" + location_id);
}

onMounted(loadData);
</script>
