import axios from "axios";

const endpoint = import.meta.env.VITE_BIRDHOUSE_ENDPOINT;

function useDevicesAPI() {
  async function listDevices(sortBy = "") {
    return (
      await axios.get(`${endpoint}/devices`, { params: { sort: sortBy } })
    ).data.devices;
  }

  async function getDevice(device_serial) {
    return (await axios.get(`${endpoint}/devices/` + device_serial)).data
      .device;
  }

  function updateDevice(serial, data) {
    return axios.put(`${endpoint}/devices/${serial}`, JSON.stringify(data), {
      headers: { "Content-Type": "application/json" },
    });
  }

  return {
    listDevices,
    getDevice,
    updateDevice,
  };
}

export default useDevicesAPI;
