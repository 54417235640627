<template>
  <div class="body">
    <div
      v-if="
        messageStatus === DRAFTING_STATUS || messageStatus === SENDING_STATUS
      "
      class="mx-auto contact-page-container"
    >
      <div class="fs-2 fw-bold">Contact us</div>
      <div class="mb-3">
        Enter text below or send an email to
        <a href="mailto:feedback@oivi.co">feedback@oivi.co</a>.
      </div>
      <div>
        <Form @submit="handleFormSubmit">
          <div class="mb-4">
            <TextInput
              v-model="message.subject"
              inputLabel="Subject *"
              inputName="contactSubject"
              placeholder="Choose a subject"
              :validator="validateSubject"
            />
          </div>
          <div class="mb-4">
            <TextAreaInput
              v-model="message.content"
              label="Message *"
              textAreaName="contactMessage"
              placeholder="Write your message here"
              rows="10"
              :validator="validateContactForm"
            />
          </div>
          <button
            class="btn btn-secondary me-4"
            id="cancel-contact-oivi-form"
            :onClick="clearFields"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-oivi-light"
            id="submit-contact-oivi-form"
            :disabled="messageStatus === SENDING_STATUS"
          >
            {{ messageStatus === SENDING_STATUS ? "Sending..." : "Send" }}
          </button>
        </Form>
      </div>
    </div>
    <div
      v-if="
        messageStatus === FAILURE_STATUS || messageStatus === SUCCESS_STATUS
      "
      class="text-center mx-auto w-75"
    >
      <div v-if="messageStatus === SUCCESS_STATUS">
        <div class="alert alert-success">Message was sent!</div>
      </div>
      <div v-if="messageStatus === FAILURE_STATUS">
        <div class="alert alert-danger">
          Something went wrong when trying to send the message.
          {{
            error !== ""
              ? `This is the error
        message we received:
        ${error}.`
              : ""
          }}
          We advice you to try again later.
        </div>
      </div>
      <button
        class="btn btn-primary"
        id="submit-contact-oivi-form"
        :onClick="
          () => {
            messageStatus = DRAFTING_STATUS;
            message.subject = '';
            message.content = '';
          }
        "
      >
        Send another message
      </button>
    </div>
  </div>
</template>

<script setup lan="ts">
import backend from "@/backend";
import TextAreaInput from "@/components/Forms/TextAreaInput.vue";
import TextInput from "@/components/Forms/TextInput.vue";
import { Form } from "vee-validate";
import { ref, reactive } from "vue";

const DRAFTING_STATUS = "Drafting";
const SENDING_STATUS = "Sending";
const SUCCESS_STATUS = "Success";
const FAILURE_STATUS = "Failed";

const initMessage = { subject: "", content: "" };

const message = reactive({ ...initMessage });
const messageStatus = ref(DRAFTING_STATUS);
const error = ref("");

function clearFields(e) {
  e.preventDefault();
  message.subject = "";
  message.content = "";
}

function validateSubject(input) {
  if (input === "" || message.subject === "") return "Subject cannot be empty";
  return true;
}

function validateContactForm(input) {
  if (input === "" || message.content === "")
    return "Text area cannot be empty";
  return true;
}

async function handleFormSubmit() {
  messageStatus.value = SENDING_STATUS;
  const currentUser = await backend.users.getCurrentUser();
  const response = await backend.contact.sendContactMessage({
    userDetails: currentUser,
    message: { subject: message.subject, content: message.content },
  });
  if (response.data.message === "OK") messageStatus.value = SUCCESS_STATUS;
  else {
    messageStatus.value = FAILURE_STATUS;
    error.value = response.data.message;
  }
}
</script>
<style>
.contact-page-container {
  max-width: 660px;
}
</style>
