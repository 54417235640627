<template>
  <ModalForm
    v-show="visible"
    @close="visible = false"
    title="Select organization"
  >
    <slot />

    <select class="form-select" v-model="selectedOrg">
      <option v-for="org in orgs" :key="org.id" :value="org.id">
        {{ org.name }}
      </option>
    </select>

    <div class="mt-5">
      <button class="btn btn-primary me-2" @click="onOK">OK</button>
      <button class="btn btn-secondary" @click="visible = false">Cancel</button>
    </div>
  </ModalForm>
</template>
<script setup>
import { ref } from "vue";
import backend from "@/backend";
import ModalForm from "@/components/ModalForm.vue";

const visible = ref(false);
const orgs = ref([]);
const selectedOrg = ref(null);
const emit = defineEmits(["orgSelected"]);

async function onOK() {
  visible.value = false;
  if (selectedOrg.value) emit("orgSelected", selectedOrg.value);
}

async function show() {
  selectedOrg.value = null;
  orgs.value = await backend.organizations.listOrganizations();
  visible.value = true;
}

defineExpose({ show });
</script>
