import { applyToPoint } from "transformation-matrix";

function useZoom() {
  var icVars, icFuncs;

  function initUseZoom(icVarsR, icFuncsR) {
    icVars = icVarsR;
    icFuncs = icFuncsR;
  }

  function getInverseTransformation(currentTransform) {
    let M =
      currentTransform.a * currentTransform.d -
      currentTransform.b * currentTransform.c;
    let a = currentTransform.d / M;
    let b = currentTransform.b / M;
    let c = -currentTransform.c / M;
    let d = currentTransform.a / M;
    let e =
      (currentTransform.c * currentTransform.f -
        currentTransform.d * currentTransform.e) /
      M;
    let f =
      (currentTransform.b * currentTransform.e -
        currentTransform.a * currentTransform.f) /
      M;
    let inverseTransform = DOMMatrix.fromMatrix({
      a: a,
      b: b,
      c: c,
      d: d,
      e: e,
      f: f,
    });
    return inverseTransform;
  }

  function getDefaultTransformation() {
    return DOMMatrix.fromMatrix({
      a: icVars.minScale,
      b: 0,
      c: 0,
      d: icVars.minScale,
      e: 0,
      f: 0,
    });
  }

  function adjustTransformationToScope() {
    if (icVars.background === undefined || icVars.background.width === 0) {
      return;
    }
    var isAdjustmentMade = false;
    var adjusted = false;
    var transformation = icVars.ic.getTransform();
    for (let i = 0; i < 100; i++) {
      if (!adjusted) {
        let inverseTransformation = getInverseTransformation(transformation);
        let p0 = applyToPoint(inverseTransformation, [0, 0]);
        let p1 = applyToPoint(inverseTransformation, [
          icVars.ic.canvas.width,
          icVars.ic.canvas.height,
        ]);
        adjusted = true;
        if (p0[0] < -1 / icVars.minScale) {
          transformation.e -= 1;
          adjusted = false;
        }
        if (p0[1] < -1 / icVars.minScale) {
          transformation.f -= 1;
          adjusted = false;
        }
        if (p1[0] - icVars.background.width > 1 / icVars.minScale) {
          transformation.e += 1;
          adjusted = false;
        }
        if (p1[1] - icVars.background.height > 1 / icVars.minScale) {
          transformation.f += 1;
          adjusted = false;
        }
        if (!adjusted) {
          isAdjustmentMade = true;
        }
      } else {
        break;
      }
    }
    if (isAdjustmentMade) {
      icVars.ic.setTransform(transformation);
      icVars.currentOffsetX = -transformation.e / transformation.a;
      icVars.currentOffsetY = -transformation.f / transformation.d;
    }
  }

  function setScale(x, y, scale) {
    if (scale <= icVars.minScale) {
      icVars.ic.setTransform(getDefaultTransformation());
      icVars.currentOffsetX = 0;
      icVars.currentOffsetY = 0;
      icVars.currentScale = icVars.minScale;
      icFuncs.redrawCanvas();
      return;
    }

    if (scale > icVars.maxScale) {
      return;
    }

    icFuncs.clearCanvas();
    let zoom = scale / icVars.currentScale;
    icVars.ic.translate(icVars.currentOffsetX, icVars.currentOffsetY);
    icVars.currentOffsetX -= x / scale - x / icVars.currentScale;
    icVars.currentOffsetY -= y / scale - y / icVars.currentScale;
    icVars.ic.scale(zoom, zoom);
    icVars.ic.translate(-icVars.currentOffsetX, -icVars.currentOffsetY);
    icVars.currentScale = scale;
    if (zoom < 1) {
      adjustTransformationToScope();
    }
    icFuncs.redrawCanvas();
  }

  function mouseWheelHandler(e) {
    e.preventDefault();
    let scale =
      e.deltaY > 0 ? icVars.currentScale / 1.1 : icVars.currentScale * 1.1;
    const canvas = e.target;
    const x = (e.offsetX * canvas.width) / canvas.clientWidth;
    const y = (e.offsetY * canvas.height) / canvas.clientHeight;
    setScale(x, y, scale);
  }

  function startPan(e) {
    [icVars.currentMousePanX, icVars.currentMousePanY] = icFuncs.getScaledXY(
      e,
      false
    );
    icVars.isPanning = true;
  }
  function stopPan() {
    icVars.isPanning = false;
  }
  function isCursorAlmostOutOfClient(e) {
    let xp = e.offsetX / icVars.ic.canvas.clientWidth;
    let yp = e.offsetY / icVars.ic.canvas.clientHeight;
    if (xp < 0.03 || xp > 0.97 || yp < 0.03 || yp > 0.97) {
      return true;
    }
    return false;
  }
  function movePan(e) {
    if (icVars.isPanning) {
      if (isCursorAlmostOutOfClient(e)) {
        stopPan();
        return;
      }
      let [newMousePanX, newMousePanY] = icFuncs.getScaledXY(e, false);
      let diffX =
        (newMousePanX - icVars.currentMousePanX) / icVars.currentScale;
      let diffY =
        (newMousePanY - icVars.currentMousePanY) / icVars.currentScale;

      icVars.ic.translate(diffX, diffY);
      let transformation = icVars.ic.getTransform();
      icVars.currentOffsetX = -transformation.e / transformation.a;
      icVars.currentOffsetY = -transformation.f / transformation.d;
      adjustTransformationToScope();
      icFuncs.redrawCanvas();

      icVars.currentMousePanX = newMousePanX;
      icVars.currentMousePanY = newMousePanY;
    }
  }

  return {
    initUseZoom,
    setScale,
    mouseWheelHandler,
    startPan,
    stopPan,
    movePan,
  };
}
export default useZoom;
