<template>
  <BreadCrumbs
    :current="location.name"
    parent="Organizations"
    parent-path="/organizations"
    :parent2="location.organization_name"
    :parent2-path="'/organizations/' + location.organization_id"
  />
  <div class="body">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">{{ location.name }}</h5>
        <p class="card-text">{{ location.email }}</p>
        <p class="card-text">{{ location.notification_email }}</p>
        <p class="card-text">{{ location.phone }}</p>
        <p class="card-text">{{ location.address }}</p>
        <p class="infocell">
          Analyst Organizations:
          <span
            v-for="(analyst_org, index) in location?.analyst_orgs"
            :key="index"
            class="ms-2"
          >
            <router-link :to="'/organizations/' + analyst_org.id">
              {{ analyst_org.name }}
            </router-link>
            <a
              href="#"
              class="bi bi-trash ms-1"
              @click="removeAnalystOrg(analyst_org.id)"
              :title="'Remove Analyst from ' + location.name"
            ></a>
          </span>
          <a
            href="#"
            class="bi bi-plus-circle ms-2"
            :title="'Add analyst to ' + location.name"
            @click="selectOrgModal.show()"
          ></a>
        </p>
        <br />
        <p class="infocell">
          Labels:
          <span
            v-for="(label, index) in location?.labels"
            :key="index"
            class="ms-2 badge bg-primary"
          >
            {{ label }}
            <a
              href="#"
              class="bi bi-trash ms-1"
              style="color: white"
              @click="removeLabel(label)"
              :title="'Remove label from ' + location.name"
            ></a>
          </span>
          <br />
          <input
            type="text"
            class="me-2 mt-2"
            placeholder="Label Name..."
            v-model="newLabel"
            @keyup.enter="addLabel"
          />
          <a
            href="#"
            class="bi bi-plus-circle ms-2"
            :title="'Add label ' + location.name"
            @click="addLabel"
          ></a>
          <label class="form-label fw-medium-bold"
            ><i
              class="bi bi-info-circle ms-2"
              id="info-icon"
              data-toggle="tooltip"
              :title="`Following labels already exist:\n- ${allLabels.join(
                '\n- '
              )}`"
            ></i
          ></label>
        </p>
        <br />
        <div class="form-check form-switch">
          <input
            class="form-check-input"
            type="checkbox"
            role="button"
            v-model="send_report_to_patients"
            @change="toggleSendReportToPatients"
          />
          <label class="form-check-label" for="flexSwitchCheckDefault"
            >Send Reports to patients automatically</label
          >
        </div>
        <br />
        <div class="form-check form-switch">
          <input
            class="form-check-input"
            type="checkbox"
            role="button"
            v-model="password_protect_patient_report"
            @change="togglePasswordProtectReport"
          />
          <label class="form-check-label" for="flexSwitchCheckDefault"
            >Password-Protect patient reports</label
          >
        </div>
        <br />
        <br />
        <p v-if="allowed('EDIT_ORGANIZATION')">
          <button
            class="btn btn-oivi-light me-2"
            @click="editLocationModal.show()"
          >
            Edit
          </button>
          <button
            class="btn btn-outline-danger"
            @click="deleteLocationModal.show()"
          >
            Delete
          </button>
        </p>
      </div>
    </div>
  </div>
  <ModalForm
    title="Confirm analyst deletion"
    v-show="confirmAnalystDeletionVisible"
    @close="confirmAnalystDeletionVisible = false"
  >
    <p>
      Please confirm that you want to delete the analyst from the organization.
      They will no longer have access to the visits.
    </p>
    <div class="text-center mt-5">
      <button
        class="btn btn-secondary me-3"
        @click="confirmAnalystDeletionVisible = false"
      >
        Cancel
      </button>
      <button
        class="btn btn-primary"
        @click="confirmRemoveAnalystOrg(analystOrgToBeDeleted)"
      >
        Confirm
      </button>
    </div>
  </ModalForm>

  <YesNoModal
    ref="commonConfirmation"
    :title="commonConfirmationDetails.title"
    :yes-label="commonConfirmationDetails.yesLabel"
    yesCustomStyle="btn-primary"
    :onYes="commonConfirmationDetails.onYes"
    :onNo="commonConfirmationDetails.onNo"
  >
    <template
      v-for="(desc, index) in commonConfirmationDetails?.desc"
      :key="index"
    >
      <p>{{ desc }}</p>
    </template>
  </YesNoModal>

  <EditLocationModal
    ref="editLocationModal"
    :location="location"
    @dataChange="loadData"
  />
  <DeleteLocationModal
    ref="deleteLocationModal"
    :locationID="location.id"
    :orgID="location.organization_id"
    :locationName="location.name"
  />
  <SelectOrgModal ref="selectOrgModal" @orgSelected="addAnalystOrg">
    <p>Please select the analyst organization to add</p>
    <p>Analysts from this organization will get access to analyze patients</p>
  </SelectOrgModal>
</template>
<script setup>
import { ref, onMounted } from "vue";
import backend from "@/backend";
import usePolicy from "@/util/usePolicy";
import EditLocationModal from "./components/EditLocationModal.vue";
import DeleteLocationModal from "./components/DeleteLocationModal.vue";
import SelectOrgModal from "@/components/SelectOrgModal.vue";
import ModalForm from "@/components/ModalForm.vue";
import YesNoModal from "@/components/YesNoModal.vue";

const { allowed } = usePolicy();

const deleteLocationModal = ref(null);
const editLocationModal = ref(null);
const props = defineProps(["locationID"]);
const location = ref({});
const selectOrgModal = ref(null);
const confirmAnalystDeletionVisible = ref(false);
const analystOrgToBeDeleted = ref(0);
const commonConfirmation = ref(null);
const commonConfirmationDetails = ref({
  title: "",
  desc: [],
  yesLabel: "",
  onYes: null,
  onNo: null,
});
const send_report_to_patients = ref(false);
const password_protect_patient_report = ref(false);
const newLabel = ref("");
const allLabels = ref([]);

async function loadData() {
  location.value = await backend.locations.getLocation(props.locationID);
  send_report_to_patients.value = location.value?.send_report_to_patients;
  password_protect_patient_report.value =
    location.value?.password_protect_patient_report;

  allLabels.value = await backend.locations.listLabels();
}

async function addAnalystOrg(analyst_org_id) {
  if (location.value.analyst_orgs.find((org) => org.id === analyst_org_id)) {
    alert("The Organization already exists as an analyst");
    return;
  }

  let data = {};
  data.analyst_orgs = location.value.analyst_orgs.map((org) => org.id);
  data.analyst_orgs.push(analyst_org_id);
  await backend.locations.updateLocation(props.locationID, data);
  await loadData();
}

async function removeAnalystOrg(analyst_org_id) {
  analystOrgToBeDeleted.value = analyst_org_id;
  confirmAnalystDeletionVisible.value = true;
}

async function confirmRemoveAnalystOrg(analyst_org_id) {
  let data = {};
  data.analyst_orgs = location.value.analyst_orgs.map((org) => org.id);
  const index = data.analyst_orgs.indexOf(analyst_org_id);
  if (index > -1) {
    data.analyst_orgs.splice(index, 1);
  }
  await backend.locations.updateLocation(props.locationID, data);
  await loadData();
  confirmAnalystDeletionVisible.value = false;
}

async function toggleSendReportToPatients() {
  commonConfirmationDetails.value = {
    title: send_report_to_patients.value
      ? "Enable Sending Reports?"
      : "Disable Sending Reports?",
    desc: [
      send_report_to_patients.value
        ? "Patients will start receiving reports automatically after they are ready."
        : "Patients will no longer receive reports automatically.",
    ],
    yesLabel: "Confirm",
    onYes: async () => {
      let data = {
        send_report_to_patients: send_report_to_patients.value,
      };
      await backend.locations.updateLocation(props.locationID, data);
      await loadData();
    },
    onNo: async () => {
      send_report_to_patients.value = !send_report_to_patients.value;
    },
  };
  commonConfirmation.value.show();
}

async function togglePasswordProtectReport() {
  commonConfirmationDetails.value = {
    title: password_protect_patient_report.value
      ? "Enable Password Protection?"
      : "Disable Password Protection?",
    desc: [
      password_protect_patient_report.value
        ? "The patient reports will be password protected with their name and age."
        : "The patient reports will be like a general PDF without any password.",
    ],
    yesLabel: "Confirm",
    onYes: async () => {
      let data = {
        password_protect_patient_report: password_protect_patient_report.value,
      };
      await backend.locations.updateLocation(props.locationID, data);
      await loadData();
    },
    onNo: async () => {
      password_protect_patient_report.value =
        !password_protect_patient_report.value;
    },
  };
  commonConfirmation.value.show();
}

async function addLabel() {
  if (newLabel.value) {
    let data = {};
    data.labels = location.value.labels;
    data.labels.push(newLabel.value);
    await backend.locations.updateLocation(props.locationID, data);
    await loadData();
    newLabel.value = "";
  }
}

async function removeLabel(label) {
  let data = {};
  data.labels = location.value.labels;
  const index = data.labels.indexOf(label);
  if (index > -1) {
    data.labels.splice(index, 1);
  }
  await backend.locations.updateLocation(props.locationID, data);
  await loadData();
}

onMounted(loadData);
</script>

<style scopeclass>
.infocell {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-bottom: 0rem;
}
.badge {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
}
</style>
