<template>
  <div v-if="totalEntries > 0" class="d-md-flex justify-content-center">
    <p class="p-2 text-nowrap me-3">
      {{ firstShown }} - {{ lastShown }} of {{ totalEntries }}
    </p>
    <ul class="limitpp me-3">
      <div class="dropdown">
        <button
          class="btn btn-light dropdown-toggle"
          type="button"
          id="dropdownMenuButton"
          data-bs-toggle="dropdown"
        >
          {{ limitPerPage }} items per page
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item" @click="$emit('newLimitPerPage', 10)">10</a>
          <a class="dropdown-item" @click="$emit('newLimitPerPage', 20)">20</a>
          <a class="dropdown-item" @click="$emit('newLimitPerPage', 50)">50</a>
        </div>
      </div>
    </ul>
    <ul class="pagination" v-if="last_page > 1">
      <li v-if="currentPage === 1" class="page-item disabled">
        <span class="page-link">Previous</span>
      </li>
      <li v-else class="page-item">
        <span class="page-link" @click="newOffsetValue(currentPage - 1)"
          >Previous</span
        >
      </li>
      <li v-if="currentPage > 2" class="page-item">
        <span class="page-link" @click="newOffsetValue(1)">1</span>
      </li>
      <li v-if="currentPage > 2" class="page-item disabled">
        <span class="page-link">...</span>
      </li>

      <li v-if="currentPage > 1" class="page-item">
        <span class="page-link" @click="newOffsetValue(currentPage - 1)">
          {{ currentPage - 1 }}
        </span>
      </li>
      <li class="page-item active">
        <span class="page-link">
          {{ currentPage }}
        </span>
      </li>
      <li v-if="currentPage < last_page" class="page-item">
        <span class="page-link" @click="newOffsetValue(currentPage + 1)">
          {{ currentPage + 1 }}
        </span>
      </li>

      <li v-if="currentPage < last_page - 1" class="page-item disabled">
        <span class="page-link">...</span>
      </li>
      <li v-if="currentPage < last_page - 1" class="page-item">
        <span class="page-link" @click="newOffsetValue(last_page)">{{
          last_page
        }}</span>
      </li>

      <li v-if="currentPage === last_page" class="page-item disabled">
        <span class="page-link">Next</span>
      </li>
      <li v-else class="page-item">
        <span class="page-link" @click="newOffsetValue(currentPage + 1)"
          >Next</span
        >
      </li>
    </ul>
  </div>
  <div v-else class="text-center text-muted">No entries found</div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  totalEntries: {
    type: Number,
    required: true,
  },
  limitPerPage: {
    type: Number,
    required: true,
  },
  offsetValue: {
    type: Number,
    required: true,
    default: 0,
  },
});

const emit = defineEmits(["newOffsetValue", "newLimitPerPage"]);

function getNewOffset(page, limit) {
  let newOffset = (page - 1) * limit;
  return newOffset;
}

function getCurrentPage(limit, offset) {
  let val = Math.floor(offset / limit + 1);
  return val;
}

function getLastPage(limit, total) {
  let last_page = Math.floor(total / limit);
  if (total % limit) last_page++;
  return last_page;
}

const currentPage = computed(() =>
  getCurrentPage(props.limitPerPage, props.offsetValue)
);
const last_page = computed(() =>
  getLastPage(props.limitPerPage, props.totalEntries)
);

const firstShown = computed(() => props.offsetValue + 1);
const lastShown = computed(() =>
  Math.min(props.offsetValue + props.limitPerPage, props.totalEntries)
);

function newOffsetValue(newPage) {
  const newOffsetVal = getNewOffset(newPage, props.limitPerPage);
  emit("newOffsetValue", newOffsetVal);
}
</script>

<style scoped>
.pagination {
  user-select: none;
}
.limitpp {
  padding: 0;
}

.page-link:hover {
  cursor: pointer;
}
</style>
